import React from 'react';

import { ModalLink } from 'navigation/Link';

import * as styles from './styles';

type Props = {
  accountExecutive?: string;
  accountName?: string;
  accountId?: string;
  opportunityName?: string;
  opportunityId?: string;
};

const SummaryInsightHeader: React.FC<Props> = ({
  accountExecutive = '-',
  accountName = '',
  accountId = '',
  opportunityName = '',
  opportunityId = '',
}) => {
  const hasAccount = !!(accountId && accountName);
  const hasOpportunity = !!(opportunityId && opportunityName);

  const entityLabel = hasAccount && !hasOpportunity ? 'Account' : 'Opportunity';
  const entityName = hasOpportunity ? opportunityName : accountName;
  const entityUrlScheme = hasOpportunity ? '/deal/:id' : '/account/:id';
  const entityId = hasOpportunity ? opportunityId : accountId;

  return (
    <div className={styles.summaryInsightHeaderContainer}>
      <div className="header-opportunity">
        <span>{entityLabel}:</span>

        {entityId && entityName ? (
          <ModalLink
            url={{
              scheme: entityUrlScheme,
              params: { id: entityId as string },
            }}
          >
            <span className="link">{entityName}</span>
          </ModalLink>
        ) : (
          <span>{opportunityName || '-'}</span>
        )}
      </div>

      <div className="header-ae">
        <span>AE:</span>
        <span>{accountExecutive || '-'}</span>
      </div>
    </div>
  );
};

export default SummaryInsightHeader;
