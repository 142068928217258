import { isNil } from 'lodash';
import * as _ from 'ramda';

import {
  snippetTypeMappings,
  snippetSpeakerTypeMappings,
  USER_ROLES,
  SALES_MANAGER_ROLES,
} from 'common/constants';

export const createQs = _.compose(
  _.concat('?'),
  _.join('&'),
  _.map(_.join('=')),
  _.toPairs
);

export const notNullAndEmpty = (value) => !_.isEmpty(value) && !_.isNil(value);

export const filterSnippets = (
  snippets,
  selectedGroup,
  selectedGroupType,
  searchText
) => {
  const snippetKey = snippetTypeMappings[selectedGroup];
  const speakerType = snippetSpeakerTypeMappings[selectedGroup];
  return snippets
    .filter((s, index) => !_.isEmpty(s.transcript))
    .filter((s, index) => {
      if (speakerType) {
        return s.speaker_type === speakerType;
      }
      return true;
    })
    .filter((s, index) => {
      if (selectedGroupType) {
        const newGroupType =
          selectedGroupType === 'Seller Questions'
            ? 'Company Questions'
            : selectedGroupType;
        if (newGroupType !== 'Highlights') {
          return _.contains(newGroupType, s[snippetKey]);
        }
        return notNullAndEmpty(s.questions) || notNullAndEmpty(s.topics);
      }
      return true;
    })
    .filter((s, index) => {
      if (searchText && s.transcript) {
        const searchText_ = searchText.replace(/\s+/g, ' '); // replace multiple whitespace with one
        const snippetText_ = s.transcript.replace(/\s+/g, ' '); // replace multiple whitespace with one
        return (
          snippetText_.toLowerCase().indexOf(searchText_.toLowerCase()) !== -1
        );
      }
      return true;
    });
};

export const getApiErrorMessage = (apiError, default_error) => {
  if (apiError && apiError.error && apiError.error.message) {
    return apiError.error.message;
  }
  if (default_error) {
    return default_error;
  }
  return 'API Error';
};

export const isManagerRole = (role) =>
  SALES_MANAGER_ROLES.includes(role) || role === USER_ROLES.SALES_OPERATIONS;

export const capitalizeFirstLetter = (inputStr) => {
  if (inputStr) {
    return inputStr.charAt(0).toUpperCase() + inputStr.substring(1);
  }
  return inputStr;
};

export const splitAndCapitalize = _.curry((separator, inputStr) =>
  _.compose(
    _.join(' '),
    _.map(capitalizeFirstLetter),
    _.split(separator)
  )(inputStr)
);

export const pluralize = _.curry((singular, plural, value) =>
  _.converge(_.unapply(_.reduce(_.concat, '')), [
    _.toString,
    _.always(' '),
    _.ifElse(_.equals(1), _.always(singular), _.always(plural)),
  ])(value)
);

export const ordinalize = (n) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  const suffix = suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
  return `${n}${suffix}`;
};

// refresh cache after 5 minutes
export const shouldUpdateCache = (timestamp, lifetime = 30000) => {
  return isNil(timestamp) ? true : Date.now() - timestamp > lifetime;
};
