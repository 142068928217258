import classNames from 'classnames';
import { css } from 'emotion';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TABLE_ID } from 'common/constants';
import BuButton from 'components/UI/BuButton';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import Table from 'components/UI/TableConfig/Table';
import { getRevBiDashboardsColumns } from 'components/dashboard/Metrics/List/Common/Columns/ListTableColumns';
import { BIDashboard } from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';

const dashboardsListStyles = css`
  overflow: hidden;

  .table-controls {
    padding-left: 25px;
  }
  .revbi__manage-dashboards-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px 8px 24px;
    gap: 6px;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: var(--bu-gray-900);
      margin-bottom: 8px;
    }
    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: var(--bu-gray-700);
    }
  }
`;

const tableContainer = css`
  height: calc(100% - 75px);
`;

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards`;

type IProps = {
  dashboardsList: BIDashboard[];
  onRemove: (id: string) => void;
  setDashboardsList: React.Dispatch<React.SetStateAction<BIDashboard[]>>;
  onAdd: () => void;
};

export const DashboardsList = ({
  dashboardsList,
  onAdd,
  onRemove,
  setDashboardsList,
}: IProps) => {
  const history = useHistory();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedDashboard, selectDashboard] = useState({ id: '', name: '' });
  const [searchValue, setSearchValue] = useState('');

  const handleDeleteConfirm = (id: string, name?: string) => {
    fetchApi<void, any>({
      url: `${API_URL}/${id}`,
      queryMethod: 'delete',
      setData: () => {
        toast.success(`Dashboard ${name || id} has been deleted`);
        setDashboardsList((prev: any) =>
          prev.filter((widget: any) => (widget as any)?._id !== id)
        );
        onRemove(id);
      },
      setError: (error: string | null) => {
        console.error(`Failed to delete dashboard "${name || id}": ${error}`);
        toast.error(`Failed to delete dashboard "${name || id}"`);
      },
      setStatus: () => {},
    });
  };

  const handleSearch = (value: string) => {
    setSearchValue(value.toLowerCase());
  };

  const handleEditDashboard = (id: string) => {
    history.replace({ pathname: `/revbi/dashboard/${id}` });
  };

  const handleDeleteDashboard = (id: string, name: string) => {
    selectDashboard({ name, id });
    setIsConfirmationOpen(true);
  };

  const columns = getRevBiDashboardsColumns(
    handleEditDashboard,
    handleDeleteDashboard
  );

  const dashboardsData = Boolean(searchValue)
    ? dashboardsList.filter(({ name }) =>
        name.toLowerCase().includes(searchValue)
      )
    : dashboardsList;

  return (
    <div className={classNames(dashboardsListStyles)}>
      <div className="revbi__manage-dashboards-header">
        <div>
          <div className="title">Manage Dashboards</div>
          <span className="desc">
            Define your own dashboards and publish for your users
          </span>
        </div>
        <BuButton onClick={onAdd}>Create dashboard</BuButton>
      </div>

      <div className={tableContainer}>
        <Table
          tableId={TABLE_ID.DASHBOARD_LIST}
          fullscreen
          hidePaginationStart
          hidePaginationEnd
          title={'Dashboards'}
          columns={columns}
          data={dashboardsData as any[]}
          rowsPerPage={10}
          currentPage={1}
          totalCount={dashboardsList.length}
          onSearchChange={handleSearch}
          searchPlaceholder="Search dashboards"
        />
      </div>

      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        headerText="Confirmation Required!"
        isOpen={isConfirmationOpen}
        onClose={() => {
          setIsConfirmationOpen(false);
        }}
        onConfirm={() => {
          if (selectedDashboard.id) {
            handleDeleteConfirm(selectedDashboard.id, selectedDashboard.name);
            setIsConfirmationOpen(false);
          }
        }}
      >
        {`Are you sure you want to delete "${selectedDashboard?.name}"? It will be permanently removed.`}
      </BuConfirmationPopup>
    </div>
  );
};
