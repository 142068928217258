import { css } from 'emotion';

import { cardStyle, tabContentWrapper, notAvilableContent } from '../../styles';
import { fontCaption1, fontHeading3 } from 'assets/css/fontStyles';

export { tabContentWrapper, notAvilableContent };

export const transcriptTabHeader = css`
  position: sticky;
  z-index: 999;
  top: 0;
  height: 40px;
  background-color: var(--bu-primary-100);
  margin-left: -16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
`;

export const transcriptTabWrapper = css`
  height: calc(100% - 40px);

  &.disabled {
    .segment-card {
      cursor: default;
    }
  }

  .ReactVirtualized__List {
    padding-right: 16px;
  }

  .segment-card {
    ${cardStyle}
    display: flex;
    border-radius: var(--bu-control-border-radius);
    gap: var(--bu-control-gap-medium);
    cursor: pointer;
    position: relative;

    &:hover,
    &.active {
      background-color: var(--bu-gray-100);

      .active-border {
        border: 1px solid;
        display: block;
        border-radius: var(--bu-control-border-radius);
        position: absolute;
        background: transparent;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .active-border {
      display: none;
    }

    .message-info {
      width: 100%;

      .message-info-name-time {
        ${fontHeading3}
        display: flex;
        align-items: center;
        gap: var(--bu-control-gap-extra-large);

        span + span {
          ${fontCaption1}
          color: var(--bu-gray-700);
        }
      }

      p {
        padding-right: 30px;
        margin-top: 8px;
      }
    }
  }

  .divider {
    height: 12px;
  }
`;

export const noTranscriptAvailable = css`
  ${notAvilableContent}
  padding-top: 24px;
`;

export const autoscrollMessagePopupClass = css`
  opacity: 0;
  transition: opacity 1s ease-in-out;

  &.fade-in {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
  }
`;
