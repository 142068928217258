import { css } from 'emotion';

export const dimmer = css`
  > .content {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const modal = css`
  label: modal;
  margin: 0 2.5% !important;
  color: var(--bu-gray-900);
`;

export const fullHeight = css`
  height: 85%;
`;

export const modalContent = css`
  border-radius: 4px;
  max-height: calc(85vh) !important;
  padding: 0px 1.5rem !important;

  &.no-padding {
    padding: 0 !important;
  }
`;

export const modalContentFullHeight = css`
  height: calc(100% - 50px);
  display: flex !important;
`;

export const closeButton = css`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: color 0.4s;
    color: var(--bu-gray-900);
    font-size: var(--bu-font-size-big);
  }
`;

export const header = css`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--bu-gray-500);
  display: grid;
  grid-template-columns: 12fr 150px 60px;
`;

export const header_title = css`
  color: var(--bu-gray-900);
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const closeButton_box = css`
  border-left: 1px solid var(--bu-gray-500);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const controllers_box = css`
  display: flex;
  align-items: center;
`;

export const closeTooltip = css`
  text-align: center;
`;
