import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { ActionCreator } from 'typescript-fsa';

import { actions } from 'actions';
import { TABLE_ID } from 'common/constants';
import { PersistedTableItem } from 'components/UI/DealsFlatTableTS/Table/types';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import Table from 'components/UI/TableConfig';
import { ColumnTypesCallback } from 'components/UI/TableConfig/column-helper';
import {
  isColumnConfigClickable,
  isColumnConfigQuery,
  onReadyCallback,
} from 'components/UI/TableConfig/types';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import { ICheckboxItemNode } from 'components/UI/common/Filter/types';
import {
  IColumn,
  IRow,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import viewConfig from 'components/dashboard/Accounts/getConfig';
import Banner from 'components/dashboard/Banner';
import AccountsAnalytics from 'components/dashboard/accounts-analytics';
import AccountRecommendations from 'components/dashboard/accounts-recommendations';
import * as styles from 'components/dashboard/styles';
import { useCheckFrameWindow } from 'components/hooks/useCheckFrameWindow';
import { useTableOffset } from 'components/hooks/useTableOffset';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { Filters as FilterTypes } from 'reducers/opportunitiesReducer/types';
import { IReduxState } from 'reducers/types';
import { FiltersForAPI, getFiltersForAPI } from 'selectors';
import { getCrmType } from 'selectors/settings';
import { getTablePrevState } from 'selectors/table';
import { usePageSize } from 'components/hooks/usePageSize';

const queryDealsCollection = (
  accountId: string | number,
  params: any,
  persistModalParams: ActionCreator<PersistQueryParams>,
  pageSize: number
) => {
  openModal({
    scheme: '/deals/:tab',
    params: { tab: 'accounts' },
    persistParams: {
      account_id: accountId,
      close_date_interval: undefined,
      include_disabled: true,
      page_size: pageSize,
      ...params,
    },
    persistor: persistModalParams,
  });
};

type OwnProps = {
  fetchAccounts: Function;
  updateAccount: Function;
  status?: string;
  companyCurrency: string;

  // accounts
  accounts: {
    total_count: number;
    data: {
      account_name: string;
      email_domain: string;
      open_opportunities: number;
      positive_indicators: RiskFactor[];
      risk_factors: RiskFactor[];
      risk_score: number;
      risk_status: string;
      risk_status_display: string;
      user: {
        email: string;
        name: string;
      };
      created_by: string;
      _id: string;
    }[];
    query: {
      users: any[];
      account_name: string;
      page_size: number;
      page_number: number;
      sort: string;
    };
  };

  // withRouter
  match: {
    isExact: boolean;
    path: string;
  };
  location: {
    pathname: string;
    search: string;
    query: any;
  };
};

type StateProps = {
  crmType: string;
  filters: FiltersForAPI;
  sortAccountsPrevState: PersistedTableItem | {};
};

type RiskFactor = {
  category: string;
  name: string;
  score: number;
  text: string;
  updated_at: string;
};

type DispatchProps = {
  persistTableParams: ActionCreator<PersistedTableItem>;
  persistModalParams: ActionCreator<PersistQueryParams>;
};

type IProps = OwnProps & StateProps & DispatchProps;

const emptyTableMessage = [
  'For your filters there are 0 accounts found that satisfy filter conditions.',
  'Change your filter selection or reset to default above to see accounts.',
].join('\n');

const AccountsDashboard: React.FC<IProps> = ({
  fetchAccounts,
  updateAccount,
  accounts,
  status = 'notAsked',
  crmType,
  match,
  location,
  persistTableParams,
  persistModalParams,
  filters,
}) => {
  const { setPartition, clearContext } = useHeader();

  const [isReady, setReady] = useState(false);
  const [isFilterInitialized, setFilterInitialized] = useState(false);

  const [sortOrder, setSortOrder] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [accountsPageSize, setPageSize] = usePageSize('Accounts');
  const [searchText, setSearchText] = useState('');

  const [containerRef, offset] = useTableOffset();

  const serializedFilters = JSON.stringify(filters);

  const isCheckFrame = useCheckFrameWindow();

  useEffect(() => {
    setPartition('accounts');

    return () => {
      clearContext();
    };
  }, []);

  useEffect(() => {
    if (!isFilterInitialized && !isEmpty(filters)) {
      setFilterInitialized(true);
    }
    setPageNumber(1);
  }, [serializedFilters]);

  const handleReady: onReadyCallback = ({
    order: { object_field, direction },
  }) => {
    setSortOrder(
      object_field ? `${direction === -1 ? '-' : ''}${object_field}` : ''
    );
    setReady(true);
  };

  useEffect(() => {
    const lastState: PersistedTableItem = {
      name: 'Accounts',
      type: 'accounts',
      filter: {
        sort: sortOrder || '',
        page_size: accountsPageSize,
        page_number: pageNumber - 1,
      },
    };

    return () => {
      persistTableParams(lastState);
    };
  }, [sortOrder, accountsPageSize, pageNumber]);

  const handleClick: ColumnTypesCallback = ({
    column,
    columnConfig,
    objectId,
  }) => {
    if (column.type === ColumnTypes.TEXT) {
      if (
        columnConfig.object_field === 'open_opportunities' ||
        columnConfig.object_field === 'crm_metadata.account_name'
      ) {
        AnalyticsTracker.event(
          {
            tab: 'Accounts Dashboard',
          },
          {
            category: 'Accounts Dashboard',
            action: 'Accounts Table',
            label:
              columnConfig.object_field === 'open_opportunities'
                ? 'Open opportunities column'
                : 'Open name column',
          }
        );
      }
      if (isColumnConfigQuery(columnConfig)) {
        queryDealsCollection(
          objectId,
          columnConfig.meta.params,
          persistModalParams,
          accountsPageSize
        );
      }
      if (isColumnConfigClickable(columnConfig)) {
        switch (columnConfig.meta.object_id) {
          case 'account_id':
            openModal({
              scheme: '/account/:id',
              params: {
                id: objectId,
              },
            });
            break;
          case '_id':
            openModal({
              scheme: '/account/:id',
              params: {
                id: objectId,
              },
            });
            break;
          default:
            console.warn('Action not supported');
        }
      } else {
        console.warn('Action not supported');
      }
    } else if (column.type === ColumnTypes.SCORE) {
      openModal({
        scheme: '/account/:id',
        params: {
          id: objectId,
        },
      });
    }
  };

  const getAccountType = (field: string) => {
    if (!filters.account_type) {
      return false;
    }

    return (
      isEmpty(filters.account_type) || filters.account_type.includes(field)
    );
  };

  if (crmType === 'no-crm') {
    viewConfig.filters.account_type.children.forEach(
      (c: ICheckboxItemNode) => (c.checked = true)
    );
  }

  useEffect(() => {
    const query: {
      [key: string]: string[] | string | number | boolean | null | undefined;
    } = {
      ...(filters as any),
      created_at: (filters.created_at || [])[0],
      account_name: searchText || undefined,
      page_size: accountsPageSize,
      page_number: pageNumber - 1,
      sort: sortOrder,
      customer_account: getAccountType('customer_account'),
      pipeline_account: getAccountType('pipeline_account'),
      prospect_account: getAccountType('prospect_account'),
    };

    if (
      !isEqual(query, accounts.query) &&
      location.pathname === '/accounts/all' &&
      isFilterInitialized &&
      isReady
    ) {
      fetchAccounts(query);
    }
  }, [
    accountsPageSize,
    pageNumber,
    sortOrder,
    searchText,
    accounts.query,
    isFilterInitialized,
    match.path,
    serializedFilters,
    isReady,
  ]);

  const handleSort = (sort?: string) => {
    setPageNumber(1);
    setSortOrder(sort || '');
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

  const handleSearchChange = (text: string) => {
    setSearchText(text);
    setPageNumber(1);
  };

  const handleChange = (col: IColumn, row: IRow, value: ValueProp) => {
    updateAccount(row.id, { [col.field]: value });
  };

  const onRefresh = () => {
    const query: {
      [key: string]: string[] | string | number | boolean | null | undefined;
    } = {
      ...(filters as any),
      created_at: (filters.created_at || [])[0],
      account_name: searchText || undefined,
      page_size: accountsPageSize,
      page_number: pageNumber - 1,
      sort: sortOrder,
      customer_account: getAccountType('customer_account'),
      pipeline_account: getAccountType('pipeline_account'),
      prospect_account: getAccountType('prospect_account'),
    };

    if (
      location.pathname === '/accounts/all' &&
      isFilterInitialized &&
      isReady
    ) {
      fetchAccounts(query);
    }
  };

  const heightTable = `calc(100vh - ${
    isCheckFrame ? offset + 50 : offset + 10
  }px)`;

  return (
    <section style={{ height: '100%', overflow: 'hidden' }}>
      <article className={styles.container} role="none" ref={containerRef}>
        <Banner title="Accounts Dashboard" />

        <OpenFiltersPanel tab="accounts" />
      </article>
      <article className={styles.content}>
        <Route
          loading={status === 'loading'}
          path="/accounts/all"
          title="Accounts"
          strict
          accounts={accounts}
          exact
          config={viewConfig.columns}
          render={() => (
            <div
              style={{
                height: heightTable,
              }}
            >
              <Table
                tableId={TABLE_ID.ACCOUNTS}
                pinnableColumns
                currentPage={pageNumber}
                data={accounts.data.map((item) => ({ ...item, id: item._id }))}
                downloadButton={{
                  serializedQueryParams: JSON.stringify({
                    ...(filters as any),
                    created_at: (filters.created_at || [])[0],
                    account_name: searchText || undefined,
                    page_size: accountsPageSize,
                    page_number: pageNumber - 1,
                    sort: sortOrder,
                    customer_account: getAccountType('customer_account'),
                    pipeline_account: getAccountType('pipeline_account'),
                    prospect_account: getAccountType('prospect_account'),
                  }),
                  url: '/api/data/accounts/csv',
                }}
                emptyTableMessage={emptyTableMessage}
                fullscreen
                hidePaginationEnd
                loading={status === 'loading'}
                onChange={handleChange}
                onPaginationChange={handlePaginationChange}
                onReady={handleReady}
                onSearchChange={handleSearchChange}
                onSort={handleSort}
                onSpecialClick={handleClick}
                onRefresh={onRefresh}
                rowsPerPage={accountsPageSize}
                searchPlaceholder="Search for accounts"
                showColumnsVisibilityToggle={true}
                sortOrder={sortOrder}
                specialTableConfig={{
                  riskMoreButtonLabel: 'View Account Insights',
                }}
                tableConfigCollection="account"
                tableConfigName="Accounts"
                title="Accounts"
                totalCount={accounts.total_count}
                hasRefreshButton
              />
            </div>
          )}
        />
        <Route
          path="/accounts/recommendations"
          render={() => {
            const viewFilters: Partial<FilterTypes> = {
              ...filters,
              created_at: (filters.created_at || [])[0],
              customer_account: getAccountType('customer_account'),
              pipeline_account: getAccountType('pipeline_account'),
              prospect_account: getAccountType('prospect_account'),
            };
            return <AccountRecommendations filters={viewFilters} />;
          }}
        />
        <Route
          path="/accounts/analytics"
          render={() => <AccountsAnalytics />}
          strict
        />
      </article>
    </section>
  );
};

const mapDispatchToProps: DispatchProps = {
  persistTableParams: actions.ui.table.persist,
  persistModalParams: actions.ui.modal.persist,
};

const mapStateToProps = (state: IReduxState): StateProps => ({
  crmType: getCrmType(state),
  filters: getFiltersForAPI(state, 'accounts'),
  sortAccountsPrevState: getTablePrevState(state, 'accounts', 'Accounts'),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsDashboard);
