import { useLocalStorage } from 'components/hooks/useLocalStorage';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUser } from 'selectors';

export type PinnedColumns = string[];

const usePinColumns = (
  tableId: string,
  visibleColumnsId: string[],
  defaultPinnedColumns: number,
  enabled: boolean
) => {
  const { email } = useSelector(getUser);
  const { pathname } = useLocation();
  const completeId = `${email}-${pathname}-${tableId}-pinned-columns`;
  const [pinnedColumns, setPinnedColumns] = useLocalStorage<string[]>(
    enabled ? completeId : '',
    []
  );

  const handlePinChange = (columnId: string) => {
    const columnPinIndexModified =
      visibleColumnsId.findIndex((id) => id === columnId) + 1;
    const isUnpinning = pinnedColumns.includes(columnId);
    const pinTo = isUnpinning
      ? columnPinIndexModified - 1
      : columnPinIndexModified;
    setPinnedColumns(visibleColumnsId.slice(0, pinTo));
  };

  useEffect(() => {
    // displayedColumns is not set yet on first render
    // So we need to wait for it to have some data
    if (visibleColumnsId.length && enabled) {
      // In case a column that was pinned was hidden or removed
      // We have to change the pinned columns to the last one that is displayed
      const lastPinnedColumnThatIsDisplayed = visibleColumnsId.findIndex(
        (id, idx) => id !== pinnedColumns[idx]
      );

      const foundALastPinnedColumn = lastPinnedColumnThatIsDisplayed !== -1;
      const pinnedColumnsCountChanged =
        lastPinnedColumnThatIsDisplayed !== pinnedColumns.length;

      if (foundALastPinnedColumn && pinnedColumnsCountChanged) {
        setPinnedColumns(
          visibleColumnsId.slice(0, lastPinnedColumnThatIsDisplayed)
        );
      }
    }
  }, [visibleColumnsId, pinnedColumns, defaultPinnedColumns, enabled]);

  const pinnedColumnsCount = enabled
    ? pinnedColumns.length || defaultPinnedColumns
    : 0;

  return {
    pinnedColumnsCount,
    handlePinChange,
  };
};

export default usePinColumns;
