import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import { TABLE_ID } from 'common/constants';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuDropdown from 'components/UI/BuDropdown';
import Table from 'components/UI/TableConfig/Table';
import {
  ButtonContainer,
  DropDownItemsContainer,
  tableColumnsSelectionStyle,
} from 'components/UI/common/DraggableColumnsControl/styles';
import {
  IColumn,
  IRow,
  onChangeCallback,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { IReduxState } from 'reducers/types';
import { getPersistName } from 'selectors';
import tableColumnsSelectionIcon from '../../../../assets/fonts/boostup-icons/table_columns_selection.svg';

interface columnItem {
  label: string;
  id: string;
  show: boolean;
  fixed_order?: boolean;
}

interface IProps {
  displayedColumns: columnItem[];
  isModal?: boolean;
}

const tableColumns: IColumn[] = [
  {
    config: {},
    id: 'label',
    label: 'name',
    field: 'label',
    type: ColumnTypes.TEXT,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
  },
  {
    config: { style: 'toggle' } as any,
    id: 'show',
    label: 'show',
    field: 'show',
    editable: true,
    disable: (row: IRow): any => row.fixed_order,
    type: ColumnTypes.CHECKBOX,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
  },
];

// typescript complaint id is missing if columns is used directly as IRow. strange
const columnsToRow = (columns: columnItem[]): IRow[] =>
  columns.map((column) => {
    return { ...column };
  });

export const DraggableColumnsControl: React.FC<IProps> = ({
  displayedColumns,
  isModal = false,
}) => {
  const [columns, setColumns] = useState<columnItem[]>(displayedColumns);
  const [isColumnUpdated, setIsColumnUpdated] = useState<Boolean>(false);
  const persistName = useSelector((state: IReduxState) => {
    return getPersistName(state, isModal);
  });
  const dispatch = useDispatch();
  useEffect(() => {
    setColumns(displayedColumns);
  }, [displayedColumns]);

  const toggleShowHide: onChangeCallback = (_, row) => {
    setIsColumnUpdated(true);
    const updatedColumns = columns.map((column) => {
      if (column.id === row.id) {
        return { ...column, show: !column.show };
      }
      return column;
    });

    setColumns(updatedColumns);
  };

  const handleDrop = (rows: IRow[]) => {
    setIsColumnUpdated(true);
    const updatedColumns: columnItem[] = rows.map((row, idx) => {
      const idParts = (row.id as string).split('-');
      idParts.slice(0, -1).push(idx.toString());
      return {
        id: idParts.join('-'),
        label: row.label as string,
        show: row.show as boolean,
      };
    });
    setColumns(updatedColumns);
  };

  const handleApply = () => {
    setIsColumnUpdated(false);

    dispatch(
      actions.ui.appState.replaceShowOrHideColumns({
        path: persistName,
        columns,
      })
    );
  };

  const handleCancel = () => {
    setIsColumnUpdated(false);
    setColumns(displayedColumns);
  };

  return (
    <BuDropdown
      label={
        <img
          className={tableColumnsSelectionStyle}
          src={tableColumnsSelectionIcon}
          alt="table columns selection"
        />
      }
      tooltip="Columns"
      secondary
      size={BuControlSize.SMALL}
      iconDropdown
    >
      {(hide) => (
        <>
          <DropDownItemsContainer>
            <Table
              tableId={TABLE_ID.DRAGGABLE_COLUMNS}
              columns={tableColumns}
              data={columnsToRow(columns)}
              rowsPerPage={0}
              currentPage={0}
              enableReorderingRows
              onDrop={handleDrop}
              fullscreen
              onChange={toggleShowHide}
              hidePaginationStart
              hidePaginationEnd
              hideSearch
              hideColumnHeader
              canDragRow={(row: RollUpsConfig.ColumnDetail) => !row.fixed_order}
            />
          </DropDownItemsContainer>
          <ButtonContainer>
            <BuButton
              onClick={() => {
                handleApply();
                hide();
              }}
              disabled={!isColumnUpdated}
            >
              Apply
            </BuButton>
            <BuButton
              secondary
              onClick={() => {
                handleCancel();
                hide();
              }}
            >
              Cancel
            </BuButton>
          </ButtonContainer>
        </>
      )}
    </BuDropdown>
  );
};
