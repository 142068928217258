import React from 'react';
import classNames from 'classnames';
import { css } from 'emotion';

import Header from 'components/UI/Wrapper/Public/Header';

import * as s from '../Wrapper/styles';

const overrideContentBox = css`
  width: 100%;
  margin-left: 0;
`;

interface Props {
  component: React.ComponentType;
}

const PublicWrappedComponent: React.FC<Props> = (props) => (
  <div
    id="main_content"
    className={classNames(s.content_box, overrideContentBox)}
  >
    <Header />

    <div>
      <props.component {...props} />
    </div>
  </div>
);

export default PublicWrappedComponent;
