import { SubValuesData } from 'api/RevBiDropdownConfigs';
import classNames from 'classnames';
import {
  MetricPropertyWrapper,
  SavedMetricProperty,
  SubValueConfiguration,
  SubValueWrapper,
  subValueSelect,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/DefinitionsMetricsList/styles';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import BuCheckbox from 'components/UI/BuCheckbox';
import BuInput from 'components/UI/BuInput';
import BuSelect from 'components/UI/BuSelect';
import MetricColorPicker from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/DefinitionsMetricsList/MetricProperties/MetricColorPicker';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  BIMetricCreated,
  MetricDisplayInfo,
  MetricsDisplay,
} from 'components/dashboard/Metrics/metrics.types';

interface IProps {
  readonly metric: BIMetricCreated;
  readonly metricsDisplayConfig: MetricsDisplay | undefined;
  subValuesData: SubValuesData | undefined;
  isLoadingSubValueList: boolean;
  onChange: (metricId: string, config: MetricDisplayInfo) => void;
}

export const MetricProperty: React.FC<IProps> = ({
  metricsDisplayConfig,
  metric,
  subValuesData,
  isLoadingSubValueList,
  onChange,
}) => {
  /**
   * for now only one section is only available for Live type
   * if in the future we have more section please use this dictionary.
   */
  const propertyEnableSettings = useMemo(() => {
    const isLive = metric.analysis_type === AnalysisType.LIVE;

    return {
      subValue: isLive,
    };
  }, [metric]);

  const metricDisplayConfig: MetricDisplayInfo = useMemo(
    () => (metricsDisplayConfig ? metricsDisplayConfig[metric._id] : {}),
    [metricsDisplayConfig, metric._id]
  );
  const subValue = useMemo(() => {
    if (metricDisplayConfig) {
      const key = Object.keys(metricDisplayConfig.subvalues ?? {})[0];
      if (key && metricDisplayConfig.subvalues) {
        return metricDisplayConfig.subvalues[key];
      }
    }
  }, [metricDisplayConfig]);

  const [showSubValue, setShowSubValue] = useState(!!subValue);

  const [displayName, setDisplayName] = useState<string>(
    metricDisplayConfig?.display_name_override ?? metric.name
  );

  const [subValueTemplate, setSubValueTemplate] = useState<string>(
    subValue?.template ?? ''
  );

  const triggerDelayedChange = useCallback(
    debounce((metricId: string, value: string) => {
      onChange(metricId, { display_name_override: value });
    }, 750),
    [onChange]
  );

  const triggerDelayedSubValueTemplateChange = useCallback(
    debounce((metricId: string, value: string) => {
      if (subValue) {
        onChange(metricId, {
          subvalues: {
            [subValue.field_name]: { ...subValue, template: value },
          },
        });
      }
    }, 750),
    [onChange]
  );

  return (
    <>
      <SavedMetricProperty>
        <MetricPropertyWrapper>
          <BuCheckbox
            checked={!!metricDisplayConfig?.column_highlight_color}
            onChange={(value) => {
              onChange(metric._id, {
                column_highlight_color: value ? '#FFFFFF' : undefined,
              });
            }}
            elementLabel={
              <label>Add background color to Highlight Column</label>
            }
          />
          <MetricColorPicker
            color={metricDisplayConfig?.column_highlight_color}
            onChange={(value: string) => {
              onChange(metric._id, { column_highlight_color: value });
            }}
            disabled={!metricDisplayConfig?.column_highlight_color}
          />
        </MetricPropertyWrapper>
      </SavedMetricProperty>

      <SavedMetricProperty>
        <label>Display as</label>
        <BuInput
          maxLength={100}
          value={displayName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            setDisplayName(value);
            triggerDelayedChange(metric._id, value);
          }}
        ></BuInput>
      </SavedMetricProperty>
      <SavedMetricProperty>
        <BuCheckbox
          checked={metricDisplayConfig?.display_total ?? true}
          onChange={(value) => {
            onChange(metric._id, { display_total: value });
          }}
          elementLabel={<label>Add this as a top metric</label>}
        />
      </SavedMetricProperty>
      {propertyEnableSettings.subValue && (
        <SavedMetricProperty>
          <BuCheckbox
            checked={showSubValue}
            onChange={(value) => {
              if (!value) {
                setSubValueTemplate('');
                onChange(metric._id, {
                  ...metricDisplayConfig,
                  subvalues: undefined,
                });
              }
              setShowSubValue(value);
            }}
            elementLabel={<label>Show additional/sub value</label>}
          />
          <SubValueConfiguration
            className={classNames({ active: showSubValue })}
          >
            <BuInput
              placeholder=""
              style={{ width: '100px' }}
              maxLength={18}
              disabled={!subValue?.field_name}
              value={subValueTemplate}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.currentTarget.value;
                setSubValueTemplate(value);
                triggerDelayedSubValueTemplateChange(metric._id, value);
              }}
            />
            <SubValueWrapper>
              <BuSelect
                className={subValueSelect}
                placeholder="Select a Metric"
                fullWidth
                disabled={isLoadingSubValueList}
                secondary
                searchable
                options={subValuesData?.subValueOptions ?? []}
                defaults={[subValue?.field_name ?? '']}
                isLargePlaceholder
                onChange={(value: string[]) => {
                  const newSubValue = subValuesData?.subValueList.find(
                    (item) => item.field_name === value[0]
                  );

                  if (newSubValue) {
                    // as a SubValue we wanna only one value for now.
                    onChange(metric._id, {
                      ...metricDisplayConfig,
                      subvalues: {
                        [value[0]]: { ...newSubValue },
                      },
                    });
                  }

                  setSubValueTemplate('');
                }}
              />
            </SubValueWrapper>
          </SubValueConfiguration>
        </SavedMetricProperty>
      )}
    </>
  );
};
