import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontDefault } from 'assets/css/common';
import classNames from 'classnames';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { css } from 'emotion';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { BuCharLengthInfo } from './NoteCell';

interface NoteCellSubmitClose {
  draft: string;
  action: 'submit';
}

interface NoteCellSaveDraftClose {
  draft: string;
  action: 'save_draft';
}

interface NoteCellCancelClose {
  action: 'cancel';
}

export type NoteCellClose =
  | NoteCellSubmitClose
  | NoteCellSaveDraftClose
  | NoteCellCancelClose;

export type NoteCellOnClose = (payload: NoteCellClose) => void;

interface NoteCellModalProps {
  title: string;
  name?: string;
  note: string;
  maxLength?: number;

  onClose: NoteCellOnClose;
}

const modalContainer = css`
  display: flex;
  height: 85vh;
  gap: 16px;
  padding: 24px;
  flex-direction: column;
`;

const subHeaderText = css`
  margin-left: 12px;
`;

const iconContainer = css`
  all: unset;
  cursor: pointer;
`;

const headerText = css`
  margin: 0;
  font-size: var(--bu-font-size-big);
  font-weight: bold;
`;

const headerContainer = css`
  display: flex;
  justify-content: space-between;
`;

const bold = css`
  font-weight: bold;
`;

const textArea = css`
  ${fontDefault};
  align-self: stretch;
  color: var(--bu-gray-700);
  border: 1px solid var(--bu-primary-500);
  border-radius: 4px;
  padding: 5px;
  flex-grow: 1;
  margin-bottom: -14px;

  resize: none;

  &.error {
    transition: 0.4s all;
    border: 1px solid var(--bu-red-400);
    background-color: var(--bu-red-100);
  }
`;

const contentWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 23px;
  flex-grow: 1;
`;

export const NoteCellModal = ({
  title,
  note,
  name,
  maxLength,
  onClose,
}: NoteCellModalProps) => {
  const [draft, setDraft] = useState<string>(note || '');

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(
        textAreaRef.current.value.length,
        textAreaRef.current.value.length
      );
    }
  }, []);

  const handleSubmit = () => {
    onClose({
      draft,
      action: 'submit',
    });
  };

  const handleCancel = () => {
    onClose({
      action: 'cancel',
    });
  };

  const handleSaveDraft = () => {
    onClose({
      draft,
      action: 'save_draft',
    });
  };

  // Hack to discard closing a modal when inside a parent modal
  // without this, the parent modal will close when clicking on the child modal (that is why we have the div wrapping the modal with onClick)
  // or when clicking on the dimmer (that is why we use the onClose prop)
  const discardClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    return;
  };

  return (
    <div onClick={discardClose}>
      <Modal size="small" onClose={discardClose} open>
        <div className={modalContainer}>
          <div className={classNames(headerContainer, 'bu-font-heading')}>
            <div>
              <h2 className={headerText}>{title}</h2>
              {name && (
                <p className={subHeaderText}>
                  <span className={bold}>Name: </span>
                  {name}
                </p>
              )}
            </div>
            <button className={iconContainer} onClick={handleCancel}>
              <BuIcon name={BoostUpIcons.ClosePopup} />
            </button>
          </div>
          <div className={contentWrapper}>
            <textarea
              className={classNames(textArea, {
                error: maxLength && draft.length > maxLength,
              })}
              ref={textAreaRef}
              onChange={(e) => {
                setDraft(e.target.value);
              }}
              value={draft}
              data-testing="txt_field"
            />
            <BuCharLengthInfo maxLength={maxLength} textLength={draft.length} />
            <div>
              <BuButton
                disabled={maxLength ? draft.length > maxLength : false}
                size={BuControlSize.SMALL}
                onClick={handleSubmit}
              >
                Save
              </BuButton>

              <BuButton
                secondary
                size={BuControlSize.SMALL}
                onClick={handleSaveDraft}
              >
                Save Draft
              </BuButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
