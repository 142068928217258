import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // If the error is a 401, redirect to the login page
    if (error.response && error.response.status === 401) {
      if (!window.location.pathname.includes('/login')) {
        window.location.href =
          window.location.pathname !== '/'
            ? `/login?next=${window.location.pathname}${window.location.search}`
            : '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
