import {
  cleanWidgetCache,
  GetWidgetDataBody,
  GetWidgetResponse,
  HierarchicalWidgetNode,
  WidgetColumnConfiguration,
  WidgetColumnErrorConfiguration,
  WidgetMetricConfiguration,
  WidgetPivotConfiguration,
} from 'api/RevBiWidget';
import {
  PathAwareHierarchicalWidgetNode,
  PIVOT_HIERARCHY_SEPARATOR,
} from '../useExpandableWidgetTable/useExpandableWidgetTable.helper';

import { useMutation } from '@tanstack/react-query';
import {
  DataDescriptor,
  BIMetricFormula,
  BIMetricSimple,
  BIWidget,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';

export interface GetWidgetDataBodyWithKey {
  payload: GetWidgetDataBody;
  queryKey: string[];
}

export interface HierarchicalWidgetNodeSubtree {
  nodes: HierarchicalWidgetNode[];
  parentPath: string[];
}

export interface HierarchicalWidget {
  tree: PathAwareHierarchicalWidgetNode[];
  totals: PathAwareHierarchicalWidgetNode;
  metricConfigurations: WidgetMetricConfiguration[];
  pivotConfigurations: WidgetPivotConfiguration[];
  errors: WidgetColumnErrorConfiguration;
  updatedAt: string;
}

export const isHierarchicalWidget = (
  obj: BIWidgetDataV2 | HierarchicalWidget
): obj is HierarchicalWidget => {
  return 'tree' in obj;
};

/**
 * This is a key used to identify the whole batch fetching of the widget
 * Subsequent keys are the expanded node paths
 */
export const BATCH_WIDGET = 'BATCH_WIDGET';

export const getPayloadForWidgetBatchFetching = (
  expandedNodePaths: string[],
  pivots: DataDescriptor[],
  sharedPayload: BIWidget
): GetWidgetDataBodyWithKey[] => {
  const dashboardFilters =
    sharedPayload.dashboard_filters?.filter(
      (elem) => elem.operator !== 'all'
    ) ?? [];
  const hasDatePivot = pivots.some((groupBy) => groupBy.type === 'date');
  const dateDeltaPosition = hasDatePivot ? 1 : 0;

  const openedPayloads = expandedNodePaths.map((expandedNodePath) => {
    const expandedPathArray = expandedNodePath.split(PIVOT_HIERARCHY_SEPARATOR);

    const levelsOpened = expandedPathArray.length + dateDeltaPosition;

    const pivotToExpand = pivots[levelsOpened + 1] ?? null;

    // in general we will group by the last pivot opened
    // but when the user is using a date we should group by
    // two pivot, time and the last pivot opened.
    const pivotsToGroup = hasDatePivot
      ? [pivots[0], pivots[levelsOpened]]
      : [pivots[levelsOpened]];

    const pivotToFilter = expandedPathArray.map((pivotValue, index) => ({
      operator: 'eq',
      value: pivotValue,
      column: pivots[index + dateDeltaPosition],
    }));

    return {
      queryKey: [BATCH_WIDGET, ...expandedPathArray],
      payload: {
        ...sharedPayload,
        dashboard_filters: dashboardFilters,
        group_by: pivotsToGroup,
        pivot_filters: pivotToFilter,
        pivot_to_expand: pivotToExpand,
      },
    };
  });

  let groupBy = [pivots[0]];
  let expand = pivots[1];

  // date pivot is only available in the 1st pivot,
  //so if the user has two pivots we should send both pivots.
  if (hasDatePivot) {
    groupBy = pivots.slice(0, 2);
    expand = pivots[2] ?? null;
  }

  // First payload has to be done even when there are no opened nodes
  const mainPayload = {
    queryKey: [BATCH_WIDGET],
    payload: {
      ...sharedPayload,
      dashboard_filters: dashboardFilters,
      group_by: groupBy,
      pivot_filters: [],
      pivot_to_expand: expand,
    },
  };

  return [mainPayload, ...openedPayloads];
};

const appendSubtreesToTree = (
  tree: HierarchicalWidgetNode[],
  subtrees: HierarchicalWidgetNodeSubtree[],
  pivots: WidgetPivotConfiguration[],
  parentPath: string[] = []
): PathAwareHierarchicalWidgetNode[] =>
  tree.map((node) => {
    const currentPivotIndex = parentPath.length;
    const currentPivot = pivots[currentPivotIndex];

    const nodePivotValue = node[currentPivot.field_name];
    // i will check if the field name has a title, in order to add it in the row data.
    const fieldTitle = `${currentPivot.field_name.split('$')[0]}$title`;
    const nodePivotTitleValue = node[fieldTitle] ?? '';

    const childrenSubtrees = subtrees.filter(
      // We don't have to compare the whole path, as the subtrees were already filtered
      // If the subtrees have the same value as my pivot, it means that they are my children
      (subtree) => subtree.parentPath[currentPivotIndex] === nodePivotValue
    );

    const nodePath = [...parentPath, nodePivotValue];
    const stringedTreePath = nodePath.join(PIVOT_HIERARCHY_SEPARATOR);
    const managerHierarchyChildren = node.children
      ? appendSubtreesToTree(
          node.children,
          // As this is a manager hierarchy
          // We cant filter down the subtrees
          subtrees,
          pivots,
          // We don't go deeper in the pivot tree
          // Pivot is the same as its parent
          parentPath
        )
      : undefined;

    const nodeWithManagerHierarchyChildren: PathAwareHierarchicalWidgetNode = {
      ...node,
      nodePath: stringedTreePath,
      nodePivotTitleValue,
      children: managerHierarchyChildren,
    } as PathAwareHierarchicalWidgetNode;

    if (!childrenSubtrees) {
      return nodeWithManagerHierarchyChildren;
    }

    const myDirectChildren = childrenSubtrees.find(
      (subtree) => subtree.parentPath.length === currentPivotIndex + 1
    );

    // Is possible for the BE to return a hierarchy already built
    // So we need to be sure to check the other subtrees or what we
    // already have returned by the BE
    const myDirectChildrenNodes = myDirectChildren?.nodes || node.pivots;

    const nonDirectChildren = childrenSubtrees.filter(
      (subtree) => subtree !== myDirectChildren
    );

    if (!myDirectChildrenNodes || !childrenSubtrees) {
      return nodeWithManagerHierarchyChildren;
    }

    const pivotsChildren = appendSubtreesToTree(
      myDirectChildrenNodes,
      nonDirectChildren,
      pivots,
      nodePath
    );

    return {
      ...nodeWithManagerHierarchyChildren,
      pivots: pivotsChildren,
    };
  });

export const getMetricConfigurations = (
  widgetData: GetWidgetResponse | undefined,
  widgetConfiguration: BIWidget
): WidgetMetricConfiguration[] => {
  if (!widgetData) {
    return [];
  }
  const metricColumnsConfigurationResponse = widgetData.metric_columns;

  const displayMetricsConfig =
    widgetConfiguration.advanced_configurations?.display?.metrics ?? {};

  const metricsWithDisplayName = metricColumnsConfigurationResponse.map(
    (metricColumn) => {
      const displayConfig = displayMetricsConfig[metricColumn.field_name] ?? {};
      const metricMetadata = widgetConfiguration.metric_list.find(
        (metric) => metricColumn.field_name === metric._id
      )?.metadata;

      return {
        ...metricColumn,
        display_name:
          displayConfig.display_name_override || metricColumn.display_name,
        displayConfig,
        metadata: metricMetadata,
      };
    }
  );

  return metricsWithDisplayName.map((metric) => ({
    ...metric,
    metrics: metricsWithDisplayName,
  }));
};

export const getPivotConfigurations = (
  widgetData: GetWidgetResponse | undefined,
  widgetConfiguration: BIWidget,
  { usePivotDataFromResponse }: { usePivotDataFromResponse: boolean }
): WidgetPivotConfiguration[] => {
  const parsedPivotsConfiguration: WidgetColumnConfiguration[] =
    // If we are batch fetching we use the group_by configuration
    // As we won't have the pivot columns in the first response
    // We need to change the field name to match what the BE returns
    !usePivotDataFromResponse
      ? widgetConfiguration.group_by.map((group) => ({
          display_name: group.label,
          field_name: group.name.replace('.', '$'),
          type: group.type,
        }))
      : // If we are not batch fetching we use the pivot_columns configuration
        // As we will have the pivot columns in the first response
        // This is actually useful needed for the historical widgets
        // as the group_by configuration doesn't contains the date pivot
        // but is returned on the pivot_columns
        // If not for historical we could always use the group_by configuration
        widgetData?.pivot_columns ?? [];

  return parsedPivotsConfiguration.map((pivot) => ({
    ...pivot,
    // we need to review this when we remove the hack that replaces '.' to '$'
    table_name: pivot.field_name.split('$')[0],
  }));
};

export const buildWidgetTreeFromBatchFetching = (
  subTrees: HierarchicalWidgetNodeSubtree[],
  pivots: WidgetPivotConfiguration[]
): PathAwareHierarchicalWidgetNode[] => {
  const sortedSubtrees = subTrees.sort(
    (a, b) => a.parentPath.length - b.parentPath.length
  );

  // First layer is actually the totals
  // and inside that layer we have the root nodes
  const totalSubtree = sortedSubtrees.find(
    (segment) => segment.parentPath.length === 0
  );
  const totals = totalSubtree?.nodes?.[0];
  const rootSubtreeNodes = totals?.pivots;

  if (totals && !pivots.length) {
    return [
      {
        ...totals,
        nodePath: '',
        children: undefined,
        pivots: [],
      },
    ];
  }

  if (!rootSubtreeNodes) {
    return [];
  }
  const childrenSubtrees = sortedSubtrees.slice(1);

  const completeTree = appendSubtreesToTree(
    rootSubtreeNodes,
    childrenSubtrees,
    pivots
  );

  return [
    {
      ...totals,
      nodePath: '',
      children: undefined,
      pivots: completeTree,
    },
  ];
};

/**
 * This function is used to get the data that should be used to distinguish
 * if the widget configuration has changed
 *
 * We only want to refresh the data and discard the old endpoints data, only when this specific
 * properties changes
 */
export const getWidgetDataThatShouldResetTreesIfChanged = (
  widget: BIWidget,
  widgetType: string
) => {
  const metricDependencies = widget.metric_list.map(
    (metric: Partial<BIMetricSimple & BIMetricFormula>) => ({
      aggregation_function: metric.aggregation_function,
      column: {
        name: metric.column?.name,
      },
      object: metric.object,
      is_cumulative_sum: metric.is_cumulative_sum,
      cumulative_sum_period: metric.cumulative_sum_period,
      target_period: metric.target_period,
      target_type: metric.target_type,
      manager_aggregation_type: metric.manager_aggregation_type,
      forecast_submission_properties: {
        metric_type: metric.forecast_submission_properties?.metric_type,
        calculation: metric.forecast_submission_properties?.calculation,
      },
      synthetic_metric: metric.synthetic_metric,
      filters: metric.filters,
    })
  );

  const displayConfigs = widget.advanced_configurations?.display?.metrics || {};
  const displayConfigKeys = Object.keys(displayConfigs);
  const displayNames = displayConfigKeys.map(
    (key) => displayConfigs[key].display_name_override || ''
  );
  const subValues = displayConfigKeys.map((key) => {
    const subValue = displayConfigs[key]
      ? displayConfigs[key].subvalues ?? {}
      : {};
    const keys = Object.keys(subValue);
    return subValue[keys[0]]?.field_name ?? '';
  });

  return {
    widgetType,
    displayNames,
    subValues,
    group_by: widget.group_by,
    metric_list: metricDependencies,
    time_field: widget.time_field,
    template_filters: widget.template_filters,
    time_interval: widget.time_interval,
    time_period: widget.time_period,
    point_in_time: widget.point_in_time,
    compute_user_hierarchy_response: widget.compute_user_hierarchy_response,
    remove_reportees_data_from_managers:
      widget.advanced_configurations?.remove_reportees_data_from_managers,
    dashboard_filters: widget.dashboard_filters,
  };
};

/**
 * This function provides a mutation in order to clean the server side cache
 * and a function to refetch the pivot one by one with a parameterizable delay
 *
 * @param widgetId
 * @param urlQuery
 */
export const useCacheMutation = (
  widgetId: string,
  urlQuery?: { user_status: string | undefined }
) => {
  const cleanCacheMutation = useMutation({
    mutationFn: () => {
      return cleanWidgetCache(widgetId, urlQuery);
    },
    onMutate: async () => {},
    onSettled: () => {
      //settled
    },
    onError: () => {
      //onError
    },
  });

  const fetchPivotDelayedBy = (
    numberOfPivots: number,
    refetch: (level?: string) => void,
    delay: number = 900
  ) => {
    for (let position = 1; position <= numberOfPivots; position++) {
      setTimeout(() => refetch(`level-${position}`), delay * (position - 1));
    }
  };

  return { cleanCacheMutation, fetchPivotDelayedBy };
};

/**
 * Finds a node in a hierarchical widget tree based on a given path.
 * Path format is expected to be as the one built by PathAwareHierarchicalWidgetNode
 * that is a string with the values separated by PIVOT_HIERARCHY_SEPARATOR
 *
 * @returns The found node or undefined if not found.
 */
export const findNodeInTree = (
  path: string,
  tree: PathAwareHierarchicalWidgetNode[]
): PathAwareHierarchicalWidgetNode | undefined => {
  if (tree.length === 0) {
    return;
  }

  const node = tree.find(
    (node) =>
      path.includes(node.nodePath) || findNodeInTree(path, node.children || [])
  );

  if (!node) {
    return;
  }

  const isLastNode = path === node.nodePath;

  if (isLastNode) {
    return node;
  }

  return findNodeInTree(path, node.pivots || []);
};
