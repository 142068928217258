import * as Sentry from '@sentry/browser';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import * as R from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
  generateMetricsFromColumns,
  mapMetricsToBuMetricsPanelItems,
} from './helpers/metrics';

import {
  clearSubmitForecastFlag,
  onRefreshForecast,
} from 'actions/forecastActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import TickAlertCircle from 'assets/images/icons/tick_alert_circle.svg';
import { TABLE_ID } from 'common/constants';
import { getStartDateForChangeInterval } from 'common/dates';
import { formatMoney } from 'common/numbers';
import ActiveUsersToggle from 'components/UI/ActiveUsersToggle';
import { BuControlSize, buttonStyle } from 'components/UI/BuButton';
import BuButtonRefresh from 'components/UI/BuButtonRefresh';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
  getDropdownItemProps,
} from 'components/UI/BuDropdown';
import BuIcon from 'components/UI/BuIcon';
import BuMetricsPanel, {
  BuMetricsPanelItem,
} from 'components/UI/BuMetricsPanel';
import BuToggle from 'components/UI/BuToggle';
import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import ExportCSVFromJSON from 'components/UI/ExportCSVFromJSON';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import Table from 'components/UI/TableConfig';
import {
  CallbackProps,
  multiplierFormatter,
  percentFormatter,
} from 'components/UI/TableConfig/column-helper';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import TwoColumnsDashboard from 'components/UI/TwoColumnsDashboard';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import {
  IColumn,
  IRow,
  TooltipConfig,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { setRowExpand } from 'components/UI/common/TypedTable/helper';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import SubmitForecast from 'components/dashboard/Forecast/Dashboard/SubmitForecast';
import Tooltip from 'components/dashboard/Forecast/Dashboard/SubmitForecast/Tooltip';
import {
  CellTooltip,
  ColumnHeaderTooltip,
  MyOrgCustomTooltip,
} from 'components/dashboard/ForecastRollUps/CustomColumnTooltips';
import ErrorMessage from 'components/dashboard/ForecastRollUps/ErrorMessage';
import {
  extractIdsFromRow,
  findCurrentUserRow,
  getColumnByFieldName,
  getCSVData,
  getCSVHeaders,
  getPipelineGapClassName,
  getSubmissionWithColumn,
  isEmptyValue,
  processTableData,
  useRecalculateDealsStatusModal,
} from 'components/dashboard/ForecastRollUps/helpers';
import {
  getExpressionWithDisplayName,
  getFormulaKeys,
  getFormulaKeysValues,
} from 'components/dashboard/ForecastRollUps/helpers/formulas';
import * as styles from 'components/dashboard/ForecastRollUps/styles';
import {
  DeltasTag,
  ExclamationIconWrapper,
} from 'components/dashboard/ForecastRollUps/styles';
import {
  ApiResponse,
  AppliedFilters,
  DefaultPivotsProps,
  OpenModalProps,
  Pivots,
  Props,
  RollupsTableConfigurationColumn,
  SingleTableData,
} from 'components/dashboard/ForecastRollUps/types';
import TabTitle from 'components/dashboard/Tabs/TabTitle';
import { useElementSize } from 'components/hooks/useElementSize';
import { openModal } from 'navigation/utils';
import {
  getCompanyCurrency,
  getCompanySettings,
  getCompanyTimezone,
  getFeatureFlags,
  getIncludeDisabledUsersByDefautOnRollups,
  getUserLocalCurrency,
  isReadOnlyUser,
} from 'selectors';
import {
  getForecastSubmissionProfilesDeadlines,
  isForecastSubmissionLoaded,
} from 'selectors/forecastSubmission';
import { fetchApi, fetchApiPromise } from 'utils/network';
import { usePageSize } from 'components/hooks/usePageSize';

const apiPointTable = `${process.env.REACT_APP_BACKEND_URL}/api/data/dynamic_tables/forecast_roll_ups`;
const apiPointPreviewTable = (tableId: string) =>
  `${process.env.REACT_APP_BACKEND_URL}/api/data/dynamic_tables/preview/${tableId}`;
const apiPointPreviewDeltaTable = (tableId: string) =>
  `${process.env.REACT_APP_BACKEND_URL}/api/data/dynamic_tables/preview/delta/${tableId}`;
const apiPointTableOverall = `${process.env.REACT_APP_BACKEND_URL}/api/data/dynamic_tables/forecast_roll_ups_overall`;
const apiPointDeltas = `${process.env.REACT_APP_BACKEND_URL}/api/data/dynamic_tables/forecast_roll_ups_deltas`;
const apiPointDeltasOverall = `${process.env.REACT_APP_BACKEND_URL}/api/data/dynamic_tables/forecast_roll_ups_overall_deltas`;
const apiPivots = `${process.env.REACT_APP_BACKEND_URL}/api/data/dynamic_tables/pivots/forecast_roll_ups`;
const apiPointPreviewPivots = `${process.env.REACT_APP_BACKEND_URL}/api/data/dynamic_tables/pivots_preview/`;
const toastOptions: ToastOptions = { position: 'bottom-left' };

const primaryField = 'My Org';
const OVERALL_BUSINESS_TYPE = 'Overall';
const DEFAULT_PIVOT = {
  display_name: 'Managers',
  index: 'user._id',
  secondary_pivot_name: 'months_breakdown',
};

const ForecastRollUps: React.FC<Props> = ({
  isOverallEnabled,
  selectedBusinessType,
  businessTypesList,
  clearOnExit,
  companyCurrency,
  fetchForecastSubmission,
  filters,
  isSubmissionAvailable,
  persistModalParams,
  submissionSettings,
  user,
  match,
  isSubmitForecast,
  cacheSettings,
  isSubmissionLoaded,
  companySettingsLoaded,
}) => {
  const {
    clearContext,
    setPartition,
    isSubmitForecastPanelOpen,
    setShowSubmitForecastButton,
    setIsSubmitForecastPanelOpen,
    setRenderExtraTabs,
  } = useHeader();

  const userCurrencyCode = useSelector(getUserLocalCurrency);
  const companyCurrencyCode = useSelector(getCompanyCurrency);
  const isReadOnly = useSelector(isReadOnlyUser);
  const companySettings = useSelector(getCompanySettings);
  const profilesDeadlines = useSelector(getForecastSubmissionProfilesDeadlines);
  const forecastSubmissionLoaded = useSelector(isForecastSubmissionLoaded);
  const includeDisabledUsersByDefautOnRollups = useSelector(
    getIncludeDisabledUsersByDefautOnRollups
  );

  const [refreshCache, setRefreshCache] = useState<boolean>(false);
  const [cacheDate, setCacheDate] = useState('');
  const [isVisiblePanel, setIsVisiblePanel] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SingleTableData>({
    columns: [],
    rows: [],
  });
  const [rawTable, setRawTable] = useState<SingleTableData>({
    columns: [],
    rows: [],
  });

  const [metricsItems, setMetricItems] = useState<BuMetricsPanelItem[]>();
  const [pivots, setPivots] = useState<Pivots[]>();
  const [defaultPivots, setDefaultPivots] =
    useState<DefaultPivotsProps>(DEFAULT_PIVOT);
  const [activePivot, setActivePivot] =
    useState<DefaultPivotsProps>(defaultPivots);
  const [includeDisabledUsers, setIncludeDisabledUsers] = useState(
    includeDisabledUsersByDefautOnRollups
  );
  const [showingMetrics, setShowingMetrics] = useState(true);

  useEffect(() => {
    setIncludeDisabledUsers(includeDisabledUsersByDefautOnRollups);
  }, [includeDisabledUsersByDefautOnRollups]);

  // dataset is the final data we send to the table,
  // because this involves various useEffect (multiple slow renders) until the final
  // data is ready, we use another state to know when the data is ready to be sent to the table
  // so we don't send incorrect data to the table
  const [dataset, setDataset] = useState<IRow[]>([]);
  const [isDataSetLoading, setIsDataSetLoading] = useState<boolean>(false);

  const prevFiltersRef = useRef<AppliedFilters>();
  const [rollupsResponse, setRollupsResponse] = useState<ApiResponse>();
  const [deltaResponse, setDeltaResponse] = useState<ApiResponse>();
  const [hasEverLoaded, setHasEverLoaded] = useState<boolean>(false);
  const [hasEverLoadedDeltas, setHasEverLoadedDeltas] =
    useState<boolean>(false);
  const [loadingAndProcessingRollups, setLoadingAndProcessingRollups] =
    useState<boolean>(true);
  const [loadingAndProcessingDeltas, setLoadingAndProcessingDeltas] =
    useState<boolean>(false);
  const [thereIsAnError, setThereIsAnError] = useState<boolean>(false);
  const [listRef, listSize] = useElementSize<HTMLDivElement>();
  const companyTimezone = useSelector((state) => getCompanyTimezone(state));
  const { enable_pipeline_summary } = useSelector((state) =>
    getFeatureFlags(state)
  );

  const smallActionRow = useMemo(
    () => listSize.width <= 1170,
    [isVisiblePanel, listSize]
  );

  const isCacheEnabled = cacheSettings?.rollups_cache_enabled;

  const dispatch = useDispatch();

  const fetchNewMonthsRows = async (id: string) => {
    const url = match.params.tableId
      ? apiPointPreviewTable(match.params.tableId)
      : apiPointTable;
    const fetchedData = await fetchApiPromise<string, ApiResponse>({
      queryParams: JSON.stringify({
        ...currentFilters.current,
        users_monthly_breakdown: id,
      }),
      url,
    });
    const newFetchedData = fetchedData.result?.data.map((item) => ({
      ...item,
      'My Org': '  ',
    }));

    return newFetchedData as IRow[];
  };

  const onShowMonths = (row: IRow, monthsRows: IRow[], status: string) => {
    const recursive = (rowdata: IRow, row: IRow, months: IRow[]) => {
      if (rowdata.index === row.index) {
        const noDataStatus = months.length === 0 ? 'No Data' : '';
        rowdata.buttonLabelStatus =
          noDataStatus !== '' && status !== 'Loading' ? noDataStatus : status;
        rowdata.monthsExpandedRows = months;
        return rowdata;
      }
      if (rowdata.children) {
        rowdata.children.forEach((child) => recursive(child, row, months));
      }

      return rowdata;
    };

    setDataset((prev) =>
      prev.map((item: IRow) => recursive(item, row, monthsRows))
    );
  };

  const onHideMonths = (row: IRow, status: string) => {
    const recursive = (rowdata: IRow, row: IRow) => {
      if (rowdata.index === row.index) {
        rowdata.buttonLabelStatus = status;
        rowdata.monthsExpandedRows = [];
        return rowdata;
      }
      if (rowdata.children) {
        rowdata.children.forEach((child) => recursive(child, row));
      }

      return rowdata;
    };
    setDataset((prev) => prev.map((item: IRow) => recursive(item, row)));
  };

  const handleMonthsColumnCustomCell = async (row: IRow) => {
    const index = row.index as string;
    if (row.buttonLabelStatus === 'Hide') {
      onHideMonths(row, 'Show');
    } else {
      // add new rows
      onShowMonths(row, [], 'Loading');
      const tableRows = await fetchNewMonthsRows(index);
      const mappedTableRows = tableRows.map((monthRow) => ({
        ...monthRow,
        isMonthRow: true,
      }));
      onShowMonths(row, mappedTableRows, 'Hide');
    }
  };

  const isOverallTabActive = selectedBusinessType === OVERALL_BUSINESS_TYPE;

  const handleChange = (column: IColumn, row: IRow, newValue: ValueProp) => {
    AnalyticsTracker.event(
      {
        filters,
        column: column.label,
        rowId: row.id,
        newValue,
      },
      {
        category: 'Forecast view',
        action: 'Tab: RollUps',
        label: 'Change Table Cell',
      }
    );
  };

  const getProfileDeadline = useCallback(
    (profileId: string) => {
      const profile = profilesDeadlines.find(({ id }) => id === profileId);

      const firstProfileDeadline = profilesDeadlines?.[0];

      /* the default deadline is given without an id */
      const defaultDeadline =
        firstProfileDeadline?.id === null
          ? firstProfileDeadline.deadline
          : null;

      return profile?.deadline || defaultDeadline;
    },
    [profilesDeadlines]
  );

  const deadlineCallback = useCallback(
    (lastSubmission: Moment, profileId: string) => {
      const deadline = moment.utc(getProfileDeadline(profileId));

      return deadline.subtract(2, 'w').isAfter(lastSubmission);
    },
    [profilesDeadlines]
  );

  const appliedFilters = {
    ...filters,
    business_type_name: isOverallTabActive ? undefined : selectedBusinessType,
    include_disabled: includeDisabledUsers,
    ...(activePivot.index !== 'user._id'
      ? { group_by: activePivot.index }
      : {}),
  } as AppliedFilters;

  const currentFilters = useRef<AppliedFilters>(appliedFilters);

  appliedFilters.secondary_pivot =
    activePivot.secondary_pivot_name || 'months_breakdown';
  const serializedQueryParams = JSON.stringify(appliedFilters);

  const appliedCloseDateInterval =
    appliedFilters?.close_date_interval &&
    appliedFilters.close_date_interval[0];

  const isForecastRollUpsDeltasEnabled = Boolean(
    companySettings?.rollups_deltas_enabled
  );

  useEffect(() => {
    setDataset(tableData.rows.length ? tableData.rows : []);
    const thereIsNothingLoading =
      !loadingAndProcessingDeltas && !loadingAndProcessingRollups;
    if (thereIsNothingLoading) {
      setIsDataSetLoading(false);
    }
  }, [tableData.rows]);

  useEffect(() => {
    setPartition('forecast');

    AnalyticsTracker.event(
      {},
      {
        action: 'Open',
        category: 'Forecast',
        label: 'Roll Ups page',
      }
    );

    if (!isSubmissionLoaded) {
      fetchForecastSubmission();
    }
    getPivotsFilters();

    return () => {
      clearContext();
      clearOnExit();
    };
  }, []);

  const currencyValueFormatter = (value: number) =>
    formatMoney(companyCurrency, value, 0);

  const getSubmissionPrefix =
    (column: TableConfigurationColumn): TooltipConfig['getTooltip'] =>
    (row) => {
      const { display_name = '', field_name = '' } = column || {};
      const lastSubmission =
        getFieldValue(`${field_name} Submission Date`, row as IRow) ?? '';
      const lastSubmissionMoment = moment.utc(lastSubmission as string);
      const profileDeadline = moment.utc(
        getProfileDeadline(getFieldValue('Profile ID.$oid', row as IRow))
      );

      const tooltipBody = (
        <div>
          <p>Did not submit</p>
          <p>
            by{' '}
            {profileDeadline
              .subtract(7, 'd')
              .local()
              .format('MMM DD `YY, h:mma')}{' '}
            local time
          </p>
        </div>
      );

      if (R.isEmpty(lastSubmission)) {
        return null;
      }

      const shouldShowDeadline = deadlineCallback(
        lastSubmissionMoment,
        getFieldValue('Profile ID.$oid', row as IRow)
      );

      return shouldShowDeadline && display_name !== 'BoostUp Projection' ? (
        <TooltipWrapper tooltip={tooltipBody}>
          <ExclamationIconWrapper>
            <BuIcon name={BoostUpIcons.DangerCircle} />
          </ExclamationIconWrapper>
        </TooltipWrapper>
      ) : null;
    };

  const getFormulaHeaderTooltip = (column: IColumn): React.ReactNode => (
    <ColumnHeaderTooltip column={column} activePivot={activePivot} />
  );

  const getSubmittedForecastTooltip =
    (column: TableConfigurationColumn): TooltipConfig['getTooltip'] =>
    (value, args) => {
      const {
        display_name: title,
        meta: {
          close_date_interval: metaCloseDateInterval,
          close_date_period: metaCloseDatePeriod,
          forecast_name,
          business_type,
          period_delta: periodDelta,
          period_type: periodType,
        },
      } = column;

      const businessTypeName =
        businessTypesList && business_type
          ? Object.keys(businessTypesList).find(
              (key) => businessTypesList[key] === business_type
            )
          : appliedFilters?.business_type_name;

      const closeDateInterval =
        metaCloseDatePeriod ||
        metaCloseDateInterval ||
        appliedCloseDateInterval;

      if (!args.user_id || args.pivotEnabled) {
        return null;
      }

      // VPD-3435: Avoid tooltip when no value (history) to show inside it
      if (!Number.isFinite(value)) {
        return null;
      }

      let isCurrencyDiff = Boolean(
        args.Currency || companyCurrencyCode !== userCurrencyCode
      );

      if (
        args.Currency === userCurrencyCode ||
        args.Currency === companyCurrencyCode
      ) {
        isCurrencyDiff = false;
      }
      const prevSubmissionDate = args[
        `Prev ${column.object_field} Submission Date`
      ] as string | undefined;
      const changeIntervalValue = currentFilters.current?.change_interval?.[0];
      const DELTA_START_DATE_TO_CHECK_FORMAT = 'MMMM Do h:mm a';
      const abbrCompanyTimezone = moment().tz(companyTimezone).zoneAbbr();
      const startDateForChangeInterval = getStartDateForChangeInterval(
        changeIntervalValue,
        new Date(),
        companyTimezone
      )
        .endOf('D')
        .format(DELTA_START_DATE_TO_CHECK_FORMAT);

      const deltaStartDateToCheck = `${startDateForChangeInterval} ${abbrCompanyTimezone}`;
      const deltaValue = args.deltaValue as number;

      return (
        <Tooltip
          title={title}
          companyCurrency={companyCurrency}
          userId={args.user_id as string}
          closeDateInterval={closeDateInterval}
          businessTypeName={businessTypeName}
          forecastName={forecast_name}
          periodType={periodType}
          periodDelta={periodDelta}
          isCurrencyDiffThenUser={isCurrencyDiff}
          deltaOnFirstHistoryItem={deltaValue}
          submissionDateUsedToCalculateDelta={prevSubmissionDate}
          deltaStartDateToCheck={deltaStartDateToCheck}
        />
      );
    };

  const getFormulaColumnTooltip =
    (
      column: TableConfigurationColumn,
      columns: TableConfigurationColumn[]
    ): TooltipConfig['getTooltip'] =>
    (value, args) => {
      const {
        display_name: title,
        meta: { expression = '' },
      } = column;

      const formulaKeys = getFormulaKeys(expression, true);
      const expressionValues = formulaKeys.map((key) => ({
        variable: getColumnByFieldName(key, columns)?.display_name || key,
        fieldName: key,
        value: args[key] as number,
      }));

      const expressionWithDisplayName = getExpressionWithDisplayName(
        expression,
        columns
      );

      const formulaKeysValues = getFormulaKeysValues(formulaKeys, args);

      const deltaValue = args.deltaValue as number;

      return (
        <CellTooltip
          title={title}
          selectedBusinessType={selectedBusinessType}
          expression={expressionWithDisplayName}
          expressionValues={expressionValues}
          cellValue={value as number}
          companyCurrency={companyCurrency}
          myOrg={args['My Org'] as string}
          currencyValueFormatter={currencyValueFormatter}
          deltaValue={deltaValue}
          formulaKeysValues={formulaKeysValues}
        />
      );
    };

  const getPipelineGapTooltip: TooltipConfig['getTooltip'] = (value, args) => {
    if (isEmptyValue(value)) {
      return value;
    }

    const multiplier = args['User Role Multiplier'] as number,
      target = args.Target as number,
      booked = args.Booked as number,
      expectedPipeline = multiplier * (target - booked),
      currentPipeline = args['Total Pipe'] as number;

    return (
      <>
        <div className={styles.tooltip_line}>
          Expected Pipeline:{' '}
          <span>{currencyValueFormatter(expectedPipeline)}</span>
        </div>
        <div className={styles.tooltip_line}>
          Current pipeline:{' '}
          <span>{currencyValueFormatter(currentPipeline)}</span>
        </div>
        <div className={getPipelineGapClassName(value)}>
          ({percentFormatter(value as number)} of expected)
        </div>
      </>
    );
  };

  const getPipelineCoverageTooltip: TooltipConfig['getTooltip'] = (
    value,
    args
  ) => {
    if (isEmptyValue(value)) {
      return value;
    }

    const multiplier = args['User Role Multiplier'] as number,
      target = !R.isNil(args.Target)
        ? (args.Target as number)
        : (args['Next Qtr Target'] as number),
      booked = !R.isNil(args.Booked)
        ? (args.Booked as number)
        : (args['Next Qtr Booked'] as number),
      expectedPipeline = target - booked,
      currentPipeline = !R.isNil(args['Total Pipe'])
        ? (args['Total Pipe'] as number)
        : (args['Next Qtr Total Pipe'] as number);

    return (
      <>
        <div className={styles.tooltip_line}>
          Residual Target:{' '}
          <span>{currencyValueFormatter(expectedPipeline)}</span>
        </div>
        <div className={styles.tooltip_line}>
          Total Pipe: <span>{currencyValueFormatter(currentPipeline)}</span>
        </div>
        <div className={styles.tooltip_line}>
          Desired Coverage: <span>{multiplierFormatter(multiplier)}</span>
        </div>
      </>
    );
  };

  const getMyOrgRoolUpsTooltip: TooltipConfig['getTooltip'] = (
    _value,
    args
  ) => (
    <MyOrgCustomTooltip
      rollUpsFilters={currentFilters.current}
      managerEmail={args.email as string}
      companyCurrency={companyCurrency}
      includeDisabledUsers={includeDisabledUsers}
      args={args}
    />
  );

  const getPivotsFilters = () => {
    const abortController = new AbortController();
    const url = match.params.tableId
      ? `${apiPointPreviewPivots}${match.params.tableId}`
      : apiPivots;
    fetchApi<string, any>({
      queryMethod: 'get',
      setData: ({ data }) => {
        setPivots(data.pivots);
        setDefaultPivots({ ...data.pivots[0], index: data.pivots[0]?.index });
      },
      setError: (error: string | null) =>
        toast.error(`Fetching pivots data failed: ${error}`, toastOptions),
      signal: abortController.signal,
      url,
    });
  };

  const calculateTable = (
    columns: TableConfigurationColumn[],
    rows: IRow[]
  ) => {
    setTableData({
      ...tableData,
      columns,
      // Expand only the first level of the tree and add the top row identifier.
      rows: setRowExpand(rows, 0, true),
    });
  };

  const processApiResponse = (apiResponse: ApiResponse) => {
    try {
      const { columns, rows } = processTableData(
        apiResponse,
        primaryField,
        getFormulaColumnTooltip,
        getSubmittedForecastTooltip,
        getFormulaHeaderTooltip,
        getPipelineGapTooltip,
        getPipelineCoverageTooltip,
        getMyOrgRoolUpsTooltip,
        (column) => getSubmissionPrefix(column),
        handleClick,
        appliedCloseDateInterval,
        activePivot.index
      );

      const metrics = generateMetricsFromColumns(
        apiResponse.columns,
        rows,
        apiResponse.data
      );

      const metricsPanelItems = mapMetricsToBuMetricsPanelItems(
        metrics,
        companyCurrency
      );

      setRawTable({ columns, rows });
      calculateTable(columns, rows);

      // This is a workaround to use the updated applied filters on handle click
      // as even when there is a new reference for the handleSpecialClick
      // Typed table doesn't update the columns , because it relies on stringify and
      // this doesn't consider a change on a function
      currentFilters.current = appliedFilters;
      setMetricItems(metricsPanelItems);
    } catch (e) {
      setThereIsAnError(true);
      // Adds local Scope data only to track more information about this error.
      Sentry.withScope(function (scope) {
        const tableUrl = match.params.tableId
          ? apiPointPreviewTable(match.params.tableId)
          : apiPointTable;
        scope.setTag('Page', 'Forecast/RollUps');
        scope.setLevel(Sentry.Severity.Critical);
        scope.setContext('URL', { url: tableUrl });
        scope.setContext('Payload', appliedFilters);
        Sentry.captureException(new Error(e as any));
      });
    }
  };

  /**
   * If the the filter action is an intersection we should get the junction of the
   * index(or index mapping which is the actual value that BE needs) and the request filters.
   *
   * @param   index           The value of the row (can be used as a filter value as well).
   * @param   indexMapping    The actual value that BE expects for filtering (this
   *                          is mainly used for forecast categories since the actual value differs from index).
   * @param   requestFilters  The filters for that column.
   * @param   filterName      The filter to look for in the request filters.
   */
  const getFiltersIntersection = (
    index: string,
    indexMapping: string,
    requestFilters: Record<string, ValueProp> = {},
    filterName: string
  ) => {
    const columnFilters = [...((requestFilters[filterName] as string[]) ?? [])];

    if (columnFilters.length) {
      const mappingIntersection = R.intersection([indexMapping], columnFilters);
      const indexIntersection =
        (!mappingIntersection.length &&
          R.intersection([index], columnFilters)) ||
        [];

      // We are doing this because sending and empty array as a filter will result in BE returning every deal.
      return [
        mappingIntersection.length || indexIntersection.length ? index : null,
      ];
    } else {
      return [index];
    }
  };

  const processBothApiResponses = (
    tableResponse: ApiResponse,
    deltaResponse: ApiResponse
  ) => {
    const { columns: baseColumns, data: baseData } = tableResponse;

    /**
     * We expected to merge both requests responses, but it was not correct, for example:
     * When on tableResponse was:
     * meta: {
     *   "expression": "{M2 Booked} - {M3 Booked} + {M1 Booked}",
     *   "type": "formula",
     *   "evaluation_order": 1
     * }
     *
     * But on deltasResponse was:
     * {
     *   "sub_value": {...},
     *   "tooltip": {...},
     *   "expression": "{M2 Booked} - {M3 Booked} + {M1 Booked}",
     *   "type": "formula",
     *   "evaluation_order": 1
     * }
     *
     * The final result was the tableResponse one, ignoring the extra keys on deltasResponse.
     *
     * This new way count on extra keys deeply and merge the objects properly.
     * But we still using the tableResponse as priority when values has conflicts.
     */
    processApiResponse({
      columns: baseColumns.map((baseColumn) => {
        const deltasColumn = deltaResponse.columns.find(
          (deltaColumn) => baseColumn.field_name === deltaColumn.field_name
        );

        if (deltasColumn) {
          return R.mergeDeepLeft(
            baseColumn,
            deltasColumn
          ) as RollupsTableConfigurationColumn;
        }

        return baseColumn as RollupsTableConfigurationColumn;
      }),
      data: baseData.map((item) => {
        const newItem =
          deltaResponse.data.find(
            (newItem) => newItem.user_id?.$oid === item.user_id?.$oid
          ) || {};

        return {
          ...item,
          ...newItem,
        };
      }),
    });
  };

  const handleFetchRollups = (refreshCache: boolean = false) => {
    setHasEverLoaded(true);

    if (rollupsAbortController.current) {
      rollupsAbortController.current?.abort();
    }

    rollupsAbortController.current = new AbortController();
    setRollupsResponse(undefined);

    setLoadingAndProcessingRollups(true);
    setIsDataSetLoading(true);

    const previewTableUrl = match.params.tableId
      ? apiPointPreviewTable(match.params.tableId)
      : apiPointTable;

    const baseUrl = !isOverallTabActive
      ? previewTableUrl
      : apiPointTableOverall;
    const url = refreshCache ? `${baseUrl}?cache_refresh_all=1` : baseUrl;
    const newAppliedFilters = { ...appliedFilters };
    delete newAppliedFilters.change_interval;
    newAppliedFilters.secondary_pivot =
      activePivot.secondary_pivot_name || 'months_breakdown';

    fetchApiPromise<string, ApiResponse>({
      queryParams: JSON.stringify(newAppliedFilters),
      signal: rollupsAbortController.current.signal,
      url,
    })
      .then((r) => {
        setRollupsResponse(r.result);
        const cacheDate = r.headers?.get('cache-created') || '';
        setCacheDate(cacheDate);
      })
      .catch(() => {
        setThereIsAnError(true);
      });
  };

  const handleFetchDeltas = (refreshCache: boolean = false) => {
    setHasEverLoadedDeltas(true);
    if (deltasAbortController.current) {
      deltasAbortController.current?.abort();
    }

    deltasAbortController.current = new AbortController();

    setDeltaResponse(undefined);
    setLoadingAndProcessingDeltas(true);

    const previewDeltaTableUrl = match.params.tableId
      ? apiPointPreviewDeltaTable(match.params.tableId)
      : apiPointDeltas;
    const baseUrl = !isOverallTabActive
      ? previewDeltaTableUrl
      : apiPointDeltasOverall;
    const url = refreshCache ? `${baseUrl}?cache_refresh_all=1` : baseUrl;

    fetchApiPromise<string, ApiResponse>({
      queryParams: serializedQueryParams,
      signal: deltasAbortController.current.signal,
      url,
    }).then((r) => {
      if (r.result && r.result.data) {
        const delta = r.result.data.map((item) => {
          const fix_key = (key: string) => `Prev ${key}`;
          const excluded = [
            'My Org',
            'user_id',
            'manager_id',
            'email',
            'index',
          ];
          return Object.assign(
            {},
            ...Object.keys(item).map((key) =>
              excluded.includes(key)
                ? { [key]: item[key] }
                : { [fix_key(key)]: item[key] }
            )
          );
        });

        setDeltaResponse({ ...r.result, data: delta });
      }
    });
  };

  useEffect(() => {
    /**
     * The reason of this code is to improve performance
     *
     * We must avoid at all cost calling deltas or rollups endpoint if its not necessary
     * as are costly and long endpoints, also there is some process that we need to do on the frontend
     * that's not cheap
     * Also we can display information even when there is no deltas, we can add deltas later
     * When the endpoint call is finished and porcessed . For this we need to consider the following cases.
     * This is kind of complex as there are some ocations that we need to call both endpoints but others when we don't.
     * Changes Since filteronly affects deltas, so its not neccesary to call the rollups endpoint. When pivoting for other thing other than manager,
     * deltas are not necessary. So we have to consider the following cases
     *
     * We call both endpoints
     *  - Deltas endpoint finishes first
     *    So we have to wait for rollups and then process both responses thogether
     *
     *  - Rollups endpoint finishes first
     *    We can process and display the information while we wait for deltas
     *    Once deltas arrives we can process again the information thogether
     *
     * We only call deltas
     *  We use the already saved rollups information and merge it with the new deltas information
     *  While waiting for deltas we still show the previous information
     *
     * We only call rollups
     *  When pivoting we need to call rollups endpoint and display without a deltas call done
     *
     *
     * */
    if (rollupsResponse && loadingAndProcessingDeltas && deltaResponse) {
      processBothApiResponses(rollupsResponse, deltaResponse);
      setLoadingAndProcessingDeltas(false);
      setLoadingAndProcessingRollups(false);
    } else if (loadingAndProcessingRollups && rollupsResponse) {
      processApiResponse(rollupsResponse);
      setLoadingAndProcessingRollups(false);
    }
  }, [
    rollupsResponse,
    deltaResponse,
    loadingAndProcessingDeltas,
    loadingAndProcessingRollups,
  ]);

  useEffect(() => {
    if (!tableData.rows.length && rawTable.rows.length) {
      calculateTable(rawTable.columns, rawTable.rows);
    }
  }, [tableData]);

  const rollupsAbortController = useRef<AbortController>();

  useEffect(() => {
    const dependenciesLoaded =
      !R.isEmpty(filters) && companySettingsLoaded && forecastSubmissionLoaded;

    // Fetch the Table and Deltas if this is the first load or the change_interval has not changed or does not exist or because of refresh cache
    const filtersThatAffectRollupsChanged = !R.equals(
      { ...appliedFilters, change_interval: undefined },
      { ...prevFiltersRef.current, change_interval: undefined }
    );

    const shouldCallEndpoint =
      !hasEverLoaded || filtersThatAffectRollupsChanged || refreshCache;
    if (dependenciesLoaded && shouldCallEndpoint) {
      handleFetchRollups(refreshCache);

      if (refreshCache) {
        dispatch(onRefreshForecast());
        setRefreshCache(false);
      }
    }
  }, [
    serializedQueryParams,
    companySettingsLoaded,
    refreshCache,
    includeDisabledUsers,
    forecastSubmissionLoaded,
  ]);
  const deltasAbortController = useRef<AbortController>();

  useEffect(() => {
    const dependenciesLoaded =
      !R.isEmpty(filters) && companySettingsLoaded && forecastSubmissionLoaded;
    const filtersThatAffectDeltaChanged = !R.equals(
      appliedFilters,
      prevFiltersRef.current
    );

    const isPivoting = activePivot.index !== 'user._id';

    const shouldCallEndpoint =
      isForecastRollUpsDeltasEnabled &&
      !isPivoting &&
      (!hasEverLoadedDeltas || refreshCache || filtersThatAffectDeltaChanged);

    if (dependenciesLoaded && shouldCallEndpoint) {
      handleFetchDeltas(refreshCache);
    }
  }, [
    activePivot.index,
    serializedQueryParams,
    isForecastRollUpsDeltasEnabled,
    refreshCache,
    forecastSubmissionLoaded,
  ]);

  useEffect(() => {
    if (isSubmitForecast && !R.isEmpty(filters)) {
      setRefreshCache(true);
      dispatch(clearSubmitForecastFlag());
    }
  }, [serializedQueryParams]);

  useEffect(() => {
    prevFiltersRef.current = appliedFilters;
  }, [serializedQueryParams]);

  // Get the active submissions with the column configuration.
  const submissionWithColumn = useMemo(() => {
    if (tableData.columns.length) {
      return getSubmissionWithColumn(
        tableData.columns,
        submissionSettings,
        selectedBusinessType
      );
    } else {
      return [];
    }
  }, [tableData.columns, submissionSettings, appliedCloseDateInterval]);

  // Update the Forecast each time it is submitted.
  useEffect(() => {
    const currentUserRow = findCurrentUserRow(
      tableData.rows,
      primaryField,
      user.name
    );

    if (
      submissionWithColumn.length &&
      currentUserRow &&
      appliedCloseDateInterval === 'TQU'
    ) {
      submissionWithColumn.forEach((item) => {
        const [firstHistory] = item.history;
        if (firstHistory && !firstHistory.month) {
          currentUserRow[item.object_field] = firstHistory.amount;
        }
      });

      setTableData({
        ...tableData,
        rows: tableData.rows.map((item) =>
          item[primaryField] === user.name ? currentUserRow : item
        ),
      });
    }
  }, [JSON.stringify(submissionWithColumn.map((item) => item.history.length))]);

  useEffect(() => {
    setIsVisiblePanel(isSubmitForecastPanelOpen);
  }, [isSubmitForecastPanelOpen]);

  useEffect(() => {
    setShowSubmitForecastButton(
      !isReadOnly && isSubmissionAvailable && !isVisiblePanel
    );
  }, [isReadOnly, isSubmissionAvailable, isVisiblePanel]);

  useEffect(() => {
    if (match.params?.tableId) {
      setRenderExtraTabs(
        <TabTitle
          isActive={true}
          scheme={`/forecast/roll-ups/preview/:tableId`}
          params={{ tableId: match.params.tableId }}
          title="Roll Ups Preview Mode"
          titleLength={120}
          icon={TickAlertCircle}
          tooltipMessage={
            <div style={{ minWidth: '270px' }}>
              Preview your changes in Roll Ups columns using this tab. If you
              navigate to other pages, this preview mode will be closed.
            </div>
          }
        />
      );
    }
  }, [match.params?.tableId]);

  const someDataIsLoadingAndProcessing =
    loadingAndProcessingRollups ||
    loadingAndProcessingDeltas ||
    isDataSetLoading;

  const onRecalculate = () => {
    handleFetchRollups(!!isCacheEnabled);
    const shouldCallDeltas =
      isForecastRollUpsDeltasEnabled && activePivot.index == 'user._id';
    if (shouldCallDeltas) {
      handleFetchDeltas(!!isCacheEnabled);
    }
  };
  const { openDealStatusModal } = useRecalculateDealsStatusModal({
    isSomeDataLoading: loadingAndProcessingRollups,
    rollupsRows: dataset,
    rollupsColumns: tableData.columns,
    onRecalculate,
  });

  const [rollupsPageSize] = usePageSize('ForecastRollUps', 10);

  const handleOpenModalOnClickRow = ({
    column,
    columnConfig,
    row,
    extraData,
  }: OpenModalProps) => {
    const { dealIds, prevDealsIds } = extractIdsFromRow(row, column.field);

    const filters = R.mergeDeepRight(
      currentFilters.current || {},
      columnConfig.request_filters || {}
    );

    const params = {
      time_span: currentFilters.current?.change_interval,
      page_size: rollupsPageSize,
      managers: [row.email as string],
      include_disabled: includeDisabledUsers,
      context: `${
        activePivot.index === 'user._id' ? row[primaryField] : row.index
      }: ${column.label}`,
      delta: (extraData.delta as number | undefined) || 0,
      prevDealsIds: prevDealsIds,
      dealIds,
      only_with_changes: true,
      amount_field: columnConfig.amount_field,
      ...filters,
      filtersForModalFilterPanel: currentFilters.current,
      table_id: deltaResponse?.table_id,
      column_name: column.field,
      rowId: row.id as string,
    };

    if (column.field === 'My Org') {
      const modalParams = {
        user: row['My Org'] || '',
        email: row.email,
        filters: {
          time_span: filters?.['activity_period'] ?? '',
          sort_by: 'start',
          ...filters,
          users: [row.email],
        },
      };

      openModal({
        scheme: '/activity/personal/:email',
        params: { email: row.email as string },
        persistParams: modalParams,
        persistor: persistModalParams,
      });

      return;
    }

    /**
     * Get the right filters when opening deals modal on a pivoted table.
     * Each pivot has its own filter type which is given by the field being a custom field or not.
     */
    if (activePivot.index !== 'user._id') {
      const pivot = pivots?.find(
        (item) => item.index === activePivot.index
      ) as Pivots;
      const {
        filter: { type, name, action },
      } = pivot;
      const index = row.index! as string;
      const requestFilters =
        type === 'default'
          ? columnConfig.request_filters
          : columnConfig.request_filters?.custom_filters!;

      const newParams = {
        [name]:
          action === 'intersect'
            ? getFiltersIntersection(
                index,
                row.index_mapping as string,
                requestFilters,
                name
              )
            : [row.index_mapping || index],
      };

      const groupByParams = {
        ...params,
        // Custom fields require filters to be sent in a 'custom_filters' object.
        ...(type === 'custom' ? { custom_filters: newParams } : newParams),
        managers: [],
      };
      openDealStatusModal({
        params: {
          tab: 'forecast',
        },
        props: {
          ...groupByParams,
          subtab: extraData.showDelta ? 'changed deals' : 'current deals',
          localStorageKeyPrefix: 'RollUps',
          rowId: row.id as string,
        },
      });

      return;
    }

    openDealStatusModal({
      params: {
        tab: 'forecast',
      },
      props: {
        ...params,
        subtab: extraData.showDelta ? 'changed deals' : 'current deals',
        localStorageKeyPrefix: 'RollUps',
      },
    });
  };

  const handleClick = async ({
    column,
    columnConfig,
    row,
    extraData = {},
  }: CallbackProps) => {
    AnalyticsTracker.event(
      {
        filters,
        column: column.label,
        rowId: row.id,
      },
      {
        category: 'Forecast view',
        action: 'Tab: RollUps',
        label: 'clicked on Table Cell',
      }
    );

    if (column.field === 'Months') {
      handleMonthsColumnCustomCell(row);
      return;
    }

    if (row.isMonthRow) {
      return;
    }

    handleOpenModalOnClickRow({
      column,
      columnConfig,
      row,
      extraData,
    });
  };

  const getCsvTitle = (selectedBusinessType: string) => {
    const now = moment().format('YYYY_MM_DD_hh_mm_A');

    return selectedBusinessType
      ? `forecast_rollups_${selectedBusinessType}_${now}`
      : `forecast_rollups_${now}`;
  };

  const buildCSVData = () => {
    const title = getCsvTitle(selectedBusinessType);
    const { header, headerType } = getCSVHeaders(
      tableData.columns,
      primaryField
    );
    const cvsData = getCSVData(
      companyCurrency,
      tableData.rows,
      primaryField,
      headerType
    );

    return {
      title,
      header,
      cvsData,
    };
  };

  const handleCloseModal = () => {
    setIsVisiblePanel(false);
    setIsSubmitForecastPanelOpen(false);

    if (isSubmitForecast) {
      setRefreshCache(true);
      dispatch(clearSubmitForecastFlag());
    }
  };

  const getGroupByOptions = useMemo(() => {
    const options = pivots?.map((item) => ({
      display_name: item.display_name,
      index: item.index,
      secondary_pivot: item.secondary_pivots,
    }));
    return options;
  }, [pivots]);

  if (R.isEmpty(filters)) {
    return <Loader active />;
  }

  return (
    <div
      className={classNames('wrapper', styles.wrapper, styles.rollupsWrapper)}
    >
      <TwoColumnsDashboard
        isVisiblePanel={isVisiblePanel}
        panel={
          <SubmitForecast
            onRecalculate={onRecalculate}
            onClose={handleCloseModal}
            formatter={currencyValueFormatter}
          />
        }
      >
        <div className={styles.header}>
          <OpenFiltersPanel tab="forecast_roll_ups" />
          <div className={styles.btActiveUsers}>
            <BusinessTypesPanel
              tab="forecast_roll_ups"
              isOverallEnabled={isOverallEnabled}
            />
            <div className={styles.activeUsersMetricsWrapper}>
              <ActiveUsersToggle
                checked={!includeDisabledUsers}
                onChange={setIncludeDisabledUsers}
              />
              {!!metricsItems?.length && (
                <>
                  <div className={styles.activeUsersMetricsDivider} />
                  <BuToggle
                    checked={showingMetrics}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setShowingMetrics(e.target.checked)
                    }
                    testingLabel="roll-ups-metrics-toggle"
                    leftLabel
                  >
                    Metrics
                  </BuToggle>
                </>
              )}
            </div>
          </div>
          {loadingAndProcessingRollups ? (
            <Dimmer.Dimmable
              style={{
                height: '50px',
                marginBottom: '15px',
                marginTop: '15px',
              }}
              dimmed
            >
              <Dimmer inverted active>
                <Loader />
              </Dimmer>
            </Dimmer.Dimmable>
          ) : (
            metricsItems &&
            showingMetrics && (
              <BuMetricsPanel
                items={metricsItems}
                panelExpanded={showingMetrics}
              />
            )
          )}
        </div>

        <div
          className={classNames({
            [styles.hidden]: thereIsAnError,
            [styles.extraMargin]: metricsItems?.length === 0,
          })}
          ref={listRef}
        >
          <div className={styles.toggleContainer}>
            <BuDropdown
              inlineLabel="Group by"
              disabled={loadingAndProcessingRollups}
              secondary
              size={BuControlSize.REGULAR}
              label={activePivot.display_name}
            >
              {(hide) => (
                <BuDropdownItemContainer style={{ maxHeight: 300 }}>
                  {getGroupByOptions?.map((item) => (
                    <BuDropdownItem
                      key={item.index}
                      {...getDropdownItemProps(
                        item,
                        item === activePivot,
                        hide,
                        (item) => {
                          setActivePivot({
                            display_name: item.display_name,
                            index: item.index,
                          });
                        }
                      )}
                    >
                      {item.display_name}
                    </BuDropdownItem>
                  ))}
                </BuDropdownItemContainer>
              )}
            </BuDropdown>
            <div
              className={classNames(
                styles.buttons_container,
                styles.refreshButtonWrapper
              )}
            >
              {cacheSettings?.rollups_cache_enabled && (
                <BuButtonRefresh
                  onClick={() => setRefreshCache(true)}
                  cacheDate={cacheDate}
                  status={someDataIsLoadingAndProcessing}
                  message="Update Data"
                  showLastUpdatedMessage={!smallActionRow}
                />
              )}
            </div>
            <div
              className={classNames(
                styles.buttons_container,
                styles.dropdown_container
              )}
            >
              {loadingAndProcessingDeltas && (
                <DeltasTag>Fetching deltas ... </DeltasTag>
              )}
            </div>
            <div className={styles.buttons_container}>
              {tableData.rows.length > 0 && (
                <div className={styles.exportBtnWrapper}>
                  <ExportCSVFromJSON
                    onClick={buildCSVData}
                    buttonClassName={classNames(
                      styles.exportBtn,
                      buttonStyle,
                      'bu-small',
                      'bu-button-icon'
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.rollupsContent}>
          <Table
            tableId={TABLE_ID.ROLLUPS}
            pinnableColumns
            currentPage={0}
            customTableColumnConfiguration={{
              order: {},
              columns: tableData.columns,
            }}
            data={dataset}
            fullscreen
            hidePaginationEnd
            hidePaginationStart
            hideSearch
            loading={loadingAndProcessingRollups}
            onChange={handleChange}
            onSpecialClick={handleClick}
            rowsPerPage={0}
            styleFirstColumn
            title=""
            rowClassName={(row) =>
              classNames({
                topRow: row.isTopRow,
                empty: row.isEmpty,
                inactive: row.status === 'disabled',
              })
            }
            stickyColumnRollUps
          />
        </div>
        <div
          className={classNames(styles.fullSize, {
            [styles.hidden]: !thereIsAnError,
          })}
        >
          <ErrorMessage onRefresh={() => setRefreshCache(true)} />
        </div>
      </TwoColumnsDashboard>
    </div>
  );
};

export default ForecastRollUps;
