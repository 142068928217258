import styled from '@emotion/styled';
import { css } from 'emotion';

export const MainContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'var(--bu-primary-100)',
  position: 'relative',

  '&.no-scroll': {
    overflow: 'hidden',
  },
});

export const DashboardHeader = styled.div({
  position: 'sticky',
  top: '0px',
  zIndex: 2,
  backgroundColor: 'var(--bu-white)',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderBottom: 'var(--bu-gray-400) 1px solid',
});

export const DashboardHeaderRow = styled.div({
  display: 'flex',
  gap: '10px',
  padding: '15px 24px',
  borderBottom: 'var(--bu-gray-400) 1px solid',
});

export const DashboardHeaderRowTitle = styled.div({
  flexGrow: 1,
  cursor: 'pointer',
});

export const DashboardHeaderRowControls = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '16px',
  alignItems: 'center',
});

export const DashboardFiltersRow = styled.div({
  display: 'flex',
  padding: '10px 24px',
});

export const DashboardFiltersContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5px',
});

export const AddFilterButtonContainer = styled.div({});

export const ApplyContainer = styled.div({
  display: 'flex',
  flexGrow: 1,
});

export const AddFilterButton = styled.div({
  color: 'var(--bu-primary-500)',
});

export const FiltersWidgetMenu = css`
  display: flex;
  flex-direction: column;
  max-height: 380px;
  overflow-y: auto;
  padding-bottom: 3px;
`;

export const FilterSearch = styled.div({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px',
  backgroundColor: 'var(--bu-gray-200)',
  padding: '6px',
  borderRadius: '4px',
});

export const FilterSearchInput = styled.input({
  border: 'none',
  outline: 'none',
  backgroundColor: 'var(--bu-gray-200)',
  width: '100%',
});

export const FilterWrapper = styled.div({
  display: 'flex',
});

export const RemoveFilterButton = css`
  margin-left: -5px !important;
  background: var(--bu-gray-200) !important;
  z-index: 100;
  border-radius: 0 4px 4px 0 !important;
`;

export const DashboardWidgetsContainer = styled.div(
  (props: { shouldRenderFullscreen: boolean }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: props.shouldRenderFullscreen ? 0 : '16px 24px',
    gap: '24px',
    height: '100%',
    zIndex: 1,
    overflowY: props.shouldRenderFullscreen ? 'hidden' : 'auto',
  })
);

export const LoaderContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const dashboardSettingContainer = css`
  padding: 8px;
`;

interface WidgetModalComponentProps {
  readonly scrollDistance?: number;
  readonly heightView?: number;
}

export const WidgetModalComponent = styled.div(
  ({ scrollDistance = 0, heightView = 0 }: WidgetModalComponentProps) => ({
    position: 'absolute',
    zIndex: 100,
    width: '100%',
    height: '0',
    top: heightView + scrollDistance,
    backgroundColor: 'white',
    transitionProperty: 'top',
    transitionDuration: '1s',
    visibility: 'hidden',

    '&.opened': {
      top: scrollDistance,
      visibility: 'visible',
      height: 'calc(100%)',
      overflowY: 'auto',
    },

    '&.closing': {
      top: heightView + scrollDistance,
      visibility: 'visible',
      height: 'calc(100%)',
    },
  })
);

export const TitleNotEditing = styled.div({
  display: 'flex',
  alignItems: 'center',
  p: {
    margin: '0px 8px 0px 0px',
    fontWeight: 315,
    fontSize: '18px',
    lineHeight: '21.6px',
  },
  '.icon-button': {
    visibility: 'hidden',
    cursor: 'pointer',
    i: {
      fontSize: '20px',
    },
  },
  '&:hover': {
    '>.icon-button': {
      visibility: 'unset',
    },
  },
});
