import { css } from 'emotion';

import { fontHeading3 } from 'assets/css/fontStyles';

import { cardStyle, ellipsis, showMore, tabContentWrapper } from '../../styles';
import { SENTIMENT_STATUS_COLOR } from '../../helpers';

export { tabContentWrapper };

export const insightTabWrapper = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-large);

  .insight-card {
    ${cardStyle}
    display: flex;
    flex-direction: column;
    gap: var(--bu-control-gap-medium);

    .insight-card-title {
      ${fontHeading3}
      text-transform: capitalize;
    }

    .insight-card-content {
      display: flex;
      gap: var(--bu-control-gap-medium);
      flex-wrap: wrap;

      div {
        text-transform: capitalize;
        padding: 4px 10px;
        border-radius: var(--bu-control-border-radius-large);

        &.no-capitalize {
          text-transform: none;
        }

        &:not(.no-ellipsis) {
          ${ellipsis}
          max-width: 200px;

          @media (max-width: 1140px) {
            max-width: 100px;
          }

          @media (min-width: 1140px) and (max-width: 1280px) {
            max-width: 120px;
          }

          @media (min-width: 1280px) and (max-width: 1480px) {
            max-width: 140px;
          }

          @media (min-width: 1480px) and (max-width: 1720px) {
            max-width: 160px;
          }

          @media (min-width: 1720px) and (max-width: 1980px) {
            max-width: 180px;
          }
        }
      }

      &.positive,
      &.negative {
        div {
          cursor: pointer;

          &:hover {
            background-color: var(--bu-gray-300);
          }

          &.disabled {
            cursor: default;
          }
        }
      }

      &.positive {
        div {
          border: 1px solid var(--bu-color-call-positive-insight-border);
        }
      }

      &.negative {
        div {
          border: 1px solid var(--bu-color-call-negative-insight-border);
        }
      }

      &.topics,
      &.competitors {
        div {
          cursor: pointer;
          background-color: var(--bu-color-call-topics-insight-background);

          &:hover,
          &.active {
            background-color: var(--bu-gray-400);
          }
        }
      }
    }

    .show-more {
      ${showMore}
    }
  }
`;

export const topicCompetitorsTooltipWrapper = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-large);
  width: max-content;

  .title {
    ${fontHeading3}

    span {
      text-transform: capitalize;
    }
  }

  .keywords,
  .sentiment {
    display: flex;
    flex-direction: column;

    span {
      text-transform: capitalize;

      &.name {
        font-family: var(--bu-font-medium);
      }
    }
  }

  .sentiment {
    span + span {
      text-transform: capitalize;

      &.negative {
        color: ${SENTIMENT_STATUS_COLOR.negative};
      }

      &.neutral {
        color: ${SENTIMENT_STATUS_COLOR.neutral};
      }

      &.positive {
        color: ${SENTIMENT_STATUS_COLOR.positive};
      }
    }
  }
`;

export const mentionTooltipWrapper = css`
  max-height: 400px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-large);
  max-width: 300px;

  .mention,
  .topic {
    display: flex;
    flex-direction: column;

    span {
      &.name {
        font-family: var(--bu-font-medium);
      }
    }
  }

  .topic {
    span {
      text-transform: capitalize;
    }
  }
`;
