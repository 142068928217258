import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { clearBusinessTypesConfig } from 'actions/settingsActions';
import { TABLE_ID } from 'common/constants';
import Table from 'components/UI/TableConfig/Table';
import {
  IColumn,
  IRow,
  onChangeCallback,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CheckboxConfig } from 'components/UI/common/TypedTable/renderers/CheckboxCell';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import UpDownButtons from 'components/settings/UserProfiles/BusinessTypes/UpDownButtons';
import {
  DOWN,
  UP,
} from 'components/settings/UserProfiles/BusinessTypes/constants';
import { reorderBusinessTypes } from 'components/settings/UserProfiles/BusinessTypes/helpers';
import {
  container,
  minimal_table_margins,
} from 'components/settings/UserProfiles/BusinessTypes/styles';
import {
  BusinessTypesResponse,
  DataItem,
  Props,
} from 'components/settings/UserProfiles/BusinessTypes/types';
import { apiUserProfile } from 'components/settings/UserProfiles/UserProfiles';
import { fetchApi, QueryStatus } from 'utils/network';

const BusinessTypes: React.FC<Props> = ({ profileId }) => {
  const dispatch = useDispatch();
  const [businessTypes, setBusinessTypes] = useState<IRow[]>([]);
  const [status, setStatus] = useState<QueryStatus>('notAsked');
  const [updateStatus, setUpdateStatus] = useState<QueryStatus>('success');
  const firstPositionRef = useRef<number | null>(null);
  const lastPositionRef = useRef<number | null>(null);

  const isDataLoading = status === 'loading';

  const url = `${apiUserProfile}/${profileId}/business_types`;
  const toastOptions: ToastOptions = { position: 'bottom-left' };

  const moveItemHandler = (positionId: string, direction: string): void => {
    const position = Number(positionId);

    if (
      (position > firstPositionRef.current! && direction === UP) ||
      (position < lastPositionRef.current! && direction === DOWN)
    ) {
      setBusinessTypes(
        reorderBusinessTypes(businessTypes, position, direction)
      );
      setUpdateStatus('notAsked');
    }
  };

  const columns: IColumn[] = [
    {
      id: 'order',
      label: 'Order',
      field: 'order',
      sortable: false,
      minWidth: 80,
      width: 80,
      sort_order: SortOrder.DESCENDING,
      type: ColumnTypes.CUSTOM,
      align: 'center',
      config: {
        renderer({ row }: { row: IRow }) {
          const { order } = row;
          return (
            <UpDownButtons
              position={order as string}
              isFirst={order === firstPositionRef.current}
              isLast={order === lastPositionRef.current}
              onMoveItem={moveItemHandler}
            />
          );
        },
      } as CustomCellConfig,
    },
    {
      id: 'business_type',
      label: 'Business Type',
      field: 'name',
      sortable: false,
      sort_order: SortOrder.DESCENDING,
      type: ColumnTypes.TEXT,
      config: {},
    },
    {
      id: 'enable',
      label: 'Enable',
      field: 'enabled',
      align: 'center',
      editable: true,
      sortable: false,
      minWidth: 160,
      width: 160,
      sort_order: SortOrder.DESCENDING,
      type: ColumnTypes.CHECKBOX,
      config: {
        ts: Date.now(),
        style: 'toggle',
      } as CheckboxConfig,
    },
  ];

  const handleChange: onChangeCallback = (_, row, newValue): void => {
    const editableElements = businessTypes.filter((e) => e.enabled === true);
    const isEditable = Boolean(newValue);

    if (editableElements.length > 1 || isEditable) {
      setBusinessTypes(
        businessTypes.map((el) =>
          el.order === row.order ? { ...el, enabled: isEditable } : el
        )
      );
      setUpdateStatus('notAsked');
    } else {
      toast.error(
        'You cannot disable the business type. At least one business type should be enabled.',
        toastOptions
      );
    }
  };

  const getOrderedData = (data: DataItem[]): DataItem[] => {
    const dataOrdered = data.sort((a, b) => a.order! - b.order!);
    const allOrderPositions = dataOrdered.map((item: any) => item.order);

    firstPositionRef.current = Math.min(...allOrderPositions);
    lastPositionRef.current = Math.max(...allOrderPositions);

    return dataOrdered;
  };

  useEffect(() => {
    if (status === 'notAsked') {
      const setData = ({ data }: BusinessTypesResponse) => {
        setBusinessTypes(getOrderedData(data));
      };
      const setError = (error: string | null) => {
        toast.error(`Fetching profile data failed: ${error}`, toastOptions);
      };

      fetchApi<undefined, BusinessTypesResponse>({
        queryMethod: 'get',
        setData,
        setError,
        setStatus,
        url,
      });
    }
  }, [status]);

  useEffect(() => {
    if (!isDataLoading && updateStatus === 'notAsked') {
      const setError = (error: string | null) => {
        toast.error(`Updating failed: ${error}`, toastOptions);
      };

      fetchApi<string, BusinessTypesResponse>({
        queryParams: JSON.stringify({
          items: businessTypes,
        }),
        queryMethod: 'put',
        setError,
        setStatus: (status) => {
          setUpdateStatus(status);
          dispatch(clearBusinessTypesConfig());
        },
        url,
      });
    }
  }, [isDataLoading, updateStatus, dispatch]);

  return isDataLoading ? (
    <Loader active inline="centered" />
  ) : (
    <div className={container}>
      <Table
        tableId={TABLE_ID.BUSINESS_TYPES}
        columns={columns}
        data={businessTypes}
        rowsPerPage={50}
        currentPage={1}
        hidePaginationEnd
        hidePaginationStart
        hideSearch
        onChange={handleChange}
        width="100%"
        className={minimal_table_margins}
      />
    </div>
  );
};

export default BusinessTypes;
