import { useState, ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTablesPageSize } from 'selectors';

const DEFAULT_PAGE_SIZE = 50;

export const usePageSize = (
  tableName: string,
  defaultSize?: number
): [value: number, setValue: React.Dispatch<React.SetStateAction<number>>] => {
  const [value, setValue] = useState(defaultSize ?? DEFAULT_PAGE_SIZE);

  const tablesPageSize = useSelector(getTablesPageSize) as {
    [key: string]: number;
  };
  const dealsPageSize = tablesPageSize[tableName];

  useEffect(() => {
    if (dealsPageSize !== undefined && dealsPageSize !== null) {
      setValue(dealsPageSize);
    }
  }, [dealsPageSize]);

  return [value, setValue];
};
