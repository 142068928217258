import React from 'react';

import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import { useModal } from 'components/modals/ModalContext/modal.context';

import * as styles from './styles';
import { CallInsightsResponse, FollowUpEmailModal } from '../../types';

type Props = {
  followUpEmailData: CallInsightsResponse['follow_up_email_data'];
};

const ActionItemsTab: React.FC<Props> = ({ followUpEmailData }) => {
  const { openModal } = useModal<FollowUpEmailModal>();

  const handleSendFollowUpClick = () => {
    openModal({
      scheme: '/follow-up-email',
      props: followUpEmailData,
    });
  };

  return (
    <div className={styles.tabContentWrapper}>
      <div className={styles.actionItemsTabWrapper}>
        {followUpEmailData?.body ? (
          <div className="action-items-card">
            <div className="content">
              <div className="content-description">
                {/* TODO: Will have this in the future */}
                {/* <div className="done-wrapper">
                  <div className="done-icon">
                    <BuIcon name={BoostUpIcons.Checked} />
                  </div>
                </div> */}

                <BuIcon name={BoostUpIcons.Mail} />

                <span>Generate follow up email for this meeting</span>
              </div>

              <div className="content-actions">
                <BuButton onClick={handleSendFollowUpClick} secondary>
                  Send Follow Up
                </BuButton>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.notAvilableContent}>
            No action items available
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionItemsTab;
