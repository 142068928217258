import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { TABLE_ID } from 'common/constants';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import Table from 'components/UI/TableConfig/Table';
import { columnTypes } from 'components/UI/TableConfig/column-helper';
import { TableConfigurationData } from 'components/UI/TableConfig/types';
import {
  IColumn,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  CsvColumns,
  DrilldownDataResponse,
  FunnelDrilldownParams,
} from 'components/modals/types';
import { ModalPersistParams } from 'navigation/types';
import { IReduxState } from 'reducers/types';
import { getModalProps, isMulticurrencyEnabled } from 'selectors';
import { fetchApi, QueryStatus } from 'utils/network';
import { usePageSize } from 'components/hooks/usePageSize';

export const FunnelDrilldownModal: React.FC = () => {
  const { currency: companyCurrencyCode } = useContext(RevBISettingsContext);
  const [pageSize] = usePageSize('RevbiOpportunityFunnel', 15);

  const isMultiCurrencyEnabled = useSelector(isMulticurrencyEnabled);
  const params: ModalPersistParams = useSelector((state: IReduxState) =>
    getModalProps(state, '/funnel-drilldown')
  );

  const [tableColumns, setTableColumns] = useState<IColumn[]>([]);
  const [csvColumns, setCsvColumns] = useState<CsvColumns[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);
  const [count, setCount] = useState<number>(0);
  const [offsetIndex, setOffsetIndex] = useState<number>(0);
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [orderByExpression, setOrderByExpression] = useState<
    string | undefined
  >('id');
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');

  const handlePageChange = (pageNumber: number, _: number) => {
    setOffsetIndex(pageNumber - 1);
    setPaginationIndex(pageNumber);
  };

  const getColumnType = columnTypes(
    {
      currencyFormatter: currencyFormatter(
        companyCurrencyCode,
        0,
        isMultiCurrencyEnabled
      ),
      sequence: [],
    },
    () => {},
    '',
    isMultiCurrencyEnabled
  );

  useEffect(() => {
    const abortController = new AbortController();

    fetchApi<void, { data: TableConfigurationData }>({
      url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/tables/opportunity/RevbiOpportunityFunnel`,
      queryMethod: 'get',
      setData: (result) => {
        const data = result?.data ?? {};
        const { columns, order } = data;

        setOrderByExpression(order.object_field);

        setCsvColumns(
          columns.map((c) => ({
            display_name: c.display_name,
            object_field:
              c.object_field === 'crm_metadata.opportunity_name'
                ? 'opportunity_name'
                : c.object_field,
          }))
        );

        setTableColumns(
          columns
            ?.map<IColumn>((column, index) => ({
              field: column.object_field,
              sort_order: SortOrder.DESCENDING,
              sortable: column.sortable,
              id: `${column.field_name}-${index}`,
              label: column.display_name,
              ...getColumnType(column, columns),
              editable: column.editable,
            }))
            .map((column) => ({
              ...column,
              showTooltip: ![
                ColumnTypes.NUMBER,
                ColumnTypes.PERCENT,
                ColumnTypes.MONEY,
              ].includes(column.type),
            })) || []
        );
      },
      setError: () => {
        toast.error('Failed to load drilldown columns');
      },
      signal: abortController.signal,
    });

    return () => {
      abortController.abort();
      setOrderByExpression('id');
      setCsvColumns([]);
      setTableColumns([]);
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    if (params?.drilldownParams?.funnel_object && tableColumns.length) {
      const columns = tableColumns.map(({ field }) => field);
      const queryParams: FunnelDrilldownParams = {
        ...params.drilldownParams,
        columns: columns,
        limit: pageSize,
        offset: offsetIndex,
        order_by_expression: orderByExpression || '',
      };

      fetchApi<FunnelDrilldownParams, DrilldownDataResponse>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${params?.drilldownParams?.funnel_object}/data`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          const data = result ?? [];
          setCount((prev) => data?.count ?? prev);

          const parsedDeals: IRow[] = data?.deals?.map((deal: Deals.Deal) => ({
            ...deal,
            id: `${deal._id}`,
            crm_metadata:
              deal.crm_metadata && deal.crm_metadata.next_step === null
                ? { ...deal.crm_metadata, next_step: '' }
                : deal.crm_metadata,
          }));

          setRows(parsedDeals);
        },
        setStatus: setDataStatus,
        setError: () => {
          toast.error('Failed to load drilldown data');
          setRows([]);
        },
        signal: abortController.signal,
      });
    }

    return () => {
      abortController.abort();
      setRows([]);
    };
  }, [
    params.drilldownParams,
    tableColumns.length,
    offsetIndex,
    orderByExpression,
  ]);

  const downloadButton: DownloadButtonProps = useMemo(
    () => ({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${params?.drilldownParams?.funnel_object}/download`,
      queryMethod: 'post',
      serializedQueryParams: JSON.stringify({
        drill_down: {
          ...params?.drilldownParams,
          limit: 100000,
          offset: 0,
          order_by_expression: orderByExpression || '',
        },
        columns: csvColumns,
      }),
    }),
    [params, csvColumns, orderByExpression]
  );

  return (
    <div style={{ minHeight: '150px' }}>
      <Table
        tableId={TABLE_ID.MODAL_FUNNEL}
        pinnableColumns
        hidePaginationEnd
        hideSearch
        fullscreen
        isModal
        loading={dataStatus !== 'success' && dataStatus !== 'error'}
        title={params.title}
        totalCount={count}
        currentPage={paginationIndex}
        rowsPerPage={pageSize}
        downloadButton={downloadButton}
        data={rows}
        columns={tableColumns}
        sortOrder={orderByExpression}
        showTotalAmount={params.showTotalAmount}
        onSort={setOrderByExpression}
        onPaginationChange={handlePageChange}
      />
    </div>
  );
};
