import React from 'react';

import * as styles from './styles';
import SummaryInsightHeader from '../SummaryInsightHeader';
import { CallInsightsResponse } from '../../types';

type Props = {
  accountName?: string;
  accountId?: string;
  opportunityName?: string;
  accountExecutive?: string;
  opportunityId?: string;
  summary: CallInsightsResponse['call_summary_data'];
  hideOpportunityAndAE?: boolean;
};

const SummaryTab: React.FC<Props> = ({
  accountName,
  accountId,
  opportunityName,
  accountExecutive,
  opportunityId,
  summary,
  hideOpportunityAndAE = false,
}) => (
  <div className={styles.tabContentWrapper}>
    <div className={styles.summaryTabWrapper}>
      {!hideOpportunityAndAE && (
        <SummaryInsightHeader
          accountName={accountName}
          accountId={accountId}
          opportunityName={opportunityName}
          accountExecutive={accountExecutive}
          opportunityId={opportunityId}
        />
      )}

      {/* TODO: Will have this in the future */}
      {/* {!!sentiment && (
        <div className={classNames('sentiment-section', sentiment.status)}>
          <div className="sentiment-label">SENTIMENT:</div>
          <div className="sentiment-circle">
            <div className="sentiment-circle-base">
              <SentimentCircle
                percentage={sentiment.percentage}
                color={
                  SENTIMENT_STATUS_COLOR[
                    sentiment.status as TopicsCompetitors['sentiment']
                  ]
                }
              />
            </div>
            <span>{sentiment.status}</span>
          </div>
        </div>
      )} */}

      {summary ? (
        <div className="summary-card">
          {/* TODO: Will have this in the future */}
          {/* {!!sentiment && (
            <div className="summary-card-section">
              <div className="summary-card-title">Sentiment</div>
              <div className="summary-card-description">
                {sentiment.description}
              </div>
            </div>
          )} */}

          {!!summary && !!summary.summary && (
            <div className="summary-card-section">
              <div className="summary-card-title">Call Summary</div>
              <div className="summary-card-description">{summary.summary}</div>
            </div>
          )}

          {!!summary.next_steps && !!summary.next_steps.length && (
            <div className="summary-card-section">
              <div className="summary-card-title">Next Steps</div>

              <div className="summary-card-description">
                <ul>
                  {summary.next_steps.map((nextStep) => (
                    <li key={nextStep}>{nextStep}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.notAvilableContent}>No summary available</div>
      )}
    </div>
  </div>
);

export default SummaryTab;
