import { QueryParamConfig } from 'serialize-query-params/lib/types';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

const OneOfParam = <T>(BaseParam: QueryParamConfig<T>, list: T[] = []) => ({
  encode: BaseParam.encode,

  decode(value: string | string[]) {
    const val = BaseParam.decode(value);

    return val && list.includes(val) ? val : undefined;
  },
});

const NumberMinMaxParam = ({ min, max }: { min?: number; max?: number }) => ({
  encode: NumberParam.encode,

  decode: (value: string | string[]) => {
    const num = NumberParam.decode(value);

    return num &&
      (min === undefined || num >= min) &&
      (max === undefined || num <= max)
      ? num
      : undefined;
  },
});

export const usePageNumber = () =>
  useQueryParam('page_number', NumberMinMaxParam({ min: 1 }));

export const usePageSort = (availableSortingColumns: string[] = []) =>
  useQueryParam(
    'sort',
    availableSortingColumns.length
      ? OneOfParam(StringParam, [
          ...availableSortingColumns,
          ...availableSortingColumns.map((i) => `-${i}`),
        ])
      : StringParam
  );

export const usePageSearch = () => useQueryParam('search', StringParam);
