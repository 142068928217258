import { Loader } from 'semantic-ui-react';

import {
  IColumn,
  IDataCellProps,
  IRow,
} from 'components/UI/common/TypedTable/TypedTable';
import { css } from 'emotion';
import { ReactNode } from 'react';

export interface IDataExpandableCellProps extends IDataCellProps {
  isLoading: boolean;
  children: React.ReactNode;
}
export const LoaderIconStyles = css`
  margin-left: 4px !important;
  margin-bottom: 2px !important;
  box-sizing: border-box;
`;

export const TotalStyles = css`
  display: flex;
  gap: 5px;
`;

interface EmptyIfNoDataCellProps {
  row: IRow;
  column: IColumn;
  children: ReactNode;
}

const TotalCell = ({ row }: IDataExpandableCellProps) => {
  const isLoading = row.isLoading;

  return (
    <div className={TotalStyles}>
      Totals
      {isLoading && (
        <span>
          <Loader className={LoaderIconStyles} active size="tiny" inline />
        </span>
      )}
    </div>
  );
};

export default TotalCell;
