import React, { useMemo } from 'react';
import { toast } from 'react-toastify';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useModal } from 'components/modals/ModalContext/modal.context';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import BuIcon from 'components/UI/BuIcon';

import * as styles from './styles';
import { FollowUpEmailModal as FollowUpEmailModalType } from '../../types';

const FollowUpEmailModal: React.FC = () => {
  const { getPropsForScheme } = useModal<FollowUpEmailModalType>();

  const {
    recipients = [],
    subject = '',
    body = '',
  } = getPropsForScheme('/follow-up-email') || {};

  const formattedRecipients = useMemo(
    () => recipients && recipients.join(', '),
    [recipients]
  );
  const splittedBody = useMemo(() => body.split('\n'), [body]);

  const copyToClipboard = (
    contextName: 'body' | 'recipients' | 'subject',
    textToCopy: string
  ) => {
    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success(`Email ${contextName} copied to the clipboard.`, {
            position: 'bottom-left',
          });
        })
        .catch(() => {
          toast.error(`Failed to copy ${contextName} to the clipboard.`, {
            position: 'bottom-left',
          });
        });
    }
  };

  return (
    <div className={styles.followUpEmailModalWrapper}>
      {!!formattedRecipients && (
        <div className="recipients">
          <span className="key">To:</span>
          <span>{formattedRecipients}</span>

          <TooltipWrapper tooltip="Copy recipients to the clipboard">
            <BuButton
              size={BuControlSize.SMALL}
              secondary
              borderless
              icon
              onClick={() => copyToClipboard('recipients', formattedRecipients)}
            >
              <BuIcon name={BoostUpIcons.Copy} />
            </BuButton>
          </TooltipWrapper>
        </div>
      )}

      {!!subject && (
        <div className="subject">
          <span className="key">Subject:</span>
          <span>{subject}</span>

          <TooltipWrapper tooltip="Copy subject to the clipboard">
            <BuButton
              size={BuControlSize.SMALL}
              secondary
              borderless
              icon
              onClick={() => copyToClipboard('subject', subject)}
            >
              <BuIcon name={BoostUpIcons.Copy} />
            </BuButton>
          </TooltipWrapper>
        </div>
      )}

      <div className="body">
        {splittedBody.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index < splittedBody.length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>

      <div className="action-button">
        <TooltipWrapper tooltip="Copy body to the clipboard">
          <BuButton
            size={BuControlSize.REGULAR}
            onClick={() => copyToClipboard('body', body)}
          >
            Copy Email
          </BuButton>
        </TooltipWrapper>
      </div>
    </div>
  );
};

export default FollowUpEmailModal;
