import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GetDealsParams, getDealsTotal } from 'api/Deals';
import { getTotalsRow } from 'components/UI/common/TypedTable/helper';
import { IColumn } from 'components/UI/common/TypedTable/TypedTable';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import { useSelector } from 'react-redux';
import { getIsTotalsRowEnabled, tableColumnConfiguration } from 'selectors';

export const mapOpportunitiesColumnsToTotalColumns = (
  columns: TableConfigurationColumn[]
) =>
  columns.reduce((partial, column) => {
    if (
      typeof column.crm_field !== 'string' ||
      typeof column.object_field !== 'string'
    ) {
      return partial;
    }

    partial[column.crm_field] = column.object_field;
    return partial;
  }, {} as Record<string, string>);

export const useGetNewForecastTotalDeals = (
  forecastParams: GetDealsParams,
  enabled: boolean
) => {
  const config = useSelector((state) =>
    tableColumnConfiguration(state, 'opportunity', 'Opportunities')
  );

  const isTotalsRowEnabled = useSelector(getIsTotalsRowEnabled);

  const queryClient = useQueryClient();

  const totalParams = {
    ...forecastParams,
    columns: config?.columns
      .map((column: IColumn) => column.crm_field)
      .filter(Boolean) as string[],
  };

  const callTotals = enabled && !!config?.columns && isTotalsRowEnabled;

  const { data, isLoading } = useQuery({
    queryKey: ['new_forecast', 'totals', totalParams],
    queryFn: () => getDealsTotal(totalParams),
    enabled: callTotals,
  });

  const totalRow =
    callTotals && config?.columns
      ? getTotalsRow(
          data,
          isLoading,
          mapOpportunitiesColumnsToTotalColumns(config?.columns)
        )
      : undefined;

  const invalidate = () => {
    queryClient.invalidateQueries(['new_forecast', 'totals']);
  };

  return {
    totalRow,

    invalidate,
  };
};
