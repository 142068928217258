import clx from 'classnames';
import * as R from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import { ToastOptions, toast } from 'react-toastify';
import { DropdownProps, Icon } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { TABLE_ID } from 'common/constants';
import BuButtonRefresh from 'components/UI/BuButtonRefresh';
import BuGroupButton from 'components/UI/BuGroupButton';
import BuIcon from 'components/UI/BuIcon';
import Table from 'components/UI/TableConfig/Table';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import ColumnsControl from 'components/UI/common/ColumnsControl';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { getColumns, getRows } from 'components/dashboard/Progression/helpers';
import * as styles from 'components/dashboard/Progression/styles';
import {
  IDropdownOption,
  IProps,
  Pace,
  apiResponse,
} from 'components/dashboard/Progression/types';
import { useCheckFrameWindow } from 'components/hooks/useCheckFrameWindow';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { useTableOffset } from 'components/hooks/useTableOffset';
import { QueryStatus, fetchApi } from 'utils/network';

const apiPoint = `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/pace`;
const toastOptions: ToastOptions = { position: 'bottom-left' };

const PROGRESSION_CLOSE_DATE_OPTIONS = [
  {
    text: 'Current Quarter',
    value: 'TQU',
  },
  {
    text: 'Current Year',
    value: 'TYE',
  },
];
const PROGRESSION_FREQUENCY_OPTIONS = [
  {
    text: 'Week by Week',
    value: 'week',
  },
  {
    text: 'Month by Month',
    value: 'month',
  },
  {
    text: 'Quarter by Quarter',
    value: 'quarter',
  },
];
const DEFAULT_MANAGER_LIST = [{ key: 0, value: 'All', text: 'All Managers' }];

const Progression: React.FC<IProps> = ({
  businessTypes,
  columnsOptions,
  isBusinessTypesConfigReady,
  companyCurrency,
  filters,
  setForecastProgressionFilter,
  tabPartition = 'forecast',
}) => {
  const { setPartition, clearContext } = useHeader();

  const [selectedBusinessType, selectBusinessType] = useState<string>(
    businessTypes[0]
  );
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [managersList, setManagersList] =
    useState<IDropdownOption[]>(DEFAULT_MANAGER_LIST);
  const [selectedManager, setSelectedManager] = useState('All');
  const [sortOrder, setSortOrder] = useState('');
  const [paces, setPaces] = useState<Pace[]>([]);
  const [containerRef, offset] = useTableOffset();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [refreshCache, setRefreshCache] = useState(false);
  const [cacheDate, setCacheDate] = useState('');
  const isCheckFrame = useCheckFrameWindow();

  const serializedQueryParams = JSON.stringify({
    close_date_interval: filters.paceInterval,
    pace_freq: filters.frequency,
    pace_interval: filters.paceInterval,
    business_type_name: selectedBusinessType,
  });

  useEffect(() => {
    setPartition(tabPartition);

    AnalyticsTracker.event(
      { filters },
      {
        action: 'Open',
        category: 'Forecast',
        label: 'Pacing page',
      }
    );

    return () => {
      clearContext();
    };
  }, []);

  useEffect(() => {
    selectBusinessType(businessTypes[0]);
  }, [JSON.stringify(businessTypes)]);

  useEffect(() => {
    if (!isBusinessTypesConfigReady) {
      return;
    }

    const abortController = new AbortController();
    const setData = ({ data }: apiResponse) => {
      const managerNames = [
        ...Array.from(
          new Set(
            data.filter((pace) => pace.manager).map((x) => x.manager.name)
          )
        ),
      ];
      setManagersList([
        ...DEFAULT_MANAGER_LIST,
        ...[...managerNames]
          .map((name: string, id: number) => ({
            key: id + 1,
            value: name,
            text: name,
          }))
          .sort((a, b) => (a.value > b.value ? 1 : -1)),
      ]);
      setPaces(data);
    };

    fetchApi<string, apiResponse>({
      queryParams: serializedQueryParams,
      setData,
      setError: (error: string | null) =>
        toast.error(`Fetching data failed: ${error}`, toastOptions),
      setStatus: setDataStatus,
      setHeaders: (headers) => setCacheDate(headers.get('cache-created') || ''),
      signal: abortController.signal,
      url: apiPoint,
    });

    return () => abortController && abortController.abort();
  }, [serializedQueryParams]);

  useEffect(() => {
    let abortController: AbortController | null = null;

    if (refreshCache) {
      abortController = new AbortController();
      fetchApi<string, apiResponse>({
        queryParams: serializedQueryParams,
        setData: ({ data }: apiResponse) => {
          const managerNames = [
            ...Array.from(
              new Set(
                data.filter((pace) => pace.manager).map((x) => x.manager.name)
              )
            ),
          ];
          setManagersList([
            ...DEFAULT_MANAGER_LIST,
            ...[...managerNames]
              .map((name: string, id: number) => ({
                key: id + 1,
                value: name,
                text: name,
              }))
              .sort((a, b) => (a.value > b.value ? 1 : -1)),
          ]);
          setPaces(data);
        },
        setError: (error: string | null) =>
          toast.error(`Fetching data failed: ${error}`, toastOptions),
        setStatus: setDataStatus,
        setHeaders: (headers) => {
          setCacheDate(headers.get('cache-created') || '');
          setRefreshCache(false);
        },
        signal: abortController.signal,
        url: refreshCache ? `${apiPoint}?cache_refresh=1` : apiPoint,
      });
    }
  }, [refreshCache, setRefreshCache]);

  const handleEnabledColumnsChange = useCallback(
    ({ id }: { id: string }) => {
      const newCheckedColumns = filters.checkedColumns.includes(id)
        ? filters.checkedColumns.filter((value) => value !== id)
        : [...filters.checkedColumns, id];

      setForecastProgressionFilter(
        'checkedColumns',
        newCheckedColumns.length
          ? // Add the columns in the same order
            columnsOptions
              .filter((item) =>
                newCheckedColumns.some((value) => value === item.value)
              )
              .map((item) => item.value)
          : [id]
      );
    },
    [JSON.stringify(filters.checkedColumns), columnsOptions]
  );

  const displayedColumns = useMemo(
    () =>
      columnsOptions.map(({ text, value }) => ({
        label: text,
        id: value,
        show: filters.checkedColumns.includes(value),
      })),
    [columnsOptions, filters.checkedColumns]
  );

  const checkedColumns = useMemo(
    () =>
      columnsOptions.filter((column) =>
        filters.checkedColumns.includes(column.value)
      ),
    [columnsOptions, filters.checkedColumns]
  );

  const isLoading = ['notAsked', 'loading'].includes(dataStatus);

  const filteredPaces = useMemo(
    () =>
      selectedManager === 'All'
        ? paces
        : paces.filter(
            (pace: Pace) =>
              pace.manager && pace.manager.name === selectedManager
          ),
    [paces, selectedManager]
  );

  const { columns, extraHeader } = useMemo(
    () => getColumns(filteredPaces, checkedColumns, companyCurrency),
    [filteredPaces, checkedColumns, companyCurrency]
  );
  const rows = useMemo(() => getRows(filteredPaces), [filteredPaces]);

  let sortToUse = '';
  if (sortOrder) {
    // If a sortOrder was set, then check if the column is visible or use the default one (seller).
    sortToUse =
      sortOrder.includes('seller') ||
      checkedColumns.some((column) => sortOrder.includes(column.value))
        ? sortOrder
        : 'seller';
  } else if (
    filteredPaces.length &&
    checkedColumns.some((column) => column.value === 'pace')
  ) {
    /**
     * If there are paces and the pace column is visible, then use the current week/month/quarter
     * Booked Pace desc (which is always the period of the last stat).
     */
    const { stats } = filteredPaces[0];
    const currentPeriod = stats[stats.length - 1].period;
    sortToUse = `-${currentPeriod}|pace`;
  } else {
    // If there are no paces or the pace column is not visible, then default the sort to seller.
    sortToUse = 'seller';
  }
  const itemProp = sortToUse.replace('-', '');
  const isAscending = sortToUse.includes('-');

  const sortedRows = useMemo(
    () =>
      [...rows].sort(({ [itemProp]: a }, { [itemProp]: b }) => {
        let result = 0;

        if (a === b) {
          result = 0;
        } else if (R.isNil(a)) {
          result = 1;
        } else if (R.isNil(b)) {
          result = -1;
        } else if (isAscending) {
          result = a < b ? 1 : -1;
        } else {
          result = a < b ? -1 : 1;
        }

        return result;
      }),
    [rows, isAscending, itemProp]
  );

  const buildOptionGroupData = (options: string[]) => {
    return options.map((option) => {
      return {
        id: option,
        text: option,
      };
    });
  };

  const heightTable = `calc(100vh - ${isCheckFrame ? offset + 50 : offset}px)`;

  return (
    <div className={styles.wrapper}>
      <div className="container" ref={containerRef}>
        <div className="container-dashboard">
          {!!businessTypes.length && (
            <BuGroupButton
              className={styles.buttonGroupContainer}
              options={buildOptionGroupData(businessTypes)}
              selectedOption={selectedBusinessType}
              onSelect={(value: string) => selectBusinessType(value)}
              useRevampStyle
            />
          )}

          <div className={styles.header}>
            <span className={styles.title}>How are your reps pacing?</span>
            <BuButtonRefresh
              onClick={() => setRefreshCache(true)}
              cacheDate={cacheDate}
              status={refreshCache}
            />
            <div className={styles.filters}>
              <ColumnsControl
                displayedColumns={displayedColumns}
                onChange={handleEnabledColumnsChange}
              />
              <DropdownCustom
                search
                value={selectedManager}
                options={managersList}
                onClick={(option: DropdownProps) =>
                  setSelectedManager(`${option}`)
                }
              />
              <DropdownCustom
                options={PROGRESSION_CLOSE_DATE_OPTIONS}
                onClick={(option: string) => {
                  setForecastProgressionFilter('paceInterval', option);
                  setForecastProgressionFilter(
                    'frequency',
                    option === 'TYE' ? 'month' : 'week'
                  );
                }}
                value={filters.paceInterval}
              />
              <DropdownCustom
                value={filters.frequency}
                options={PROGRESSION_FREQUENCY_OPTIONS}
                onClick={(option: DropdownProps) => {
                  setSortOrder('');
                  setForecastProgressionFilter('frequency', option);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.tableContainer(checkedColumns.length)}
        style={{
          height: heightTable,
        }}
      >
        <Table
          tableId={TABLE_ID.PACING}
          title=""
          columns={columns}
          extraHeader={extraHeader}
          rowsPerPage={0}
          currentPage={0}
          data={sortedRows}
          hideSearch
          hidePaginationEnd
          hidePaginationStart
          sortOrder={sortToUse}
          onSort={(sort?: string) => setSortOrder(sort || 'seller')}
          loading={isLoading}
          fullscreen
          onRender={() => {
            if (scrollRef.current) {
              scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
            }
          }}
          innerScrollRef={scrollRef}
        />
      </div>
    </div>
  );
};

export default Progression;

type IpropsDropdownProps = {
  onClick: any;
  value: string;
  options: {
    value: string;
    text: string;
  }[];
  search?: boolean;
};

const DropdownCustom: React.FC<IpropsDropdownProps> = ({
  onClick,
  value,
  options,
  search = false,
}) => {
  const [query, setQuery] = useState<string>('');
  const { isOpen, setIsOpen, refElement } = useClickOutside();
  const inputRef = useRef<HTMLDivElement>(null);
  const option = options.find((i) => i.value === value);

  const filterdOptions = options.filter((i) =>
    i.text.toLocaleLowerCase().includes(query)
  );

  const activeValue = option !== undefined ? option.text : '';

  const toggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (
      isOpen &&
      !R.isNil(inputRef.current) &&
      inputRef.current.contains(event.target as Node)
    ) {
      return;
    }
    setIsOpen(!isOpen);
  };

  return (
    <div
      ref={refElement}
      className={clx(styles.dropdrown, { active: isOpen })}
      onClick={(event) => toggle(event)}
    >
      <span className="bu-font-default">
        {activeValue}
        <i
          className={clx(styles.triangle, {
            'bu-up': isOpen,
            'bu-down': !isOpen,
          })}
        />
      </span>
      <div className={clx(styles.dropdrown_options, { open: isOpen })}>
        {search && (
          <div ref={inputRef} className={styles.dropdown_search}>
            <span className={styles.dropdown_search_icon}>
              <Icon name="search" />
            </span>
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search..."
              data-testing="txt_field"
            />
            {query.length !== 0 && (
              <span
                onClick={() => setQuery('')}
                className={styles.btn_reset_input}
              >
                <BuIcon name={BoostUpIcons.ClosePopup} />
              </span>
            )}
          </div>
        )}
        <div className={styles.dropdrown_options_list}>
          {filterdOptions.map((i) => (
            <div
              key={i.text}
              onClick={() => {
                setIsOpen(false);
                onClick(i.value);
              }}
              className={clx(styles.dropdrown_options_item, {
                active: i.value === value,
              })}
            >
              {i.text}
            </div>
          ))}
          {filterdOptions.length === 0 && (
            <div className={styles.no_result}>
              <p>No results found.</p>
              <button onClick={() => setQuery('')}>reset search</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
