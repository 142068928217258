import React from 'react';

import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';

import { RoundedAvatar } from './styles';

type Props = {
  color: string;
};

const RoundedAvatarIcon: React.FC<Props> = ({ color }) => (
  <RoundedAvatar backgroundColor={color}>
    <BuIcon name={BoostUpIcons.User} />
  </RoundedAvatar>
);

export default RoundedAvatarIcon;
