import { css } from 'emotion';
import React, { useState } from 'react';
import { Dimmer, Loader, Menu } from 'semantic-ui-react';

import InfoIcon from 'assets/images/new_icon/info_icon.svg';
import Pagination from 'components/UI/common/Pagination/Pagination';
import Scrolling from 'components/UI/common/Scrolling/Scrolling';
import SearchInput from 'components/UI/common/SearchInput/SearchInput';
import TypedTable, {
  BorderType,
  ColumnsType,
  IColumn,
  IRow,
  StatusData,
  TableDetails,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import * as s from 'components/dashboard/Accounts/styles';

const resetMargins = css`
  &.ui.menu.fluid {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

type ExtraField = {
  [key: string]: any;
};

type RiskFactor = {
  category: string;
  name: string;
  score: number;
  text: string;
  updated_at: string;
};

export type AccountData = {
  account_name: string;
  email_domain: string;
  open_opportunities: number;
  positive_indicators: RiskFactor[] | null;
  risk_factors: RiskFactor[] | null;
  risk_score: number;
  risk_status: string;
  risk_status_display: string;
  user: {
    email: string;
    name: string;
  };
  _id: string;
};

type RowStatusType = {
  [id: string]: {
    [fieldName: string]: StatusData;
  };
};

type Accounts = {
  total_count: number;
  data: AccountData[];
  rowStatus?: RowStatusType;
  query?: {
    users: any[];
    account_name: string;
    page_size: number;
    page_number: number;
    sort: string;
  };
};

export interface IProps {
  loading?: boolean;
  widgetSize?: boolean;
  title: string;
  accounts: Accounts;
  sortOrder?: string;
  config: ColumnsType;
  searchText: string;
  rowsPerPage: number;
  currentPage: number;
  minWidth?: number;
  onSort?: (sortOrderString?: string) => void;
  onSearchChange?: (value: string) => void;
  onPaginationChange?: (page: number, pageSize: number) => void;
  onChange?: (col: IColumn, row: IRow, nextValue: ValueProp) => void;
  hidePaginationEnd?: boolean;
  hidePaginationStart?: boolean;
  hideSearch?: boolean;
  margins?: number;
}

/** @deprecated please use 'src/components/UI/TableConfig/' instead */
export const AccountsList: React.FC<IProps> = ({
  loading = false,
  title,
  accounts,
  sortOrder = '',
  config,
  searchText,
  rowsPerPage,
  currentPage,
  onSort = () => {},
  onSearchChange = () => {},
  onPaginationChange = () => {},
  onChange = () => {},
  hidePaginationEnd = false,
  hidePaginationStart = false,
  hideSearch = false,
  widgetSize,
  minWidth,
  margins = 0,
}: IProps) => {
  const [shadowTopOffset, setShadowTopOffset] = useState(0);
  const [shadowLeftOffset, setShadowLeftOffset] = useState(0);

  const data: IRow[] =
    accounts && accounts.data && accounts.data.length
      ? accounts.data.map(({ _id: id, ...row }) => {
          const extraFields: ExtraField = {};

          return {
            ...row,
            id,
            _id: id,
            ...extraFields,
          };
        })
      : [];

  const totalPages =
    rowsPerPage > 0 ? Math.ceil(accounts.total_count / rowsPerPage) : 1;

  const handleTableDetails = (details: TableDetails) => {
    if (details.columns && details.columns[0]) {
      setShadowLeftOffset(details.columns[0].width);
      setShadowTopOffset(details.columns[0].height);
    }
  };

  return (
    <Dimmer.Dimmable dimmed={loading} style={{ height: '100%' }}>
      {!widgetSize && (
        <div style={{ paddingLeft: margins, paddingRight: margins }}>
          <Menu fluid text className={resetMargins}>
            <Menu.Item fitted>
              <span className={s.header}>
                {`${title} (${accounts.total_count || 0})`}
              </span>
              <div className={s.lastUpdate}>
                <img src={InfoIcon} alt="info" />
                Last updated at 12.00 am PST today
              </div>
            </Menu.Item>

            <Menu.Menu position="right">
              {hideSearch ? null : (
                <Menu.Item fitted>
                  <SearchInput
                    placeholder="Search for accounts"
                    defaultValue={searchText}
                    onChange={onSearchChange}
                  />
                </Menu.Item>
              )}
              {hidePaginationStart ? null : (
                <Menu.Item fitted>
                  <Pagination
                    totalPages={totalPages}
                    pageNumber={currentPage}
                    rowPerPage={rowsPerPage}
                    onChange={onPaginationChange}
                  />
                </Menu.Item>
              )}
            </Menu.Menu>
          </Menu>
        </div>
      )}

      <TypedTable.Border
        height={
          widgetSize
            ? 391
            : `calc(100% - (40px + 10px))` /* header/searching height + margin bottom */
        }
        borders={margins ? BorderType.TOP : BorderType.ALL}
      >
        <Scrolling
          width
          shadows
          height="100%"
          shadowLeftOffset={shadowLeftOffset}
          shadowTopOffset={shadowTopOffset}
          alwaysShow={!!margins}
        >
          <TypedTable
            widgetSize={widgetSize}
            minWidth={minWidth}
            columns={config}
            data={data}
            onSort={onSort}
            sorting={sortOrder}
            onChange={onChange}
            rowStatus={accounts.rowStatus}
            onRender={handleTableDetails}
            stickyHeader
            fullscreen={!!margins}
          />
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Scrolling>
      </TypedTable.Border>
      {hidePaginationEnd ? null : (
        <Menu fluid text>
          <Menu.Menu position="right">
            <Menu.Item fitted>
              <Pagination
                totalPages={totalPages}
                pageNumber={currentPage}
                rowPerPage={rowsPerPage}
                onChange={onPaginationChange}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      )}
    </Dimmer.Dimmable>
  );
};

AccountsList.defaultProps = {
  loading: false,
  widgetSize: false,
  minWidth: undefined,
  onSort: () => {},
  onSearchChange: () => {},
  onPaginationChange: () => {},
  onChange: () => {},
  hidePaginationEnd: false,
  hidePaginationStart: false,
  hideSearch: false,
};
