import { css } from 'emotion';

import { cardStyle, tabContentWrapper, notAvilableContent } from '../../styles';

export { tabContentWrapper, notAvilableContent };

export const actionItemsTabWrapper = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-large);

  .action-items-card {
    ${cardStyle}
    display: flex;
    gap: var(--bu-control-gap-large);

    .content {
      display: flex;
      flex-direction: column;
      gap: var(--bu-control-gap-medium);

      /* TODO: Will have this in the future *
      /* .content-description {
        margin-right: 35px;

        .done-wrapper {
          width: 24px;
          height: 24px;
          border: 1px solid var(--bu-gray-600);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 24px;
          margin-right: 5px;

          .done-icon {
            color: var(--bu-gray-400);

            &.done {
              color: var(--bu-gray-900);
            }

            i {
              font-size: 10px !important;
            }
          }
        }

        i {
          font-size: 18px;
        }
      } */

      .content-actions {
        button {
          font-size: 12px;
          margin: 0;
        }
      }

      .content-description,
      .content-actions {
        display: flex;
        align-items: center;
        gap: var(--bu-control-gap-medium);

        i {
          color: var(--bu-purple-500);
        }
      }
    }
  }
`;

export const followUpEmailModalWrapper = css`
  padding: 12px 0;

  > div {
    padding: 12px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .recipients,
  .subject {
    border-bottom: 1px solid var(--bu-gray-300);
    display: flex;
    align-items: center;
    min-height: 50px;

    .key {
      color: var(--bu-gray-700);
      margin-right: 8px;
    }

    button {
      i {
        font-size: 14px !important;
      }
    }
  }

  .subject {
    height: 32px;
  }

  .body {
    font-family: var(--bu-font-medium);
  }

  .action-button {
    margin-top: 24px;

    button {
      margin: 0;
    }
  }
`;
