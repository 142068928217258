// TODO: Extends from OauthBaseIntegration;
class SalesforceOAuth {
  constructor(appId, loginURL, oauthCallbackURL) {
    const scope = 'id api web full refresh_token custom_permissions';
    this.appId = appId;
    const prompt = 'login consent';
    this.oAuthUrl =
      loginURL +
      `/services/oauth2/authorize?client_id=${this.appId}&redirect_uri=${oauthCallbackURL}&response_type=code&scope=${scope}&prompt=${prompt}`;
    this.oAuthWindow = null;
  }

  handleOAuthCallBack = (event, resolve, reject) => {
    let url = event.detail;
    let auth_code;
    let error_type = 'unknown',
      error_description = 'unknown error';
    if (url) {
      const parsedURL = new URL(url);
      auth_code = parsedURL.searchParams.get('code');
      if (!auth_code) {
        error_type = parsedURL.searchParams.get('error');
        error_description = parsedURL.searchParams.get('error_description');
      }
    }
    if (auth_code) {
      resolve({
        code: auth_code,
      });
    } else {
      reject({
        error_type,
        error_description,
      });
    }
  };

  login = () => {
    const oauthObject = this;
    return new Promise((resolve, reject) => {
      document.addEventListener('oauthCallback', (event) => {
        oauthObject.handleOAuthCallBack(event, resolve, reject);
      });
      oauthObject.oauthWindow = window.open(
        oauthObject.oAuthUrl,
        '_blank',
        'location=no'
      );
    });
  };
}

export default SalesforceOAuth;
