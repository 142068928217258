import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

import * as styles from './styles';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import { Mention, PositiveNegativeMention } from '../../types';
import { useVideoContext } from '../../VideoPlayer/videoPlayer.context';

type Props = {
  data: PositiveNegativeMention[];
  mentionType: 'negative' | 'positive';
  disabled: boolean;
  handleClickMention: (mention: Mention) => void;
};

const MentionsCard: React.FC<Props> = ({
  data,
  mentionType,
  disabled,
  handleClickMention,
}) => {
  const { highlighted } = useVideoContext();

  const [mentionsShowAll, setMentionsShowAll] = useState(false);

  const handleShowMoreMention = () => setMentionsShowAll((prev) => !prev);

  const mentionsList = useMemo<Mention[]>(() => {
    if (highlighted.name && highlighted.type === 'topic') {
      const topicMentions = data.find(
        (mentions) =>
          mentions.name === highlighted.name && !mentions.is_competitor
      );

      if (topicMentions) {
        return topicMentions.mentions.map((mention) => ({
          ...mention,
          is_competitor: topicMentions.is_competitor,
          name: topicMentions.name,
        }));
      }

      return [];
    }

    return data.flatMap((mentions) =>
      mentions.mentions.map((mention) => ({
        ...mention,
        ...mentions,
      }))
    );
  }, [data, highlighted.name]);

  const mentionsToRender = useMemo(
    () =>
      mentionsShowAll || mentionsList.length < 10
        ? mentionsList
        : mentionsList.slice(0, 9),
    [mentionsList, mentionsShowAll]
  );

  const showShowMoreButton = useMemo(
    () => mentionsList.length > 9,
    [mentionsList]
  );

  const getMentionToolTip = (mention: Mention) => {
    return (
      <div className={styles.mentionTooltipWrapper}>
        <div className="mention">
          <span className="name">Mention:</span>
          <span>{mention.mention}</span>
        </div>

        {!mention.is_competitor && (
          <div className="topic">
            <span className="name">Topic:</span>
            <span>{mention.name}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="insight-card">
      <div className="insight-card-title">{mentionType} mentions</div>

      <div className={classNames('insight-card-content', mentionType)}>
        {!!mentionsToRender.length ? (
          mentionsToRender.map((mention) => (
            <React.Fragment key={mention.mention}>
              <TooltipWrapper
                tooltip={getMentionToolTip(mention)}
                position="bottom center"
                key={`${mention.name}-${mention.start_time}`}
                hoverable
              >
                <div
                  className={classNames('no-capitalize', {
                    disabled,
                  })}
                  onClick={() => handleClickMention(mention)}
                >
                  {mention.mention}
                </div>
              </TooltipWrapper>
            </React.Fragment>
          ))
        ) : (
          <span>No {mentionType} mentions found</span>
        )}
      </div>

      {showShowMoreButton && (
        <BuButton
          onClick={handleShowMoreMention}
          size={BuControlSize.REGULAR}
          borderless
          className="show-more"
          dropdown={mentionsShowAll ? 'open' : 'close'}
        >
          <span>Show {mentionsShowAll ? 'less' : 'more'}</span>
        </BuButton>
      )}
    </div>
  );
};

export default MentionsCard;
