import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Dropdown } from 'semantic-ui-react';

import {
  AddFilterButton,
  AddFilterButtonContainer,
  ApplyContainer,
  DashboardFiltersContainer,
  DashboardFiltersRow,
  FilterSearch,
  FilterSearchInput,
  FilterWrapper,
  FiltersWidgetMenu,
  RemoveFilterButton,
} from 'components/dashboard/Metrics/Dashboard/styles';

import {
  DataDescriptor,
  BIMetricCreated,
  BIMetricSimple,
  BIMetricsFilter,
  BIMetricsMap,
  BIWidget,
  BIMetricFormula,
} from 'components/dashboard/Metrics/metrics.types';

import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuDropdown, {
  BuDropdownItem,
  DropdownItemContainer,
} from 'components/UI/BuDropdown';
import { getDropdownFriendlyName, getWidgetObjects } from '../metrics.helpers';
import { TemplateFilter } from '../Widget/TemplateFilters/TemplateFilter/TemplateFilter';
import BuButton from 'components/UI/BuButton';
import { TARGET } from '../constants';
import { getDashboardFiltersColumnsFields } from 'api/DashboardFilters';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import { useSelector } from 'react-redux';
import { getUser } from 'selectors';
import { isEqual } from 'lodash';
import { MetricType } from '../enums';
import { parseFormulaToMetricsIdArray } from '../Widget/helper';
import { useFormulaMetricsList } from '../Widget/hooks/useFormulaMetricsList';

interface Props {
  dashboardId?: string;
  widgetsList: BIWidget[];
  dashboardFilters: BIMetricsFilter[];
  setDashboardList: (filters: BIMetricsFilter[]) => void;
  updateFilter: (filter: BIMetricsFilter) => void;
  onApply: () => void;
  readOnly: boolean;
}

export const DashboardFilters: React.FC<Props> = ({
  dashboardId,
  widgetsList,
  dashboardFilters,
  setDashboardList,
  updateFilter,
  onApply,
  readOnly,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [applyDirty, setApplyDirty] = useState<boolean>(false);
  const [defaultFilters, setDefaultFilters] = useState<BIMetricsFilter[]>([]);
  const user = useSelector(getUser);
  const [persistedFilters, setPersistedFilters] = useLocalStorage<
    BIMetricsFilter[]
  >(`dashboard_filters_${user?.email}_${dashboardId}`, []);

  const usedFormulaMetricIds: string[] = useMemo(() => {
    let formulaMetricIds = new Set<string>();
    widgetsList.forEach((widget) => {
      widget.metric_list.forEach((metric) => {
        if (metric.metadata.type === MetricType.Formula) {
          const formula = metric as BIMetricFormula;
          const metricIds = parseFormulaToMetricsIdArray(
            formula.synthetic_metric
          );
          metricIds.forEach((id) => formulaMetricIds.add(id));
        }
      });
    });
    return Array.from(formulaMetricIds);
  }, [widgetsList]);

  const { data: usedFormulaMetricsMap } =
    useFormulaMetricsList(usedFormulaMetricIds);

  // Set of objects of the widgets.
  const tables = useMemo(() => {
    let allObjects = new Set<string>();

    widgetsList.forEach((widget) => {
      const widgetObjects = getWidgetObjects(widget, usedFormulaMetricsMap);
      allObjects = new Set([...allObjects, ...widgetObjects]);
    });

    return Array.from(allObjects);
  }, [widgetsList, usedFormulaMetricsMap]);

  // List of used metrics in all the widgets
  const metricsInUse: BIMetricsMap = useMemo(() => {
    let metrics: BIMetricsMap = {};

    widgetsList.forEach((widget) => {
      const widgetMetrics = widget.metric_list.reduce(
        (acc: BIMetricsMap, metric: BIMetricCreated) => {
          const key = (metric as BIMetricCreated)._id;
          acc[key] = metric;
          return acc;
        },
        {}
      );
      metrics = { ...metrics, ...widgetMetrics };
    });

    return metrics;
  }, [widgetsList]);

  const firstTargetMetric = useMemo(() => {
    const keys = Object.keys(metricsInUse);
    for (const key of keys) {
      const current = metricsInUse[key];
      if ((current as BIMetricSimple).object === TARGET) {
        return current;
      }
    }

    return null;
  }, [metricsInUse]);

  useEffect(() => {
    setDefaultFilters(dashboardFilters);
  }, [dashboardId]);

  // Fetching the column fields for the selected tables
  const { data: fetchedFields } = useQuery({
    queryKey: ['dashboard_filters', 'get_column_fields_filter', tables],
    queryFn: () => getDashboardFiltersColumnsFields(tables),
    enabled: tables.length > 0,
  });
  const fields: DataDescriptor[] = fetchedFields || [];

  const searchedValues = searchValue
    ? fields.filter((field) =>
        getDropdownFriendlyName(field)
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      )
    : fields;
  const unusedValues = searchedValues
    .filter(
      (el) =>
        dashboardFilters.findIndex(
          (filter) => filter.column.name === el.name
        ) === -1
    )
    .sort((a, b) =>
      getDropdownFriendlyName(a).localeCompare(getDropdownFriendlyName(b))
    );

  if (readOnly && dashboardFilters.length == 0) {
    return null;
  }

  const hasPersistedFilters = readOnly && persistedFilters.length > 0;
  const mappedPersistedFilters: { [key: string]: BIMetricsFilter } =
    persistedFilters.reduce(
      (acc, filter) => ({ ...acc, [filter.column.name]: filter }),
      {}
    );
  const dashboardFiltersWithPersistance = hasPersistedFilters
    ? dashboardFilters.map((filter) =>
        Object.keys(mappedPersistedFilters).includes(filter.column.name)
          ? mappedPersistedFilters[filter.column.name]
          : filter
      )
    : dashboardFilters;

  useEffect(() => {
    // On component mount, apply the persisted filters
    if (hasPersistedFilters) {
      dashboardFiltersWithPersistance.forEach((filter) => {
        updateFilter(filter);
      });
      onApply();
    }
  }, []);

  return (
    <DashboardFiltersRow>
      <DashboardFiltersContainer>
        {dashboardFilters.map((filter, idx) => (
          <FilterWrapper key={`${filter.column.name}`}>
            <TemplateFilter
              data-testing={`filter-${idx}`}
              isDateFirstPivot={false}
              widgetFilter={filter.column}
              targetPeriod={
                (firstTargetMetric as BIMetricSimple)?.target_period
              }
              templateFilters={dashboardFiltersWithPersistance.filter(
                (elem) =>
                  elem.column.name === filter.column.name &&
                  elem.operator !== 'all'
              )}
              relativeDateFilterOptions={[]}
              onChangeWidget={(widget: Partial<BIWidget>) => {
                if (widget.template_filters === undefined) {
                  return;
                }
                const currentFilter = widget.template_filters[0];
                const oldFilter =
                  dashboardFilters.find(
                    (item) => item.column.name === filter.column.name
                  ) || ({} as BIMetricsFilter);
                const newFilter = currentFilter
                  ? currentFilter
                  : {
                      column: filter.column,
                      operator: 'all',
                      value: 'all',
                    };

                if (!isEqual(oldFilter, newFilter)) {
                  setApplyDirty(true);
                  if (dashboardId) {
                    setPersistedFilters([
                      ...dashboardFilters.filter(
                        (prevFilter) =>
                          prevFilter.column.name !== filter.column.name
                      ),
                      newFilter,
                    ]);
                  }
                }

                updateFilter(newFilter);
              }}
            />
            {!readOnly && (
              <BuButton
                className={RemoveFilterButton}
                secondary
                icon
                onClick={() => {
                  setDashboardList([
                    ...dashboardFilters.filter(
                      (elem) => elem.column.name !== filter.column.name
                    ),
                  ]);
                  setApplyDirty(true);
                }}
              >
                <BuIcon name={BoostUpIcons.Trash} />
              </BuButton>
            )}
          </FilterWrapper>
        ))}
        {readOnly && (
          <div>
            <BuButton
              disabled={!hasPersistedFilters}
              onClick={() => {
                if (dashboardId) {
                  setPersistedFilters([]);
                  setDashboardList(defaultFilters);
                  onApply();
                  setApplyDirty(false);
                }
              }}
            >
              Reset to defaults
            </BuButton>
          </div>
        )}
        {!readOnly && (
          <AddFilterButtonContainer>
            <BuDropdown
              label={<AddFilterButton>+ Add Filter</AddFilterButton>}
              secondary
              borderless
              noDropdownIcon
            >
              {(hide) => (
                <Dropdown.Menu className={FiltersWidgetMenu}>
                  <FilterSearch>
                    <BuIcon name={BoostUpIcons.Search} />
                    <FilterSearchInput
                      value={searchValue}
                      type="text"
                      placeholder="Search..."
                      data-testing="search_txt_field"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSearchValue(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === ' ') {
                          e.preventDefault();
                          e.stopPropagation();
                          setSearchValue((prev) => {
                            if (prev && prev !== ' ') {
                              return prev + ' ';
                            }
                            return prev;
                          });
                        }
                      }}
                    />
                  </FilterSearch>

                  <DropdownItemContainer>
                    {unusedValues.map((field, idx) => (
                      <BuDropdownItem
                        key={idx}
                        data-testing={`filter-${idx}`}
                        onClick={() => {
                          hide();
                          setSearchValue('');
                          setDashboardList([
                            ...dashboardFilters,
                            {
                              column: {
                                ...field,
                                label: getDropdownFriendlyName(field),
                              },
                              operator: 'all',
                              value: 'all',
                            },
                          ]);
                          setApplyDirty(true);
                        }}
                      >
                        {getDropdownFriendlyName(field)}
                      </BuDropdownItem>
                    ))}
                  </DropdownItemContainer>
                </Dropdown.Menu>
              )}
            </BuDropdown>
          </AddFilterButtonContainer>
        )}
        <ApplyContainer>
          <BuButton
            disabled={!applyDirty}
            onClick={() => {
              setApplyDirty(false);
              onApply();
            }}
          >
            {readOnly ? 'Apply Filters' : 'Refresh preview with filters'}
          </BuButton>
        </ApplyContainer>
      </DashboardFiltersContainer>
    </DashboardFiltersRow>
  );
};
