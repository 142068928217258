import * as R from 'ramda';

import * as t from 'actions/actionTypes';
import { getAction, postAction } from 'actions/baseActions';
import * as genericSagas from 'sagas/generic';

export const userActionType = {
  UPDATE_STATE: 'USER_UPDATE_STATE',
  RESET_STATE: 'USER_RESET_STATE',
  SET_ORIGINAL_USER: 'SET_ORIGINAL_USER',
};

export const createUserAction = {
  updateState: (key, value) => {
    return {
      type: userActionType.UPDATE_STATE,
      key,
      value,
    };
  },
  resetState: () => {
    return {
      type: userActionType.RESET_STATE,
    };
  },
  setOriginalUser: (originalUser) => {
    return {
      type: userActionType.SET_ORIGINAL_USER,
      originalUser,
    };
  },
  processApiSuccess: (response) => {
    return createUserAction.updateState('apiSuccess', response);
  },
  processApiError: (response) => {
    return createUserAction.updateState('apiError', response);
  },
  processLoginUser: (response) => {
    return createUserAction.updateState({
      apiSuccess: response,
      api_access_token: response.data.api_access_token,
      role: response.data.role,
      user_id: response.data.user_id,
      name: response.data.name,
      email: response.data.email,
    });
  },
};

export const doResetPassword = (email) => {
  return getAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/user/reset-password`,
    {
      email,
    },
    createUserAction.processApiSuccess,
    createUserAction.processApiError
  );
};

export const doGetGoogleOAuthToken = (
  email,
  api_access_token,
  auth_code,
  scope,
  successCallback,
  failureCallback
) => {
  const success = successCallback || createUserAction.processApiSuccess;
  const failure = failureCallback || createUserAction.processApiError;
  return postAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/google/oauth/generate-token`,
    {
      email,
      api_access_token,
      auth_code,
      scope,
    },
    success,
    failure
  );
};

export const doGetSalesforceOAuthToken = (
  email,
  api_access_token,
  auth_code,
  successCallback,
  failureCallback
) => {
  const success = successCallback || createUserAction.processApiSuccess;
  const failure = failureCallback || createUserAction.processApiError;
  return postAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/salesforce/generate-token`,
    {
      email,
      api_access_token,
      auth_code,
    },
    success,
    failure
  );
};

export const doGetHubspotOAuthToken = (
  email,
  api_access_token,
  auth_code,
  successCallback,
  failureCallback
) => {
  const success = successCallback || createUserAction.processApiSuccess;
  const failure = failureCallback || createUserAction.processApiError;
  return postAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/hubspot/oauth`,
    {
      email,
      api_access_token,
      auth_code,
    },
    success,
    failure
  );
};

export const doGetZoomOAuthToken = (
  auth_code,
  successCallback,
  failureCallback
) => {
  const success = successCallback || createUserAction.processApiSuccess;
  const failure = failureCallback || createUserAction.processApiError;
  return getAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/recall/oauth-callback/zoom`,
    {
      code: auth_code,
    },
    success,
    failure
  );
};

export const doGetOutlookOAuthToken = (
  email,
  api_access_token,
  auth_code,
  scope,
  successCallback,
  failureCallback
) => {
  const success = successCallback || createUserAction.processApiSuccess;
  const failure = failureCallback || createUserAction.processApiError;
  return postAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/outlook/oauth/generate-token`,
    {
      email,
      api_access_token,
      auth_code,
      scope,
    },
    success,
    failure
  );
};

export const doLogoutUser = (email, api_access_token) => {
  return postAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/user/logout`,
    {
      email,
      api_access_token,
    },
    createUserAction.processApiSuccess,
    createUserAction.processApiError
  );
};

export const enterLogin = () => ({
  type: t.LOGIN + t.ENTER,
});

export const exitLogin = () => ({
  type: t.LOGIN + t.EXIT,
});

const loginWithOauth = (provider, code) => ({
  type: t.LOGIN + t.OAUTH,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/user/login/oauth`,
  data: {
    auth_provider: provider,
    auth_code: code,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.LOGIN + t.OAUTH + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.LOGIN + t.OAUTH + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.LOGIN + t.OAUTH + t.LOADING,
  }),
});

export const loginWithSSO = (email) => ({
  type: t.LOGIN + t.SSO,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/user/sso/link`,
  data: {
    email: email,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.LOGIN + t.SSO + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.LOGIN + t.SSO + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.LOGIN + t.SSO + t.LOADING,
  }),
});

export const loginWithGoogle = R.partial(loginWithOauth, ['google']);
export const loginWithOutlook = R.partial(loginWithOauth, ['outlook']);

export const enterSignUp = () => ({
  type: t.SIGN_UP + t.ENTER,
});

export const exitSignUp = () => ({
  type: t.SIGN_UP + t.EXIT,
});

const signUpWithOauth = (provider, code, role, registrationToken) => ({
  type: t.SIGN_UP + t.OAUTH,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/user/sign-up/oauth`,
  data: {
    auth_provider: provider,
    auth_code: code,
    registration_token: registrationToken,
    role,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.SIGN_UP + t.OAUTH + t.SUCCESS,
    provider,
    payload,
  }),
  error: (e) => ({
    type: t.SIGN_UP + t.OAUTH + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SIGN_UP + t.OAUTH + t.LOADING,
  }),
});

export const signUpWithGoogleForITAdmins = (code, role, registrationToken) => ({
  type: t.SIGN_UP + t.OAUTH,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/user/sign-up/it-admin-oauth`,
  data: {
    auth_provider: 'google',
    auth_code: code,
    registration_token: registrationToken,
    role,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.SIGN_UP + t.OAUTH + t.SUCCESS,
    provider: 'google',
    payload,
  }),
  error: (e) => ({
    type: t.SIGN_UP + t.OAUTH + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SIGN_UP + t.OAUTH + t.LOADING,
  }),
});

export const signUpWithGoogle = R.partial(signUpWithOauth, ['google']);
export const signUpWithOutlook = R.partial(signUpWithOauth, ['outlook']);

export const linkSalesforceAccount = (email, apiAccessToken, code) => ({
  type: t.SALESFORCE + t.OAUTH,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/salesforce/generate-token`,
  data: {
    email,
    api_access_token: apiAccessToken,
    auth_code: code,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.SALESFORCE + t.OAUTH + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SALESFORCE + t.OAUTH + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SALESFORCE + t.OAUTH + t.LOADING,
  }),
});

export const signUpStepChange = (step, status) => ({
  type: t.SIGN_UP + t.STEP + t.CHANGE,
  payload: {
    step,
    status,
  },
});

export const appInitiated = () => ({
  type: t.APP_INITIATED,
});

export const setAppDisplayMode = (displayMode) => ({
  type: t.APP_DISPLAY_MODE,
  payload: {
    displayMode,
  },
});
