import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import DealsTable from 'components/UI/DealsFlatTableConfig/DealsTable.container';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import * as styles from 'components/modals/ChildWidgetDealsModal/styles';
import { IProps } from 'components/modals/ChildWidgetDealsModal/types';
import { getUser } from 'selectors';
import { usePageSize } from 'components/hooks/usePageSize';

const ChildWidgetDealsModal: React.FC<IProps> = ({
  filters,
  modalProps,
  modalOptions,
}) => {
  const user = useSelector(getUser);
  const localStorageKey = modalOptions.localStorageKeyPrefix
    ? `${modalOptions.localStorageKeyPrefix}SortColumn:${user.email}`
    : '';
  const [
    sortColumnLocalStorage,
    setSortColumnLocalStorage,
    getAndSetSortColumnLocalStorage,
  ] = useLocalStorage(localStorageKey, '');

  const [dealsPageSize] = usePageSize('Opportunities');

  useEffect(() => {
    getAndSetSortColumnLocalStorage(localStorageKey);
  }, [localStorageKey]);

  if (isEmpty(modalProps) || isEmpty(filters)) {
    return <Loader active />;
  }

  const updatedFilters = Object.assign({}, filters, modalProps);
  delete updatedFilters['page_number'];

  const persistLocallyColumnSort = (column: string): void => {
    if (localStorageKey) {
      setSortColumnLocalStorage(column);
    }
  };

  return (
    <div className={styles.container}>
      <DealsTable
        title="Deals"
        tableConfigCollection="opportunity"
        tableConfigName="Opportunities"
        initialRequest={{
          ...(filters as any),
          ...modalProps,
          page_size: dealsPageSize,
          sort: sortColumnLocalStorage,
        }}
        persistName={'risk_analytics_modal'}
        showNextSteps
        showProbability
        inModal
        localStorageSortColumn={sortColumnLocalStorage}
        onSortChangePersistLocally={persistLocallyColumnSort}
      />
    </div>
  );
};

export default ChildWidgetDealsModal;
