import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { isEmpty } from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Breadcrumb,
  Dimmer,
  Dropdown,
  DropdownProps,
  Loader,
} from 'semantic-ui-react';

import {
  NewPipelineCreatedSpanData,
  NewPipelineCreatedSpanUser,
} from 'actions/pipelineActions';
import BuButtonRefresh from 'components/UI/BuButtonRefresh';
import ScrollableChart from 'components/UI/BuScrollableChart';
import TypedTable, {
  BorderType,
  IColumn,
} from 'components/UI/common/TypedTable/TypedTable';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import {
  NEW_PIPELINE_CREATED_FREQUENCY_OPTIONS,
  NEW_PIPELINE_CREATED_PACE_INTERVAL_OPTIONS,
  PIPELINE_BY_CLOSED_DATE_PACE_INTERVAL_OPTIONS,
  PIPELINE_CREATED_BY_CLOSE_DATE_FREQUENCY_OPTIONS,
} from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget/constants';
import {
  getBarPlotOptions,
  getColumns,
  getDealsColumnName,
  getTargetColumnName,
  INTERVAL_COLUMN_WIDTH,
  SELLER_COLUMN_WIDTH,
  TOTAL_COLUMN_WIDTH,
} from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget/helper';
import * as styles from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget/styles';
import {
  IProps,
  RowType,
} from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget/types';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { openModal } from 'navigation/utils';
import { FiltersForAPI } from 'selectors';

const PipelineWidget: React.FC<IProps> = ({
  companyCurrency,
  filters,
  isCloudOps,
  isLoading,
  isNewPipelineCreatedWidget,
  openFilters,
  persistModalParams,
  pipelineData,
  selectedBusinessType,
  user,
  isModal = false,
  hideFilters,
  localStorageKeyPrefix = '',
  fetch,
  setFilter,
}) => {
  const widgetContainer = useRef<HTMLDivElement>(null);
  const [selectedManager, setSelectedManager] = useState<
    NewPipelineCreatedSpanUser[]
  >([
    {
      name: user.name,
      email: user.email,
    },
  ]);
  const [availableWidth, setAvailableWidth] = useState<number>(0);
  const [refreshCache, setRefreshCache] = useState(false);
  const [cacheDate, setCacheDate] = useState(pipelineData.cache);

  useEffect(() => {
    setCacheDate(pipelineData.cache);
  }, [pipelineData]);

  // Workaround as highchart doesn't updates correctly click event
  // another solution is to use immutable	Boolean prop
  // as it eeinitialises the chart on prop update (as oppose to chart.update())
  // But makes ux worse as it re render the chart 3 times an in random moments
  // https://github.com/highcharts/highcharts/issues/12067
  // https://github.com/highcharts/highcharts-react/issues/112
  const filtersRef = useRef<FiltersForAPI>(openFilters);
  useEffect(() => {
    filtersRef.current = openFilters;
  }, [openFilters]);

  const openDealsModal = useCallback(
    (title: string, ids: NewPipelineCreatedSpanData['deals']) => {
      AnalyticsTracker.event(
        { tab: 'pipeline_dashboard' },
        {
          category: 'Pipeline Dashboard',
          action: 'Pipeline widget',
          label: 'Open modal deals',
        }
      );

      openModal({
        scheme: '/deals',
        params: {
          localStorageKeyPrefix,
        },
        persistParams: {
          title,
          filters: JSON.stringify({
            ids,
            business_type_name: selectedBusinessType,
            ...filtersRef.current,
          }),
          showFilters: true,
          filtersHaveToInheritFilterValues: true,
        },
        persistor: persistModalParams,
      });
    },
    [persistModalParams, selectedBusinessType, openFilters]
  );

  const spans = useMemo(
    () => pipelineData.data[0]?.span.map((span) => span.name) || [],
    [pipelineData.data[0]?.span]
  );

  useEffect(() => {
    if (!isEmpty(openFilters)) {
      fetch({
        manager: selectedManager.length
          ? selectedManager[selectedManager.length - 1].email
          : undefined,
        pace_interval: filters.paceInterval,
        pace_freq: filters.frequency,
        business_type_name: selectedBusinessType,
        ...openFilters,
      });
    }
  }, [
    selectedManager,
    filters,
    JSON.stringify(openFilters),
    selectedBusinessType,
  ]);

  useEffect(() => {
    const handleResize = () =>
      setAvailableWidth(widgetContainer.current?.clientWidth || 0);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (refreshCache) {
      fetch(
        {
          manager: selectedManager.length
            ? selectedManager[selectedManager.length - 1].email
            : undefined,
          pace_interval: filters.paceInterval,
          pace_freq: filters.frequency,
          business_type_name: selectedBusinessType,
          ...openFilters,
        },
        refreshCache
      );
      setRefreshCache(false);
    }
  }, [refreshCache]);

  const serializedPipelineData = JSON.stringify(pipelineData);

  const options = useMemo(
    () =>
      getBarPlotOptions(
        pipelineData.data.reduce<NewPipelineCreatedSpanData[]>((acc, item) => {
          item.span.forEach((span) => {
            const a = acc.findIndex((i) => i.name === span.name);

            if (a >= 0) {
              acc[a].total_amount! += span.total_amount || 0;
              acc[a].deals = [...acc[a].deals, ...span.deals];
            } else {
              acc.push({ ...span });
            }
          });

          return acc;
        }, []),
        pipelineData.selected_user_target || [],
        companyCurrency,
        availableWidth,
        filters.frequency === 'month',
        openDealsModal,
        isModal
      ),
    [serializedPipelineData, companyCurrency, availableWidth, openDealsModal]
  );

  const maxValue = useMemo(
    () =>
      Math.max.apply(
        Math.max,
        pipelineData.data
          .map((seller) => seller.span)
          .flat()
          .map((span) => span.total_amount || 0)
          .flat()
      ),
    [serializedPipelineData]
  );

  const columns: IColumn[] = useMemo<IColumn[]>(
    () =>
      getColumns(
        spans,
        companyCurrency,
        maxValue,
        availableWidth,
        (user) => {
          setSelectedManager([...selectedManager, user]);
        },
        openDealsModal,
        'pipeline_dashboard'
      ),
    [
      spans,
      companyCurrency,
      maxValue,
      selectedManager,
      openDealsModal,
      setSelectedManager,
      availableWidth,
    ]
  );

  const rows = useMemo<RowType[]>(() => {
    return pipelineData.data
      .map((seller) => {
        return seller.span.reduce<RowType>(
          (acc, item) => {
            if (item.total_amount !== null) {
              acc.total = acc.total + item.total_amount;
            }
            acc.allDeals = [...acc.allDeals, ...item.deals];
            acc[getDealsColumnName(item.name)] = item.deals;
            acc[getTargetColumnName(item.name)] = item.target;
            acc[item.name] = item.total_amount;
            return acc;
          },
          {
            allDeals: [],
            id: seller.user.email,
            total: 0,
            user: seller.user,
            target:
              ['TQU', 'LQU'].includes(filters.paceInterval) &&
              isNewPipelineCreatedWidget
                ? pipelineData.selected_user_target[0]?.target
                : 0,
          } as RowType
        );
      })
      .sort((a, b) => a.user.name.localeCompare(b.user.name));
  }, [serializedPipelineData]);

  const handleBreadCrumbClick = (index: number) => {
    return selectedManager.length - 1 !== index
      ? () => setSelectedManager(selectedManager.slice(0, index + 1))
      : undefined;
  };

  if (isModal) {
    return (
      <div className={styles.container}>
        <Dimmer.Dimmable dimmed={false}>
          <div className={styles.modal_header}>
            <div className={styles.headerRow_modal}>
              <div className={styles.headerRow_title}>
                What was the in-quarter pipeline creation rate?
              </div>

              {!hideFilters && (
                <div className={styles.filters}>
                  <Dropdown
                    options={
                      isNewPipelineCreatedWidget
                        ? NEW_PIPELINE_CREATED_PACE_INTERVAL_OPTIONS
                        : PIPELINE_BY_CLOSED_DATE_PACE_INTERVAL_OPTIONS
                    }
                    onChange={(
                      e: React.SyntheticEvent,
                      option: DropdownProps
                    ) => setFilter('paceInterval', option.value as string)}
                    value={filters.paceInterval}
                    className={styles.styles_dropdown}
                  />
                  <Dropdown
                    options={
                      isNewPipelineCreatedWidget
                        ? NEW_PIPELINE_CREATED_FREQUENCY_OPTIONS
                        : PIPELINE_CREATED_BY_CLOSE_DATE_FREQUENCY_OPTIONS
                    }
                    onChange={(
                      e: React.SyntheticEvent,
                      option: DropdownProps
                    ) => setFilter('frequency', option.value as string)}
                    value={filters.frequency}
                    className={styles.styles_dropdown}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.widgetContainer} ref={widgetContainer}>
            <HighchartsReact highcharts={Highcharts} options={options} />
            <div style={{ marginBottom: 30 }} />
          </div>

          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Dimmer.Dimmable dimmed={false}>
        <div className={styles.headerContainer}>
          <div className={styles.headerRow}>
            <div className={styles.titleContainer}>
              <div>
                {isNewPipelineCreatedWidget
                  ? 'New Pipeline Created'
                  : 'Total Pipeline Closing by'}
              </div>
              <Breadcrumb size="small" className={styles.breadcrumb}>
                {selectedManager.map((manager, index, arr) => (
                  <React.Fragment key={manager.email}>
                    {index > 0 && <Breadcrumb.Divider icon="right chevron" />}
                    <Breadcrumb.Section
                      active={arr.length - 1 === index}
                      onClick={handleBreadCrumbClick(index)}
                    >
                      {index === 0
                        ? isCloudOps
                          ? 'Sales Org'
                          : `${manager.name}'s Org`
                        : manager.name}
                    </Breadcrumb.Section>
                  </React.Fragment>
                ))}
              </Breadcrumb>
            </div>

            <BuButtonRefresh
              onClick={() => setRefreshCache(true)}
              cacheDate={cacheDate}
              status={refreshCache}
            />
          </div>

          <div className={styles.filters}>
            <DropdownCustom
              value={filters.paceInterval}
              options={
                isNewPipelineCreatedWidget
                  ? NEW_PIPELINE_CREATED_PACE_INTERVAL_OPTIONS
                  : PIPELINE_BY_CLOSED_DATE_PACE_INTERVAL_OPTIONS
              }
              onClick={(e: React.SyntheticEvent, value: string) =>
                setFilter('paceInterval', value)
              }
              label={isNewPipelineCreatedWidget ? 'Created in' : 'Time period'}
            />
            <DropdownCustom
              value={filters.frequency}
              options={
                isNewPipelineCreatedWidget
                  ? NEW_PIPELINE_CREATED_FREQUENCY_OPTIONS
                  : PIPELINE_CREATED_BY_CLOSE_DATE_FREQUENCY_OPTIONS
              }
              onClick={(e: React.SyntheticEvent, value: string) =>
                setFilter('frequency', value)
              }
              label="Time interval"
            />
          </div>
        </div>

        <div className={styles.widgetContainer} ref={widgetContainer}>
          <ScrollableChart
            YAxisWidth={SELLER_COLUMN_WIDTH}
            width={
              SELLER_COLUMN_WIDTH +
              TOTAL_COLUMN_WIDTH +
              INTERVAL_COLUMN_WIDTH * pipelineData.data[0]?.span.length
            }
          >
            <HighchartsReact highcharts={Highcharts} options={options} />
          </ScrollableChart>

          <TypedTable.Border borders={BorderType.TOP} width="fit-content">
            <TypedTable
              columns={columns}
              data={rows}
              fixColumns
              stickyHeader
              width="min-content"
              minWidth="100% + 5px"
            />
          </TypedTable.Border>
        </div>

        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  );
};

export default PipelineWidget;

type IPropsDropdownProps = {
  label: string;
  onClick: any;
  value: string;
  options: {
    text: string;
    value: string;
  }[];
};

const DropdownCustom: React.FC<IPropsDropdownProps> = ({
  label,
  onClick,
  value,
  options,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const toggle = () => setIsOpen(!isOpen);

  const option = options.find((el) => el.value === value);

  const activeValue = option !== undefined ? option.text : '';

  return (
    <div
      ref={refElement}
      onClick={toggle}
      className={classNames(styles.dropdrown, { active: isOpen })}
    >
      <span className={styles.dropdrown_label}>{label}:</span>
      <span className={styles.dropdrown_value}>
        {activeValue}
        <i
          className={classNames(styles.triangle, {
            'bu-up': isOpen,
            'bu-down': !isOpen,
          })}
        />
      </span>
      <ul className={classNames(styles.dropdrown_options, { open: isOpen })}>
        {options.map((item) => (
          <li
            key={item.value}
            onClick={(e) => onClick(e, item.value)}
            className={classNames(styles.dropdrown_options_item, {
              active: item.value === value,
            })}
          >
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
};
