import * as R from 'ramda';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { getCustomReports } from 'selectors/customReports';

export const OVERALL_BUSINESS_TYPE = 'Overall';

export const getSettings = (state) => state.settings.userSettings;

export const getSettingsDialInfo = (state) =>
  state.settings.userSettings.dial_info;

export const getProcessingSettings = (state) =>
  state.settings.userSettings.processing;

export const getSettingsEmailPreferences = createSelector(
  [getSettings, getCustomReports],
  (settings, reports) =>
    R.compose(
      R.mergeDeepRight({
        email_call_summary: false,
        email_periodical_manager_report: false,
        email_forecast_submission_reminder: false,
      }),
      R.pick([
        'email_call_summary',
        'email_periodical_manager_report',
        'email_forecast_submission_reminder',
      ])
    )(settings)
);

export const isSettingsUsersLoading = (state) => state.settings.usersLoading;
export const isSettingsTableConfigurationLoading = createSelector(
  [(state) => state.settings.tableConfigurationLoading],
  (value) => Boolean(value)
);
export const getSettingsUsersById = (state) => state.settings.usersById;
const getSettingsAllUsersById = (state) => state.settings.allUsersById;

export const getSettingsUserWithEmail = (state, email) =>
  R.compose(R.find(R.whereEq({ email })), R.values)(state.settings.usersById);

export const getSettingsTeamMembersIds = (state) => state.settings.teamMembers;

export const getSettingsSuggestedTeamMembersIds = (state) =>
  state.settings.suggestedTeamMembers;

export const getSettingsTeamMembers = createSelector(
  [getSettingsTeamMembersIds, getSettingsAllUsersById],
  (teamMembers, users) => {
    const result = R.compose(R.values, R.pick)(teamMembers, users);
    return result;
  }
);

export const getSettingsTeamMembersPure = (state) => state.settings.teamMembers;

export const getSettingsTeamMembersToAdd = createSelector(
  [getSettingsTeamMembersIds, getSettingsUsersById],
  (members, users) => R.compose(R.values, R.omit(members))(users)
);

export const getSettingsSuggestedTeamMembers = createSelector(
  [
    getSettingsSuggestedTeamMembersIds,
    getSettingsTeamMembersIds,
    getSettingsUsersById,
  ],
  (suggested, current, users) =>
    R.compose(R.values, R.pick(R.difference(suggested, current)))(users)
);

export const getCompanySettings = createSelector(
  [(state) => state.settings.companySettings],
  (companySettings) => companySettings
);
export const getCompanySettingsToPersist = R.path([
  'settings',
  'companySettingsToPersist',
]);

export const getCompanyHasCRM = createSelector(
  [getCompanySettings],
  R.prop('has_crm')
);
export const getSettingsStagesConfiguration = createSelector(
  [getCompanySettings],
  R.propOr([], 'stages_configuration')
);
export const getForecastConfiguration = createSelector(
  [getCompanySettings],
  R.propOr([], 'forecast_configuration')
);
export const getAllSequenceTypes = createSelector(
  [getCompanySettings],
  ({ forecast_configuration, stages_configuration }) => [
    ...(stages_configuration || []).map((i) => ({ ...i, type: 'stage' })),
    ...(forecast_configuration || []).map((i) => ({
      ...i,
      type: 'forecast_category',
    })),
  ]
);
export const getSettingsRiskCategories = createSelector(
  [getCompanySettings],
  R.propOr({}, 'risk_categories')
);
export const getSettingsCRMEnrichment = createSelector(
  [getCompanySettings],
  R.compose(
    R.merge({
      opportunity: {
        table_name: 'Opportunity',
        field_mapping: [],
      },
    }),
    R.prop('crm_enrichment_configuration')
  )
);

export const getTablesPageSize = createSelector(
  [getCompanySettings],
  R.propOr({}, 'tables_page_size')
);

export const getSettingsCRMEnrichmentMapping = createCachedSelector(
  [getSettingsCRMEnrichment, R.nthArg(1), R.nthArg(2), R.nthArg(3)],
  (config, model, field, prop) => R.path([model, field, prop])(config)
)(
  R.compose(
    // join the model, field and prop
    R.join('-'),
    R.unapply(R.tail)
  )
);

export const getSettingsStagesFunnel = createSelector(
  [getSettingsStagesConfiguration],
  R.compose(
    R.map(R.pluck('stage')), // only get the stage names
    R.values, // flatten again
    R.groupBy(R.prop('sequence')), // group by sequence
    R.sortBy(R.prop('sequence')), // sort by sequence
    R.filter(R.propEq('kind', 'active')) // filter out anything that's not active
  )
);

export const getCRMSyncUser = (state) =>
  R.path(['settings', 'CRMSyncStatus', 'credential_user'], state);

export const getCRMSyncStatus = (state) =>
  R.pathOr('', ['settings', 'CRMSyncStatus', 'status'], state);

export const getCRMSyncLastRun = (state) =>
  R.path(['settings', 'CRMSyncStatus', 'last_run'], state);

export const getCRMSyncTableData = (state) => {
  const {
    completed_stages = [],
    default_tables = [],
    custom_tables = [],
  } = R.path(['settings', 'CRMSyncStatus'], state) ?? {};

  return [
    ...completed_stages.map((item) => ({
      ...item,
      internalType: 'completed_stages',
    })),
    ...default_tables.map((item) => ({
      ...item,
      internalType: 'default_tables',
    })),
    ...custom_tables.map((item) => ({
      ...item,
      internalType: 'custom_tables',
    })),
  ];
};

export const getCRMSyncMissingFields = (state) =>
  R.path(['settings', 'CRMSyncStatus', 'missing_fields'], state);

export const getCRMSyncFrequencyMinutesOverrides = (state) =>
  R.path(
    ['settings', 'CRMSyncStatus', 'sync_frequency_minutes_overrides'],
    state
  );

export const getCRMSyncStages = (state) =>
  R.path(['settings', 'CRMSyncStatus', 'stages'], state);

export const getCRMSyncUsers = (state) =>
  R.path(['settings', 'CRMSyncUsers'], state);

export const getCRMTestUserStatus = (state) =>
  R.path(['settings', 'CRMTestUser'], state);

export const hasCRMSyncStarted = createSelector(
  [(state) => state.settings.CRMSyncStatus?.isSubmitting],
  (isSubmitting) => Boolean(isSubmitting)
);

export const getCRMSyncCompany = (state) =>
  R.path(['settings', 'CRMSyncStatus', 'company'], state);

export const getSettingsSalesforceSchema = (state) =>
  state.settings.salesforceSchema;

export const getSettingsSalesforceSchemaObjects = createSelector(
  [getSettingsSalesforceSchema],
  R.pluck('name')
);

export const getSettingsSalesforceSchemaFields = createSelector(
  [getSettingsSalesforceSchema],
  R.compose(R.map(R.prop('fields')), R.indexBy(R.prop('name')))
);

export const getSettingsOpenStages = createSelector(
  [getSettingsStagesConfiguration],
  R.pluck('stage')
);

export const getSettingsStagesByKind = (state, kind) => {
  const stagesConfiguration = R.pathOr(
    [],
    ['settings', 'companySettings', 'stages_configuration'],
    state
  );

  return stagesConfiguration.filter((i) => i.kind === kind).map((i) => i.value);
};

export const getSettingsActiveStages = (state) =>
  getSettingsStagesByKind(state, 'active');

export const getSettingsIsDashboardActivated = (state) =>
  R.pathOr(
    false,
    ['settings', 'companySettings', 'is_dashboard_activated'],
    state
  );

export const getCompanyColumnPreferences = (state) =>
  R.pathOr(
    [],
    [
      'settings',
      'companySettings',
      'display_configuration',
      'deals_list',
      'columns',
    ],
    state
  );

export const getColumnPopupTablePreferences = (state) =>
  R.pathOr(
    [],
    [
      'settings',
      'companySettings',
      'display_configuration',
      'deals_list',
      'columns',
    ],
    state
  ).filter((x) => x.additional_table);

export const getDealsInsightsColumns = (state) =>
  R.pathOr(
    [],
    ['settings', 'userProfileDisplayConfiguration', 'data', 'columns'],
    state
  );

export const getCompanyCurrency = (state) => {
  return R.pathOr(
    'USD',
    ['settings', 'companySettings', 'currency'],
    state
  ).toUpperCase();
};

export const getUserLocalCurrency = (state) => {
  return R.pathOr(
    getCompanyCurrency(state),
    ['settings', 'companySettings', 'user_currency'],
    state
  ).toUpperCase();
};

export const getFiscalPeriod = (state) => {
  const monthly = R.pathOr(
    false,
    ['settings', 'companySettings', 'fiscal_period', 'monthly'],
    state
  );
  return monthly ? 'mothly' : 'quarterly';
};

export const getFiscalYearStartDate = (state) =>
  R.pathOr(
    { day: 1, month: 1 },
    ['settings', 'companySettings', 'fiscal_period', 'fiscal_year_start_date'],
    state
  );

export const getCompanyTimezone = (state) =>
  R.pathOr('GMT', ['settings', 'companySettings', 'timezone'], state);

export const getBusinessTypesList = createSelector(
  [(state) => state.settings.businessTypesConfig.businessTypes.data],
  (businessTypes) => businessTypes || []
);

export const isBusinessTypesConfigReady = createSelector(
  [
    (state) => state.settings.businessTypesConfig.isLoading,
    (state) => state.settings.businessTypesConfig.hasLoaded,
  ],
  (isLoading, hasLoaded) => !isLoading && hasLoaded
);

export const getSortedBusinessTypeNames = createSelector(
  [getBusinessTypesList],
  (businessTypesList) =>
    businessTypesList?.length
      ? businessTypesList.sort((a, b) => a.order - b.order).map((bt) => bt.name)
      : {}
);

export const getRollupsClosingInFiltersConfig = (state) =>
  R.pathOr(
    {},
    [
      'settings',
      'companySettings',
      'forecast_roll_ups_closing_in_filters_config',
    ],
    state
  );

export const getBusinessTypes = (state, displayOverall = false) => {
  const businessTypesList = getSortedBusinessTypeNames(state);

  if (businessTypesList.length) {
    return displayOverall
      ? businessTypesList
      : businessTypesList.filter((bt) => bt !== 'Overall');
  } else {
    return [];
  }
};

export const getBusinessTypesSettings = (state) =>
  R.pathOr({}, ['settings', 'businessTypesSettings'], state);

export const getSplitTypesSettings = (state) =>
  R.pathOr({}, ['settings', 'splitTypesSettings'], state);

export const getBusinessTypesAvailableValues = (state) =>
  R.pathOr(
    {
      values: [],
      amount_field: [],
      close_date_field: [],
      crm_field: [],
      owner_field: [],
      user_roles: [],
      forecast_category_field: [],
      available_pipeline_summary_filters: [],
      active_forecast_category_names: [],
      active_opportunity_stages: [],
    },
    ['settings', 'businessTypesSettings', 'availableValues'],
    state
  );

export const getBusinessTypePrimaryField = (state) =>
  R.pathOr('', ['settings', 'businessTypesSettings', 'primaryField'], state);

export const getBusinessTypesAPIstatus = (state) =>
  R.pathOr({}, ['settings', 'businessTypesAPIstatus'], state);

export const getBusinessTypesLoading = (state) =>
  R.pathOr(false, ['settings', 'businessTypesLoading'], state);

export const getHiddenFilters = (state) =>
  R.pathOr([], ['settings', 'companySettings', 'hidden_filters'], state);

export const getHiddenColumns = (state) =>
  R.pathOr([], ['settings', 'companySettings', 'hidden_columns'], state);

export const getEditableFields = (state) =>
  R.pathOr([], ['settings', 'companySettings', 'editable_fields'], state).map(
    (x) => x.toLowerCase()
  );

export const getSortableFields = (state) =>
  R.pathOr([], ['settings', 'companySettings', 'sortable_fields'], state).map(
    (x) => x.toLowerCase()
  );

export const getForecastAnalyticsAvailableFilters = (state) =>
  R.pathOr(
    [],
    ['settings', 'companySettings', 'forecast_analytics_available_filters'],
    state
  );

export const getAccountHiddenColumns = (state) =>
  R.pathOr(
    [],
    ['settings', 'companySettings', 'account_hidden_columns'],
    state
  );

export const getAccountEditableFields = (state) =>
  R.pathOr(
    [],
    ['settings', 'companySettings', 'account_editable_fields'],
    state
  );

export const getAccountSortableFields = (state) =>
  R.pathOr(
    [],
    ['settings', 'companySettings', 'account_sortable_fields'],
    state
  );

export const getAccountInsightsSettings = (state) =>
  R.pathOr(
    [],
    [
      'settings',
      'companySettings',
      'display_configuration',
      'accounts_insights',
      'fields',
    ],
    state
  );

export const getCompanySettingsLoaded = (state) =>
  R.pathOr(false, ['settings', 'companySettings', 'loaded'], state);

export const getCompanySettingsLoading = createSelector(
  [(state) => state.settings.companySettings.isLoading],
  (value) => Boolean(value)
);

export const getSettingsToastsStatus = createSelector(
  [(state) => state.settings.settingsToasts],
  (status) => status
);

export const isMulticurrencyEnabled = createSelector(
  [(state) => state.settings.companySettings?.multi_currency_enabled],
  (value) => Boolean(value)
);

export const isLocaleCurrencyEnabled = createSelector(
  [(state) => state.settings.companySettings?.users_currency_enabled],
  (value) => Boolean(value)
);

export const isOpportunitySplitsEnabled = createSelector(
  [(state) => state.settings.companySettings?.enable_opportunity_splits],
  (value) => Boolean(value)
);

/**
 *
 * @returns {'user-preferred'|'admin-only'|'user-only'} - Check https://vocalo.atlassian.net/wiki/spaces/SUPP/pages/2896199729/Validation+Rule+change+Salesforce+Edit+Policy
 */
export const getSalesforceEditPolicy = (state) =>
  R.pathOr(
    'user-preferred',
    ['settings', 'companySettings', 'salesforce_edit_policy'],
    state
  );

export const isOppSplitEnabled = (state) =>
  R.pathOr(
    false,
    ['settings', 'companySettings', 'enable_opportunity_splits'],
    state
  );

export const isSplitsOnWaterfallEnabled = (state) =>
  R.pathOr(
    false,
    ['settings', 'companySettings', 'splits_on_waterfall_enabled'],
    state
  );

export const getDealInsightsDefaultTime = (state) =>
  R.pathOr(
    'L3M',
    ['settings', 'companySettings', 'deal_insights_default_time_filter'],
    state
  );

export const getMetricsDashboard = (state, id) =>
  R.pathOr(
    undefined,
    ['settings', 'companySettings', 'metrics_dashboards', id],
    state
  );

export const getMetricsDashboardsList = (state) =>
  R.pathOr(
    [],
    ['settings', 'companySettings', 'metrics_dashboards'],
    state
  ).map((x) => x.title);

export const getMetricsDashboardByTitle = (state, title) => {
  const dashboards = R.pathOr(
    [],
    ['settings', 'companySettings', 'metrics_dashboards'],
    state
  );
  for (const i in dashboards) {
    if (dashboards[i].title === title) {
      return dashboards[i];
    }
  }
};

export const getForecastCategoryMapping = (state) =>
  R.pathOr(
    {},
    ['settings', 'companySettings', 'forecast_category_mapping'],
    state
  );

export const getSalesforceLoginUrl = (state) =>
  R.pathOr(
    null,
    ['settings', 'companySettings', 'salesforce_login_url'],
    state
  );

export const getCrmType = (state) =>
  R.pathOr('', ['settings', 'companySettings', 'crm_type'], state);

export const getAllowDisplayEmailBody = (state) =>
  R.pathOr(
    false,
    ['settings', 'companySettings', 'allow_display_email_body'],
    state
  );

export const getAllowDisplayEventBody = (state) =>
  R.pathOr(
    false,
    ['settings', 'companySettings', 'allow_display_event_body'],
    state
  );

export const getAllowProcessEmailBody = (state) =>
  R.pathOr(
    false,
    ['settings', 'companySettings', 'allow_process_email_body'],
    state
  );

export const getIsAdminSFDCUpdateAllowed = (state) =>
  R.pathOr(
    false,
    ['settings', 'companySettings', 'allow_boostup_admin_salesforce_update'],
    state
  );

export const getIsForecastIncludeSummaryEnabled = (state) =>
  R.pathOr(
    false,
    ['settings', 'companySettings', 'enable_forecast_include_summary'],
    state
  );

export const getIsTotalsRowEnabled = (state) =>
  R.pathOr(false, ['settings', 'companySettings', 'show_totals_row'], state);

/**
 *
 * @returns {string | undefined}
 */
export const getSalesforceUrl = (state) =>
  R.path(['settings', 'companySettings', 'salesforce_url'], state);

export const fieldConfigurationList = (
  state,
  isShort = false,
  apiBase = '/api/settings'
) => {
  const list = R.pathOr(
    [],
    ['settings', 'fieldConfiguration', apiBase, 'configurationsList'],
    state
  );

  return isShort
    ? list.filter(
        (item) =>
          item.name === 'account' ||
          item.name === 'opportunity' ||
          item.name === 'opportunity_split'
      )
    : list;
};

export const fieldConfigurations = (state, apiBase = '/api/settings') =>
  R.pathOr(
    {},
    ['settings', 'fieldConfiguration', apiBase, 'configurations'],
    state
  );

export const fieldConfigurationsByName = (state, name, apiBase) =>
  R.pathOr(
    { crm_field_options: [], default_fields: [], custom_fields: [] },
    ['settings', 'fieldConfiguration', apiBase, 'configurations', name],
    state
  );

export const fieldConfigurationStatuses = (state, apiBase) =>
  R.pathOr(
    {},
    ['settings', 'fieldConfiguration', apiBase, 'configurationsStatuses'],
    state
  );

export const fieldConfigurationFieldStatuses = (state, apiBase) =>
  R.pathOr(
    {},
    ['settings', 'fieldConfiguration', apiBase, 'fieldStatuses'],
    state
  );

export const tableCollections = (state, apiBase) =>
  R.pathOr(
    {},
    ['settings', 'tableConfiguration', apiBase, 'collections'],
    state
  );

export const tableCollectionsStatuses = (state, apiBase) =>
  R.pathOr(
    {},
    ['settings', 'tableConfiguration', apiBase, 'collectionsStatuses'],
    state
  );

const immutableEmptyConfigObject = {
  order: {},
  columns: [],
};

export const tableColumnConfiguration = (
  state,
  collectionName,
  tableName,
  onModifyColumnConfiguration
) => {
  const configuration = R.pathOr(
    immutableEmptyConfigObject,
    ['settings', 'tableColumnConfiguration', collectionName, tableName],
    state
  );

  return onModifyColumnConfiguration
    ? onModifyColumnConfiguration(configuration)
    : configuration;
};

export const getFiltersConfiguration = (state) =>
  R.pathOr({}, ['settings', 'filtersConfiguration', 'filters'], state);

export const getFiltersConfigurationStatus = (state) =>
  R.pathOr({}, ['settings', 'filtersConfiguration', 'filtersStatus'], state);

export const getCurrentFiscalQuarter = R.path([
  'settings',
  'companySettings',
  'current_quarter',
]);

export const getCurrentFiscalYear = R.path([
  'settings',
  'companySettings',
  'current_fiscal_year',
]);

export const getActivityPushConfiguration = (state) => ({
  emails: {
    enabled: false,
    field_mappings: {},
    properties: [],
    sfdc_object: '',
  },
  emails_available_properties: [],
  meetings: {
    enabled: false,
    field_mappings: {},
    properties: [],
    sfdc_object: '',
  },
  meetings_available_properties: [],
  ...state.settings.companySettings.activity_push_configuration,
});

export const isCompanySettingsLoading = createSelector(
  [(state) => state.settings.companySettings.isLoading],
  (isLoading) => isLoading
);

export const getCRMForecastCategoryList = R.path([
  'settings',
  'CRMForecastCategories',
]);

export const getForecastCategoryList = R.path([
  'settings',
  'forecastCategories',
]);

export const getCRMForecastCategoriesLoading = R.path([
  'settings',
  'CRMForecastCategoriesLoading',
]);

export const getSalesProcess = R.path(['settings', 'salesProcesses']);

export const getSalesProcessNames = (state) =>
  getSalesProcess(state).map((process) => process.name);

export const getSalesProcessConfig = R.path(['settings', 'salesProcessConfig']);

export const getSalesProcessApiStatuses = R.path([
  'settings',
  'salesProcessApiStatuses',
]);

export const getSalesProcessLoading = R.path([
  'settings',
  'salesProcessLoading',
]);

export const getSfBaseUrl = (state) =>
  state.settings.companySettings.salesforce_login_url;

export const getUserProfilesState = (state) => state.settings.userProfiles;

export const getUserProfilesList =
  ({ search, sort } = {}) =>
  (state) => {
    let { list } = state.settings.userProfiles;

    if (!search && !sort) {
      return list;
    }

    if (search) {
      const lSearch = search.toLowerCase();

      list = list.filter(({ description, name }) => {
        return (
          description.toLowerCase().includes(lSearch) ||
          name.toLowerCase().includes(lSearch)
        );
      });
    }

    if (sort) {
      const itemProp = sort.replace('-', '');
      const isAscending = sort.includes('-');

      list = list.sort(({ [itemProp]: a }, { [itemProp]: b }) => {
        let result = 0;

        if (a === b) {
          result = 0;
        } else if (R.isNil(a)) {
          result = 1;
        } else if (R.isNil(b)) {
          result = -1;
        } else if (isAscending) {
          result = a < b ? 1 : -1;
        } else {
          result = a < b ? -1 : 1;
        }

        return result;
      });
    }

    return list;
  };

export const getSubmissionFilters = (state) => state.settings.submissionFilters;

export const getHideViewIncludeExcludeSubmissions = (state) =>
  R.pathOr(
    false,
    ['settings', 'companySettings', 'hide_view_include_exclude_submissions'],
    state
  );

export const getDayInQuarter = R.path([
  'settings',
  'companySettings',
  'relative_days_in_quarter',
]);

export const getIsFiltersOnModalsEnabled = (state) =>
  R.pathOr(
    false,
    ['settings', 'companySettings', 'filters_on_modals_enabled'],
    state
  );

export const getCompanyIsActive = (state) =>
  state.settings.companySettingsIsActive;

export const getIncludeDisabledUsersByDefaut = R.pathOr(false, [
  'settings',
  'companySettings',
  'include_disabled_user_settings',
  'other_pages',
]);

export const getIncludeDisabledUsersByDefautOnRollups = R.pathOr(false, [
  'settings',
  'companySettings',
  'include_disabled_user_settings',
  'rollups',
]);

export const getIsRealTimeEnabled = R.pathOr(false, [
  'settings',
  'companySettings',
  'rollups_real_time_opp_editing_enabled',
]);

export const getFeatureFlags = (state) => {
  const companySettings = getCompanySettings(state) || {};
  const {
    forecast_dashboard_enabled = false,
    forecast_roll_ups_enabled = false,
    rep_pace_table_enabled = false,
    trends_by_business_type_enabled = false,
    rollup_overall_view_enabled = false,
    deals_overall_view_enabled = false,
    top_metric_booked_enabled = false,
    progression_risk_factors_enabled = false,
    custom_views,
    sankey_chart_enabled = false,
    cache_settings,
    allow_single_record_sf_sync = false,
    allow_boostup_admin_salesforce_update = false,
    rollups_real_time_opp_editing_enabled = false,
    self_serve_enabled = false,
    dashboard_enabled = false,
    enable_call_dialer = false,
    enable_aggressive_call_dialing = false,
    allow_mobile_login = false,
    allow_saml_login = true,
    allow_oauth_login = true,
    meetings_dashboard_enabled = false,
    hide_mobile_forecast_submissions = false,
    metrics_builder_enabled = false,
    rollups_deltas_enabled = false,
    hide_view_include_exclude_submissions = false,
    user_edit_email_enabled = false,
    user_bulk_update_enabled = false,
    deal_show_opportunity_insights_enabled = true,
    deal_show_account_insights_enabled = true,
    quota_attainment_chart_enabled = true,
    metrics_bar_enabled = true,
    email_convert_newline_enabled = false,
    filters_on_modals_enabled = false,
    risk_analytics_metrics_bar_enabled = false,
    opportunity_metrics_bar_enabled = false,
    opportunity_metrics_bar_clickable_enabled = false,
    third_party_integration_enabled = false,
    allow_fetch_email_body = false,
    revbi_show_dashboard_nav_items_enabled = false,
    deal_outcome_enabled = false,
    include_missing_managers = false,
    activity_convert_newline_enabled = false,
    enable_pipeline_summary = false,
    enable_opportunity_forced_splits = false,
    forecast_summary_enabled = false,
    disable_sync_info_warnings = false,
    show_totals_row = false,
  } = companySettings;

  return {
    forecast_dashboard_enabled,
    forecast_roll_ups_enabled,
    rep_pace_table_enabled,
    trends_by_business_type_enabled,
    rollup_overall_view_enabled,
    deals_overall_view_enabled,
    top_metric_booked_enabled,
    progression_risk_factors_enabled,
    custom_views_enabled: custom_views?.enabled || false,
    sankey_chart_enabled,
    rollups_cache_enabled: cache_settings?.rollups_cache_enabled || false,
    allow_single_record_sf_sync,
    allow_boostup_admin_salesforce_update,
    rollups_real_time_opp_editing_enabled,
    self_serve_enabled,
    dashboard_enabled,
    enable_call_dialer,
    enable_aggressive_call_dialing,
    allow_mobile_login,
    allow_saml_login,
    allow_oauth_login,
    meetings_dashboard_enabled,
    hide_mobile_forecast_submissions,
    metrics_builder_enabled,
    rollups_deltas_enabled,
    hide_view_include_exclude_submissions,
    user_edit_email_enabled,
    user_bulk_update_enabled,
    deal_show_opportunity_insights_enabled,
    deal_show_account_insights_enabled,
    quota_attainment_chart_enabled,
    metrics_bar_enabled,
    email_convert_newline_enabled,
    filters_on_modals_enabled,
    risk_analytics_metrics_bar_enabled,
    opportunity_metrics_bar_enabled,
    opportunity_metrics_bar_clickable_enabled,
    third_party_integration_enabled,
    allow_fetch_email_body,
    revbi_show_dashboard_nav_items_enabled,
    deal_outcome_enabled,
    include_missing_managers,
    activity_convert_newline_enabled,
    enable_pipeline_summary,
    enable_opportunity_forced_splits,
    forecast_summary_enabled,
    disable_sync_info_warnings,
    show_totals_row,
  };
};

export const getFeatureFlag = (state, name) => {
  const companySettings = getCompanySettings(state);
  return companySettings && companySettings[name]
    ? companySettings[name]
    : false;
};

export const getFrozenTime = (state) =>
  state.settings.companySettings.frozen_time || '';

export const areCrmSyncTableConditionsLoading = createSelector(
  [(state) => state.settings.crmSyncTableConditions.loading],
  (loading) => Boolean(loading)
);

export const getCrmSyncTablePossibleConditions = createSelector(
  [(state) => state.settings.crmSyncTableConditions.possibleConditions],
  (possibleConditions) => possibleConditions
);

export const getCrmSyncTableConditions = createSelector(
  [(state) => state.settings.crmSyncTableConditions.conditions],
  (conditions) => conditions
);

export const getActivityMatchingCategory = createSelector(
  [(state) => state.settings.activityMatching.category],
  (category) => category
);

export const getActivityMatchingSortingKeysLoadingStatus = createSelector(
  [(state) => state.settings.activityMatching.sortingKeys.isLoading],
  (isLoading) => isLoading
);

export const getActivityMatchingFilterPolicyLoadingStatus = createSelector(
  [(state) => state.settings.activityMatching.filterPolicy.isLoading],
  (isLoading) => isLoading
);

export const getActivityMatchingFieldsInfoLoadingStatus = createSelector(
  [(state) => state.settings.activityMatching.fieldsInfo.isLoading],
  (isLoading) => isLoading
);

export const getActivityMatchingError = createSelector(
  [(state) => state.settings.activityMatching.sortingKeys.error],
  (error) => error
);

export const getActivityMatchingSortingKeysByCategory = createSelector(
  [
    (_, category) => category,
    (state) => state.settings.activityMatching.sortingKeys.account_sorting_keys,
    (state) =>
      state.settings.activityMatching.sortingKeys.opportunity_sorting_keys,
  ],
  (category, accountSortingKeys = [], opportunitySortingKeys = []) => {
    switch (category) {
      case 'account':
        return accountSortingKeys;
      case 'opportunity':
        return opportunitySortingKeys;
      default:
        return [];
    }
  }
);

export const getActivityMatchingSortingKeysDictionaryByCategory =
  createSelector([getActivityMatchingSortingKeysByCategory], (sortingKeys) => {
    return sortingKeys.reduce((result, item) => {
      if (!result[item.value]) {
        result[item.value] = {
          ...item,
        };
      }
      return result;
    }, {});
  });

export const getActivityMatchingFieldsInfo = createSelector(
  [(state) => state.settings.activityMatching.fieldsInfo.data],
  (data = []) =>
    data?.map((item) => {
      switch (item.type) {
        case 'CRM Lookup':
        case 'custom':
        case 'formula':
          return {
            ...item,
            field_type: 'text',
            field: item.object_field,
            values: item.options,
          };
        default:
          return {
            ...item,
            field_type: item.type,
            field: item.object_field,
            values: item.options,
          };
      }
    })
);

export const getActivityMatchingFilterPolicy = createSelector(
  [
    (_, category) => category,
    (state) =>
      state.settings.activityMatching.filterPolicy.account_filter_policy,
    (state) =>
      state.settings.activityMatching.filterPolicy.opportunity_filter_policy,
  ],
  (category, accountFilterPolicy = {}, opportunityFilterPolicy = {}) => {
    switch (category) {
      case 'account':
        return accountFilterPolicy;
      case 'opportunity':
        return opportunityFilterPolicy;
      default:
        return [];
    }
  }
);
