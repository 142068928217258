import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { css } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import FullScreenIcon from 'assets/images/new_icon/full_screen_icon.svg';
import { IColumn } from 'components/UI/common/TypedTable/TypedTable';
import {
  AccountsList,
  AccountData,
} from 'components/dashboard/Accounts/AccountsList';
import viewConfig from 'components/dashboard/Accounts/getConfig';
import {
  usePageNumber,
  usePageSort,
  usePageSearch,
} from 'components/dashboard/Accounts/utils';
import tablesDataInitialState from 'components/dashboard/ProspectAccountDashboard/WidgetTableAccount/config';
import * as s from 'components/dashboard/ProspectAccountDashboard/WidgetTableAccount/styles';
import { IProps } from 'components/dashboard/ProspectAccountDashboard/WidgetTableAccount/types';
import { useBoundary } from 'components/hooks/useBoundary';
import { ModalLink } from 'navigation/Link';
import { openModal } from 'navigation/utils';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { usePageSize } from 'components/hooks/usePageSize';

type ParamsFilter = {
  account_name: string | undefined;
  sort: string;
  page_size: number;
  page_number: number;
};

const accountNameCellColor = css`
  color: #0762ec;
  font-family: var(--bu-font-regular);
  font-weight: 600;
`;

const wrapperWidget = css`
  border-radius: 2px;
  border: 1px solid #dddddd;
  overflow: hidden;
`;

const WidgetTableAccount: React.FC<IProps> = ({
  filters,
  position,
  showColumns,
  title,
}) => {
  const [status, setStatus] = useState<
    'notAsked' | 'loading' | 'success' | 'error'
  >('notAsked');

  const [count, setCount] = useState<number>(0);
  const [data, setData] = useState<AccountData[]>(
    tablesDataInitialState.accounts
  );

  const [currentPage = 1, setPageNumber] = usePageNumber();
  const [accountsPageSize, setPageSize] = usePageSize('Accounts', 10);
  const [searchText = '', setSearchText] = usePageSearch();
  const isFirstLoad = useBoundary(position);

  const [
    sortOrder = filters!.sort || viewConfig.defaultSortOrder,
    setSortOrder,
  ] = usePageSort(
    Object.values<IColumn>(viewConfig.columns)
      .filter((column) => column.sortable)
      .map((column) => column.field)
  );

  const fetchData = useCallback(
    async (request: ParamsFilter, controller: AbortController) => {
      setStatus('loading');
      try {
        const response = await fetch('/api/data/accounts/', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ ...filters, ...request }),
          signal: controller.signal,
        });

        const result = await response.json();

        if (response.status === 200) {
          setData(result.data ? result.data.accounts : result.accounts);
          setCount(result.data ? result.data.total_count : result.total_count);
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (err) {
        setStatus('error');
        if ((err as Error).name === 'AbortError') {
          console.warn('ABORTED');
        }
      }
    },
    [setData, setCount, setStatus, filters]
  );

  useEffect(() => {
    let controller: AbortController | null = null;

    if (!isFirstLoad) {
      controller = new AbortController();

      const query = {
        account_name: searchText || undefined,
        page_size: accountsPageSize,
        page_number: currentPage - 1,
        sort: sortOrder || undefined,
      };

      fetchData(query, controller);
    }

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [isFirstLoad, searchText, accountsPageSize, currentPage, sortOrder]);

  const handleSort = useCallback(
    (sort?: string) => {
      setPageNumber(1);
      setSortOrder(sort);
    },
    [setPageNumber, setSortOrder]
  );

  const handleSearchChange = useCallback(
    (text: string) => {
      setSearchText(text);
      setPageNumber(1);
    },
    [setPageNumber, setSearchText]
  );

  const handlePaginationChange = useCallback(
    (page: number, pageSize: number) => {
      setPageNumber(page);
      setPageSize(pageSize);
    },
    [setPageSize, setPageNumber]
  );

  const handleAccountNameClick = useCallback((column, row) => {
    openModal({
      scheme: '/account/:id',
      params: { id: row.id },
    });
  }, []);

  viewConfig.columns['account name'].config.className = accountNameCellColor;
  viewConfig.columns['account name'].config.click = handleAccountNameClick;

  const newConfig: any = {};

  showColumns.forEach((key) => {
    newConfig[key] = viewConfig.columns[key];
  });

  const modalParams = {
    title,
    filters: JSON.stringify({
      ...filters,
    }),
  };

  return (
    <div className={wrapperWidget}>
      <div className={s.header}>
        <div className={s.header_title}>
          {title}
          <ModalLink url={{ scheme: '/accounts', persistParams: modalParams }}>
            <span className={s.header_title_count}> ({count} Accounts)</span>
          </ModalLink>
        </div>
        <div className={s.header_btns}>
          <ModalLink url={{ scheme: '/accounts', persistParams: modalParams }}>
            <img src={FullScreenIcon} alt="full screen" />
          </ModalLink>
        </div>
      </div>

      <AccountsList
        widgetSize
        loading={status === 'loading'}
        title={title}
        config={newConfig}
        accounts={{
          data,
          total_count: count,
        }}
        sortOrder={sortOrder}
        onSort={handleSort}
        searchText={searchText}
        onSearchChange={handleSearchChange}
        rowsPerPage={accountsPageSize}
        currentPage={currentPage}
        onPaginationChange={handlePaginationChange}
        hideSearch
        hidePaginationEnd
        hidePaginationStart
      />
    </div>
  );
};

const mapStateToProps = (state: IReduxState, ownProps: any): Object => ({
  filters: {
    ...(selectors.getFiltersForAPI(state, ownProps.tab) || {}),
    ...ownProps.defaultFilters,
  },
});

export default connect(mapStateToProps)(WidgetTableAccount);
