import { push, replace } from 'connected-react-router';
import queryString from 'query-string';
import * as R from 'ramda';

import * as t from 'actions/actionTypes';
import * as genericSagas from 'sagas/generic';

export const LOGIN_ROUTE = '/login';
export const DASHBOARD_ROUTE = '/dashboard';
export const ALL_DEALS_ROUTE = '/opportunities';
const ACCOUNTS_ROUTE = '/accounts';
export const WIN_AND_LOSS_ROUTE = '/winAndLoss';
export const SEARCH_ROUTE = '/search';
export const CALLS_ROUTE = '/calls';
export const LIBRARY_ROUTE = '/library';
const EVENT_ROUTE = '/event';
const TRANSCRIPT_ROUTE = '/transcript';

export const goToDealInsights = (dealId) =>
  push(`${ALL_DEALS_ROUTE}/preview/${dealId}`);
export const goToAccount = (accountId) =>
  push(`${ACCOUNTS_ROUTE}/preview/${accountId}`);
export const goToCallInsights = (callId) =>
  push(`${TRANSCRIPT_ROUTE}/${callId}`);
export const goToMeeting = (meetingId) => push(`${EVENT_ROUTE}/${meetingId}`);
export const goToAllDeals = () => push(ALL_DEALS_ROUTE);
export const goToCalls = () => push(CALLS_ROUTE);
export const goToSearch = (query, shouldReplace = false) => {
  const url = `${SEARCH_ROUTE}?${queryString.stringify({ q: query })}`;

  return shouldReplace ? replace(url) : push(url);
};
export const goBackOr = R.curry((defaultGoBack, previous) =>
  previous ? push(previous) : defaultGoBack()
);

export const changePreviousRoute = (previous, wentBack = false) => ({
  type: t.PREVIOUS_ROUTE + t.CHANGE,
  payload: {
    previous,
    wentBack,
  },
});

export const getRouterConfig = ({ read_only = false } = {}) => ({
  type: t.ROUTER_CONFIG + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/navigation`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.ROUTER_CONFIG + t.GET + t.SUCCESS,
    payload: {
      ...payload,
      read_only,
    },
  }),
  error: (e) => ({
    type: t.ROUTER_CONFIG + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.ROUTER_CONFIG + t.GET + t.LOADING,
  }),
});
