import { css } from 'emotion';

export const removeButtonStyles = css`
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
`;

export const itemStyles = css`
  font-size: 14px;
  line-height: 19.6px;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 6px 12px 6px 12px;
  gap: 10px;
  color: var(--bu-gray-900);
  font-weight: 400;
  font-family: var(--bu-font-regular);
  cursor: pointer;
  &:hover {
    background-color: var(--bu-primary-200);
  }
`;

export const iconStyles = css`
  color: var(--bu-primary-600);
`;

// Have to do this approach instead of just setting the color to the icon directly
// because the bu-icon classes inside the button have a white color applied
export const menuColor = css`
  .bu-icon {
    color: var(--bu-gray-900);
  }
`;

export const wrapperStyles = css`
  padding: 8px 0;
`;
