import React from 'react';
import { css } from 'emotion';
import classNames from 'classnames';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import * as s from 'components/UI/Wrapper/Header/styles';
import BuIcon from 'components/UI/BuIcon';

const overrideHeaderWrapper = css`
  margin-left: 0;
  padding: 0 24px;
`;

const overrideHeader = css`
  padding: 0 !important;
`;

const boostUpLogo = css`
  color: var(--bu-white);
  font-size: 24px;
`;

const Header: React.FC = () => (
  <div className={classNames(s.header, overrideHeader)}>
    <div className={classNames(s.headerWrapper, overrideHeaderWrapper)}>
      <BuIcon name={BoostUpIcons.Boostup} className={boostUpLogo} />
    </div>
  </div>
);

export default Header;
