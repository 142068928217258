import axiosInstance from 'api/axiosInstance';
import { CallInsightsResponse } from 'components/dashboard/Calls/CallDetails/types';

type GetCallInsightsDataMethodParams = {
  callId: string;
};

type GetCallInsightsExternalDataMethodParams = {
  sharedCallId: string;
};

export const getCallInsightsData = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<CallInsightsResponse> => {
  const res = await axiosInstance.get<{ data: CallInsightsResponse }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/${callId}/`
  );

  return res.data.data;
};

export const getCallInsightsExternalData = async ({
  sharedCallId,
}: GetCallInsightsExternalDataMethodParams): Promise<CallInsightsResponse> => {
  const res = await axiosInstance.get<{ data: CallInsightsResponse }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/shared/${sharedCallId}/`
  );

  return res.data.data;
};

export const postShareCall = async ({
  callId,
}: GetCallInsightsDataMethodParams): Promise<string> => {
  const res = await axiosInstance.post<{ share_id: string }>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/${callId}/share/`
  );

  return res.data.share_id;
};
