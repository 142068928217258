import { css } from 'emotion';

import { fontHeading3 } from 'assets/css/fontStyles';

import { showMore } from '../styles';

export const callTimelineWrapper = css`
  .call-timeline {
    position: relative;
    padding-bottom: 10px;
  }

  .call-timeline-progress {
    position: absolute;
    height: 100%;
    /* Using color straight because the opacity  */
    /* Adding opacity property would impact the border also */
    background-color: #3884ff10; // --bu-primary-500
    border-right: 1px solid var(--bu-primary-700);
  }

  .call-timeline-highlight-section {
    position: absolute;
    height: 100%;
    background-color: var(--bu-colol-call-timeline-highlight-section);
    z-index: 999;

    &.disabled {
      cursor: default;
    }

    &:hover:not(.disabled) {
      cursor: pointer;
      box-shadow: var(--bu-shadow-basic);
    }
  }

  .call-timeline-participant {
    margin-top: 30px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .participant {
      display: flex;
      align-items: center;
      gap: var(--bu-control-gap-medium);

      .participant-info {
        ${fontHeading3}
        display: flex;
        align-items: center;
        gap: var(--bu-control-gap-medium);

        /* TODO: Will have this in the future */
        /* .participant-role-company {
          color: var(--bu-color-call-light-gray);
          font-size: 12px;
          font-family: var(--bu-font-regular);
        } */

        .participant-percentage {
          font-size: 14px;
        }
      }
    }

    .video-time {
      margin-top: 6px;
      background-color: var(--bu-color-call-video-time);
      height: 10px;
      border-radius: var(--bu-control-border-radius-large);
      position: relative;

      .video-participant-speaking {
        position: absolute;
        height: 10px;
        border-radius: var(--bu-control-border-radius-large);

        &.disabled {
          cursor: default;
        }

        &:hover:not(.disabled) {
          cursor: pointer;
          box-shadow: var(--bu-shadow-deep);
        }
      }
    }
  }

  .call-timeline-show-more {
    ${showMore}
    margin-top: 14px;
  }
`;
