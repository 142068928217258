import { css } from 'emotion';

export const tooltipContent = css`
  text-transform: capitalize;
`;

export const textOverflow = css`
  position: relative;
  min-height: 20px;
  line-height: 26px;

  & {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1em !important;

    &[title]:focus:after,
    &[title]:hover:after {
      all: inherit;
      content: attr(title);
      overflow: visible;
      position: absolute;
      left: auto;
      top: 0;
      padding-right: 3px;
      width: auto;
      display: block;
      z-index: 2;
      background: #eef7fe;

      .row-total & {
        background-color: var(--bu-primary-200);
      }
    }
  }
`;

export const tableSizeStyle = css`
  th {
    height: 48px;
    //font-size: 11px;
    //font-weight: bold;
    //text-transform: uppercase;

    .table-header-label,
    &.table-header-label {
      font-size: 1em;
      text-transform: uppercase;
      font-weight: 400;
    }
    .bu-icon {
      font-size: 1.2em;
    }
  }

  td {
    font-size: 1em;
    height: 48px;
  }

  &.table-compact {
    th {
      height: 40px;

      .table-header-label,
      &.table-header-label {
        font-size: 0.85em;
      }
      .bu-icon {
        font-size: 1em;
      }
    }

    td {
      font-size: 1em;
      height: 44px;
    }
  }
`;

export const labelAndSortingSection = css`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const columnHeaderCell = css`
  color: var(--bu-gray-900);
  min-width: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
`;

export const columnHeaderTitleWrapper = css`
  display: flex;

  img {
    width: 18px;
    margin-left: 5px;
  }
`;

export const wrapText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const table_layout = css`
  &.ui.fixed.table {
    table-layout: auto;

    > tbody > tr > td {
      height: 44px;
    }
  }
`;

export const hoverRow = css`
  &.ui.table.structured > tbody > tr:hover {
    background: var(--bu-gray-200);

    td:first-child {
      background: var(--bu-gray-200);
    }
  }
`;

export const columnBorder = css`
  &.ui.table {
    &,
    &.structured {
      & thead tr > th:not(:last-child) {
        border-right: 1px solid #ddd;
      }
    }
  }
`;

export const fullTableBorders = css`
  &.ui.table {
    &.structured {
      & > tfoot > tr > td,
      & > tbody > tr > td {
        border-right: 1px solid var(--bu-table-border-color);
      }

      & > tbody > tr > td:first-child {
        border-right: 1px solid var(--bu-table-border-color);
      }

      & > tbody > tr:last-child > td {
        border-bottom: 1px solid var(--bu-table-border-color);
      }

      & > thead > tr > th:first-child {
        border-right: 1px solid var(--bu-table-border-color);
      }
    }
  }
`;

export const stickyColumnRollUps = css`
  &.ui.table {
    &,
    &.structured {
      border-collapse: separate;

      & > tbody > tr > td {
        border-right: 1px solid var(--bu-table-border-color);
      }

      & > tbody > tr > td:first-child {
        border-right: 1px solid var(--bu-table-border-color);

        position: sticky;
        left: 0;
        z-index: 5;

        background: white;

        &:hover {
          background: var(--bu-gray-200);
        }
      }

      & > tbody > tr > td:nth-child(2) {
        border-right: 1px solid var(--bu-table-border-color);
      }

      & > thead > tr > th:first-child {
        border-right: 1px solid var(--bu-table-border-color);

        position: sticky;
        left: 0;
        z-index: 6;
      }

      & > thead > tr > th:nth-child(2) {
        border-right: 1px solid var(--bu-table-border-color);
      }
    }
  }
`;

export const stickyHeader = css`
  &.ui.table {
    &,
    &.structured {
      border-collapse: separate;

      & > thead {
        & > tr > th {
          background-color: var(--bu--table-header);
          position: sticky !important;
          top: 0;
          z-index: 5;
          border-radius: 0;

          &.sorting {
            background-color: var(--bu--table-header-sorting);
          }
        }

        tr:nth-child(1) th {
          top: 0;
        }
        tr:nth-child(2) th {
          top: 36px;
        }
      }
    }
  }
`;

export const disableDefaultOverflow = css`
  &.ui.table.fixed > {
    thead,
    tbody,
    tfoot {
      > tr > {
        td,
        th {
          overflow: visible;
          text-overflow: initial;
        }
      }
    }
  }
`;

export const totalRow = (height: number) => css`
  position: sticky;
  top: ${height}px;
  z-index: 13;

  &:hover {
    & tr > td {
      background-color: var(--bu-gray-200);
    }
  }

  & tr > td {
    border-right: 1px solid var(--bu-table-border-color);
    background-color: var(--bu-primary-200);
  }
`;

export const emptyTableMessage = css`
  width: 100%;
  text-align: center;
  margin: 20px 0 10px 0;
  left: 0;
  position: sticky;

  span {
    font-size: 16px;
    color: #9b9b9b;
  }
`;

export const border = css`
  border-color: var(--bu-table-header-border-color);
  border-style: solid;
  border-width: 0;
  min-width: 100%;

  &:not(.top):not(.right):not(.bottom):not(.left) {
    border-width: 1px;
    border-radius: var(--bu-control-border-radius);
    overflow: visible;
  }

  &.top {
    border-top-width: 1px;
  }
  &.right {
    border-right-width: 1px;
  }
  &.bottom {
    border-bottom-width: 1px;
  }
  &.left {
    border-left-width: 1px;
  }
`;

export const fluid = css`
  position: relative;
  width: 100%;
`;

export const getClassStripeColor = (
  widgetSize: boolean | undefined
): string => css`
  &.ui.table {
    width: ${widgetSize ? '100%' : 'auto'};
    border: none;

    &.stripe,
    &.stripe.structured > tbody > tr {
      &:hover {
        background: var(--bu-gray-200);

        td {
          background: var(--bu-gray-200);
        }
      }

      &:nth-child(2n) {
        background-color: var(--bu-gray-100);

        &:hover {
          background: var(--bu-gray-200);

          td {
            background: var(--bu-gray-200);
          }
        }
      }
    }
  }
`;

export const adaptiveContent = css`
  &.ui.table {
    > thead > tr > th {
      width: auto;
      &:first-child {
        width: 250px;
      }
    }

    width: 100%;
  }
`;

export const fixedBody = css`
  table-layout: fixed !important;
`;

export const mediaQuery = css`
  @media only screen and (min-width: 1920px) {
    ${adaptiveContent}
  }
`;

export const stickHeaderToLeft = (left: number) => css`
  border-right: 1px solid var(--bu-gray-300);
  position: sticky;
  left: ${left}px;
  z-index: 6;
`;

export const stickCellToLeft = (left: number) => css`
  position: sticky;
  left: ${left}px;
  z-index: 5;
  background-color: white;

  &:hover {
    background-color: var(--bu-gray-200);
  }

  &.dropdown-open {
    z-index: 999;
  }
`;

export const lastStickyCell = css`
  border-right: 1px solid var(--bu-gray-300);
`;

export const carets = css`
  display: none;
`;

export const withPaddingLeft = css`
  padding-left: 20px;
`;

export const blockDnd = css`
  display: flex;
  align-items: center;
  padding: 0px 20px;

  &.fix-position {
    position: inherit;
  }

  &.header-text-wrap {
    position: relative;
    width: 100%;
  }

  &.column-sortable {
    padding-right: 0;
  }
`;

export const showOrHide = css`
  display: none;
`;

export const fieldHeaderHighlight = css`
  background: var(--bu-yellow-200) !important;
`;

/*
 * This is a list of supported custom column colors, same list should be on the BE as well.
 * If the color is not in the list column will stay in default grey color.
 */
export const SUPPORTED_COLUMN_COLORS = [
  'primary-300',
  'green-200',
  'green-300',
  'green-400',
  'lime-300',
  'yellow-200',
  'yellow-300',
  'orange-300',
  'orange-400',
  'red-100',
  'red-200',
  'red-300',
  'red-400',
  'purple-300',
];

const buVar = (color: string) => `var(--bu-${color})`;

export const customColumnColor = (customColor: string) => css`
  ${customColor &&
  `background-color: ${
    SUPPORTED_COLUMN_COLORS.includes(customColor)
      ? buVar(customColor)
      : customColor
  } !important;`}
`;

export const resize__line = css`
  position: absolute;
  right: -5px;
  top: 0;
  bottom: 0;
  background: transparent;
  cursor: col-resize;
  width: 10px;

  &:after {
    content: '';
    width: 1px;
    height: 100%;
    display: block;
    background: transparent;
    position: absolute;
    right: 4px;
    top: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }

  &:hover {
    &:after {
      background: #0043ff;
    }
  }
`;

export const cellContainer = (
  textAlignment?: 'left' | 'right' | 'center'
) => css`
  vertical-align: middle;
  .ui.compact.table td& {
    padding: 0 15px;
    text-align: ${textAlignment || 'left'};
    box-sizing: content-box;
  }

  &.hide {
    display: none;
  }
`;

export const currentBg = css`
  border-left: 2px solid blue !important;
`;

export const selectedBg = css`
  background-color: var(--bu-gray-200) !important;
`;

export const marginHierarchyField = css`
  margin-left: -22px;
`;

export const hierarchyMarginWrapper = css`
  margin-left: 20px;
  height: 100%;
`;

export const hierarchyWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  height: 100%;

  .typed-table-cell {
    flex: 1;
    min-width: 1px;

    &.typed-table-cell-center {
      display: flex;
      justify-content: center;
    }

    .primary-cell {
      font-weight: 400;
    }

    .hoverable {
      cursor: pointer;

      color: var(--bu-clickable-cell);

      tr:hover & {
        color: var(--bu-clickable-cell__hover);
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &.warning {
      background-color: #fffaca !important;
    }

    &.positive {
      background-color: #cff6d7 !important;
    }

    &.negative {
      background-color: #fce3dc !important;
    }
  }

  .typed-table-cell-type-change {
    padding: 0;

    &.positive {
      background-color: #cff6d7 !important;
    }

    &.negative {
      background-color: #fce3dc !important;
    }
  }

  .typed-table-cell-type-action {
    height: 100%;
  }
`;

export const hierarchyCell = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const typedCellWrapper = css`
  width: 100%;
`;

export const noIconSpaceHolder = css`
  margin-left: 26px;
`;

export const typedHierarchyTogglePlaceholder = css`
  width: 10px;
`;

export const hierarchyUserIcon = css`
  margin-left: 8px;
  margin-right: -18px;
  height: 16px;
  width: 16px;
  color: var(--bu-white);
  background-color: var(--bu-table-user-icon-level-3);
  padding: 5px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.level-0 {
    background-color: var(--bu-table-user-icon-level-0);
  }

  &.level-1 {
    background-color: var(--bu-table-user-icon-level-1);
  }

  &.level-2 {
    background-color: var(--bu-table-user-icon-level-2);
  }
`;

export const typedTableHeader = css`
  border-bottom: 1px solid var(--bu-table-header-border-color) !important;
  border-right: 1px solid var(--bu-table-header-border-color) !important;

  .ui.table > thead > tr & {
    padding: 8px 5px;
  }

  &.extraHeader {
    text-align: center !important;
  }

  &.selectAllContainer {
    padding: 0px !important;
    width: 40px !important;
    min-width: 40px !important;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &::after {
      content: none;
    }
  }

  div.header-content {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.align-center {
      justify-content: center;
      .table-header-label {
        text-align: center;
      }
    }
    &.align-right {
      justify-content: flex-end;
      .table-header-label {
        text-align: right;
      }
    }
  }
`;

export const showMoreBox = css`
  background-color: #f4f7f8;
  margin: -9px 10px 0 10px;
  padding: 10px;
  border-radius: 3px;
  word-break: break-word;
`;

export const badgeWrapper = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const newBadge = css`
  width: 40px;
  min-width: 40px;
  border-radius: 10px;
  background-color: #ff7271;
  color: var(--bu-white);
  font-family: var(--bu-font-regular);
  font-size: 12px;
  letter-spacing: 0;
  text-align: center;
  margin: 0 5px;
`;

export const cellSelectableContainer = css`
  display: flex;
  align-items: center;
  gap: 10px;

  & > *:last-child {
    flex: 1;
  }

  & > .ui.checkbox {
    margin: 0;
  }
`;

export const cellSyncControl = css`
  display: flex;
  align-items: center;
`;

export const headerSelectableContainer = css`
  display: flex;
  align-items: center;
  gap: 10px;

  & > .ui.checkbox {
    margin: 0;
  }
`;

export const highlightText = css`
  background: yellow;
  font-weight: bold;
  padding: 0;
`;

export const monthsWrapper = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const showHideButton = css`
  background: transparent;
  color: var(--bu-clickable-cell);
  fontsize: 11px;
  outline: none;
  border: none;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const iconImage = css`
  margin-right: 5px;
`;

export const loaderWrapper = css`
  width: 100%;
  position: relative;
`;

export const noDataWrapper = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const buttonLabelStatus = css`
  margin-top: 2px;
`;

export const resetPadding = css`
  padding: 0;
`;

/* styles for .popup elements that have a parent with .modal class */
export const zIndexIfModal = css`
  &.ui.popup:has(.modal) {
    z-index: 999;
  }
`;

export const selectableCell = css`
  position: sticky;
  left: 0;
`;
