import React, { createContext, useContext, useState, ReactNode } from 'react';

interface TranscriptContextType {
  isAutoscrollActive: boolean;
  setIsAutoscrollActive: (value: boolean) => void;
  scrollToIndex: number | undefined;
  setScrollToIndex: (index: number | undefined) => void;
}

const TranscriptContext = createContext<TranscriptContextType | undefined>(
  undefined
);

export const TranscriptProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAutoscrollActive, setIsAutoscrollActive] = useState(false);
  const [scrollToIndex, setScrollToIndex] = useState<number | undefined>(
    undefined
  );

  return (
    <TranscriptContext.Provider
      value={{
        isAutoscrollActive,
        setIsAutoscrollActive,
        scrollToIndex,
        setScrollToIndex,
      }}
    >
      {children}
    </TranscriptContext.Provider>
  );
};

export const useTranscriptContext = (): TranscriptContextType => {
  const context = useContext(TranscriptContext);
  if (!context) {
    throw new Error(
      'useTranscriptContext must be used within a TranscriptProvider'
    );
  }
  return context;
};
