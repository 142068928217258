import { css } from 'emotion';

import { ellipsis } from '../styles';

export const summaryInsightHeaderContainer = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--bu-control-gap-small);

  .header-opportunity,
  .header-ae {
    display: flex;
    gap: var(--bu-control-gap-small);

    span {
      color: var(--bu-color-call-light-gray);
      font-family: var(--bu-font-medium);
    }

    span + span {
      color: var(--bu-primary-700);
    }

    .link {
      color: var(--bu-primary-700);

      &:hover {
        color: var(--bu-primary-500);
      }
    }
  }

  .header-opportunity {
    span {
      ${ellipsis}
      display: block;
      max-width: 500px;

      @media (max-width: 1140px) {
        max-width: 160px;
      }

      @media (min-width: 1140px) and (max-width: 1280px) {
        max-width: 260px;
      }

      @media (min-width: 1280px) and (max-width: 1480px) {
        max-width: 320px;
      }
    }
  }

  .header-ae {
    span + span {
      color: var(--bu-gray-900);
    }
  }
`;
